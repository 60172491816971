import { Navigate } from 'react-router-dom';
import { AllPermissionRequired, checkPermission } from './permission';

interface Props1 extends AllPermissionRequired {
	permission: string[];
	component: any;
}

/**
 * RouteManager - render routes based on permissions
 * @param permission : number of ALL permissions to check before rendering
 * @param path : router parameter : path
 * @param exact : router parameter : exact
 * @param component : router parameter : component
 */
const RouteManager = (props: Props1) => {
	const doesHavePermission = checkPermission({
		permission: props.permission
	});

	if (!doesHavePermission) {
		// if user does not have permission, redirect to 404
		return <Navigate to='/404' />;
	}
	return props.component;
};

export default RouteManager;

import { ThunkDispatch } from 'redux-thunk';

import { API_CONFIG, ENVIRONMENT } from 'shared/constants/constants';
import { Action } from 'shared/interface';
import AuthService from 'shared/services/auth.service';
import HttpService from 'shared/services/http.service';
import { refreshLanguage } from 'shared/util/localization';
import { createAction } from 'shared/util/utility';
import * as actionTypes from 'store/action-types';
import { LoginResponse, UserLoginData, UserProfileResponse } from '../interface/login';

const login = (data: UserLoginData, dispatch: (action: Action) => void) => {
	dispatch(createAction(actionTypes.AUTH_INIT));
	HttpService.post(
		API_CONFIG.path.login,
		data,
		{},
		{
			isAccessTokenRequire: false
		}
	)
		.then((response: LoginResponse) => {
			if (['production', 'staging', 'development'].includes(ENVIRONMENT)) {
				window.__insp.push([
					'tagSession',
					{
						email: response.email,
						environment: ENVIRONMENT
					}
				]);
			}
			localStorage.setItem('isAnnouncement', JSON.stringify(1));
			AuthService.setAuthData(response);
			AuthService.setMatfoxxSettings(response.matfoxx_settings);
			AuthService.setDateFormate(response.date_format);
			localStorage.setItem('recordPerPage', JSON.stringify(response.page_limit));
			AuthService.setAnalyticsSessionStore({
				endTime: new Date().toISOString(),
				startTime: new Date().toISOString(),
				pageVisitsStreamId: '',
				sessionStreamUserId: ''
			});
			refreshLanguage(response.sprache);
			dispatch(createAction(actionTypes.AUTH_SUCCESS, response));
			dispatch(
				createAction(actionTypes.UPDATE_SITE_CONFIG, {
					language: response.sprache,
					dateFormat: response.date_format,
					matfoxxConfig: response.matfoxx_settings,
					recordPerPage: response.page_limit,
					admin: response.admin,
					chartView: response.chart_view
				})
			);
		})
		.catch(() => {
			dispatch(createAction(actionTypes.AUTH_FAIL));
		});
};

const getProfile = () => (dispatch: ThunkDispatch<Record<string, never>, Record<string, never>, Action>) => {
	dispatch(createAction(actionTypes.GET_PROFILE_INIT));
	HttpService.get(API_CONFIG.path.profile)
		.then((profileResponse: UserProfileResponse) => {
			AuthService.setUserData(profileResponse);
			dispatch(createAction(actionTypes.GET_PROFILE_SUCCESS, profileResponse));
		})
		.catch(() => {
			dispatch(createAction(actionTypes.GET_PROFILE_FAIL));
		});
};

const logout = (dispatch: ThunkDispatch<Record<string, never>, Record<string, never>, Action>) => {
	dispatch(createAction(actionTypes.AUTH_LOGOUT_INIT));
	HttpService.put(API_CONFIG.path.logout)
		.then(() => {
			dispatch(createAction(actionTypes.AUTH_LOGOUT_SUCCESS));
			localStorage.clear();
			sessionStorage.clear();
		})
		.catch(() => {
			dispatch(createAction(actionTypes.AUTH_LOGOUT_FAIL));
		});
};

export { login, logout, getProfile };

import { FC, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { checkExpiredDate } from 'shared/constants/constants';
import { CardView } from 'shared/interface/enums';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';
import { formatDate } from 'shared/util/utility';
import DashboardCardHeader from './dashboardCardHeader';
import { TenderContent } from './tenderContent';

interface IProps {
	cardIndex: number;
	tenderData: any;
	section: string;
	newsLength: number;
	deleteData: (data: string, action: boolean) => void;
	onSaveTender: (tenderId: number, action: boolean, index: number, section: string, rubrics: string) => void;
}
const TenderComponent: FC<IProps> = ({ cardIndex, tenderData, section, deleteData, onSaveTender }) => {
	const {
		name,
		element_id,
		component_data,
		params: { rubrics, continentId, countryId, sectorId, search, financersId, financerType, isSaved }
	} = tenderData;

	const finalFinancerId = useMemo(() => {
		if (financerType) {
			return financerType;
		} else {
			return financersId;
		}
	}, [financerType, financersId]);

	const navigationUrl = useMemo(
		() =>
			`/tenders/${isSaved ? 'saveTenders' : 'tenderList'}/${rubrics}?continentId=${continentId}${
				!isEmpty(countryId) ? `&countryId=${countryId.toString()}` : ''
			}${!isEmpty(sectorId) ? `&sectorId=${sectorId.toString()}` : ''}${finalFinancerId ? `&financerId=${finalFinancerId.toString()}` : ''}${
				search !== '' && search !== null ? `&word=${search}` : ''
			}`,
		[continentId, countryId, finalFinancerId, isSaved, rubrics, search, sectorId]
	);

	return (
		<>
			<DashboardCardHeader
				name={name}
				action={true}
				deleteData={deleteData}
				navigateUrl={navigationUrl}
				elementId={element_id}
				section='tender'
			/>
			<div className={`card-items d-flex flex-column justify-content-space-between flex-column`}>
				{!isEmpty(component_data) && (
					<div className='card-content'>
						{component_data.map((data: any) => {
							return (
								<TenderContent
									key={data.id}
									view={CardView.LIST_VIEW}
									cardIndex={cardIndex}
									rubrics={rubrics}
									className={`medium-news-card__wrapper tender-card__wrapper d-flex justify-content-start align-items-center ${
										checkExpiredDate(formatDate(data.validTo, 'YYYY-MM-DD')) ? 'expired-tender' : ''
									}`}
									data={data}
									sectorId={sectorId || []}
									onSaveTender={onSaveTender}
									section={section}
									financersId={financersId || []}
									isSaved={isSaved}
								/>
							);
						})}
					</div>
				)}
				{isEmpty(component_data) && (
					<p className='no-result-title pt--20 text-align-center font--20px'>
						<Translate text={localizationConstants.noTenderArticleFound} />
					</p>
				)}
				{!isEmpty(component_data) && (
					<div className='card-bottom d-flex justify-content-space-between align-items-center'>
						<h1 className={`width--fit-content no-margin font--13px line-height-20px font-weight--400`}>
							<Translate text={localizationConstants.latestTenders} /> ({component_data.length})
						</h1>
					</div>
				)}
			</div>
		</>
	);
};

export default TenderComponent;

import React, { Fragment } from 'react';

import { useWhitelabelConfigStatus } from 'shared/hooks/useWhitelabelConfigStatus';

import PdfModuleList from 'features/pdfModule/component/pdfModuleList';
import WhitelabelpdfModuleList from '../component/whitelabelpdfModuleList';

const AllpdfModuleBoxContainer: React.FC = () => {
	const { pdfModule_status } = useWhitelabelConfigStatus();
	return (
		<Fragment>
			{(pdfModule_status === 'active' || pdfModule_status === 'active+original') && <WhitelabelpdfModuleList />}
			{(!pdfModule_status || pdfModule_status === 'inactive' || pdfModule_status === 'active+original') && <PdfModuleList />}
		</Fragment>
	);
};

export default AllpdfModuleBoxContainer;

import { sum } from 'd3-array';
import { isEmpty } from 'lodash';
import { ATR as defaultOptions } from 'react-stockcharts/lib/calculator/defaultOptionsForComputation';
import { isDefined, last, slidingWindow } from 'react-stockcharts/lib/utils';
import { ScaleType } from 'shared/interface/enums';

export default function atrCalculator(sourcePath: string, scaleType: string) {
	let options = defaultOptions;
	let source = (d: any) => {
		if (scaleType === ScaleType.PERCENTAGE_CHANGE) {
			return {
				open: sourcePath !== 'percentage' ? d?.compare[`${sourcePath}`]?.p : d[sourcePath],
				high: sourcePath !== 'percentage' ? d?.compare[`${sourcePath}`]?.p : d[sourcePath],
				low: sourcePath !== 'percentage' ? d?.compare[`${sourcePath}`]?.p : d[sourcePath],
				close: sourcePath !== 'percentage' ? d?.compare[`${sourcePath}`]?.p : d[sourcePath]
			};
		} else {
			return {
				open: d[`${sourcePath}`]?.e || d[sourcePath],
				high: d[`${sourcePath}`]?.h || d[sourcePath],
				low: d[`${sourcePath}`]?.t || d[sourcePath],
				close: d[`${sourcePath}`]?.l || d[sourcePath]
			};
		}
	};

	function calculator(data: any) {
		const { windowSize } = options;
		const trueRangeAlgorithm = slidingWindow()
			.windowSize(2)
			.source(source)
			.undefinedValue((d: any) => {
				return d.high - d.low;
			}) // the first TR value is simply the High minus the Low
			.accumulator((values: any) => {
				const prev = values[0];
				const d = values[1];
				if (!isEmpty(d) && !isEmpty(prev)) {
					return Math.max(d.high - d.low, d.high - prev.close, d.low - prev.close);
				} else {
					return;
				}
			});

		let prevATR: any;

		const atrAlgorithm = slidingWindow()
			.skipInitial(1) // trueRange starts from index 1 so ATR starts from 1
			.windowSize(windowSize)
			.accumulator((values: any) => {
				const tr = last(values);
				const atr = isDefined(prevATR) ? (prevATR * (windowSize - 1) + tr) / windowSize : sum(values) / windowSize;

				prevATR = atr;
				return atr;
			});

		const newData = atrAlgorithm(trueRangeAlgorithm(data));
		return newData;
	}
	calculator.undefinedLength = function () {
		const { windowSize } = options;
		return windowSize - 1;
	};
	calculator.options = function (x: any) {
		if (!arguments.length) {
			return options;
		}
		options = { ...defaultOptions, ...x };
		return calculator;
	};

	calculator.source = function (x: any) {
		if (!arguments.length) {
			return source;
		}
		source = x;
		return calculator;
	};

	return calculator;
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Fragment } from 'react';

import { CurrentCoordinate } from 'react-stockcharts/lib/coordinates';
import { BollingerSeries, LineSeries } from 'react-stockcharts/lib/series';
import { hexToRGBA } from 'react-stockcharts/lib/utils';
interface IProps {
	type: string;
	index: number;
	yAccessor: any;
	color: string;
}

const RenderIndicatorsOnchart: React.FC<IProps> = (props) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { index, type, yAccessor, color } = props;
	const bbStroke = {
		top: '#964B00',
		middle: '#000000',
		bottom: '#964B00'
	};
	const opacity = (100 - (index + 1) * 10) / 100;
	const indicatorColor = hexToRGBA(color, opacity);
	const bbFill = '#4682B4';

	switch (type) {
		case 'ema':
			return (
				<Fragment key={`ema${index}`}>
					<LineSeries yAccessor={yAccessor} stroke={indicatorColor} />
					<CurrentCoordinate yAccessor={yAccessor} fill={indicatorColor} />
				</Fragment>
			);
		case 'sma':
			return (
				<Fragment key={`sma${index}`}>
					<LineSeries yAccessor={yAccessor} stroke={indicatorColor} />
					<CurrentCoordinate yAccessor={yAccessor} fill={indicatorColor} />
				</Fragment>
			);
		case 'tma':
			return (
				<Fragment key={`tma${index}`}>
					<LineSeries yAccessor={yAccessor} stroke={indicatorColor} />
					<CurrentCoordinate yAccessor={yAccessor} fill={indicatorColor} />
				</Fragment>
			);
		case 'bb':
			return (
				<BollingerSeries
					yAccessor={yAccessor}
					stroke={{
						top: indicatorColor,
						middle: indicatorColor,
						bottom: indicatorColor
					}}
					fill={indicatorColor}
					key={`bb${index}`}
				/>
			);
		case 'wma':
			return (
				<Fragment key={`wma${index}`}>
					<LineSeries yAccessor={yAccessor} stroke={indicatorColor} />
					<CurrentCoordinate yAccessor={yAccessor} fill={indicatorColor} />
				</Fragment>
			);

		default:
			return <></>;
	}
};

export default RenderIndicatorsOnchart;

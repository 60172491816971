import * as actionTypes from 'store/action-types';
import { Action } from 'shared/interface';
import { IDahboardReducerType } from 'features/dashboard/interface/dashboard';

const initialState: IDahboardReducerType = {
	isRedirectFromDashboard: false
};

const dashboardChartReducer = (state: IDahboardReducerType = initialState, action: Action): IDahboardReducerType => {
	switch (action.type) {
		case actionTypes.UPDATE_CHART_CONFIG_FROM_DASHBOARD:
			return {
				isRedirectFromDashboard: action.payload
			};
		default:
			return state;
	}
};
export default dashboardChartReducer;

import { rebind, merge } from 'react-stockcharts/lib/utils';

import baseIndicator from 'react-stockcharts/lib/indicator/baseIndicator';
import compareCalculator from './compareCalculator';

const ALGORITHM_TYPE = 'Compare';

export default function (): any {
	const base = baseIndicator()
		.type(ALGORITHM_TYPE)
		.accessor((d: any) => d.compare);

	const underlyingAlgorithm = compareCalculator();

	const mergedAlgorithm = merge()
		.algorithm(underlyingAlgorithm)
		.merge((datum: any, indicator: any) => {
			datum.compare = indicator;
		});

	const indicator = function (data: any, options = { merge: true }) {
		if (options.merge) {
			if (!base.accessor()) throw new Error(`Set an accessor to ${ALGORITHM_TYPE} before calculating`);
			return mergedAlgorithm(data);
		}
		return underlyingAlgorithm(data);
	};

	rebind(indicator, base, 'id', 'accessor', 'stroke', 'fill', 'echo', 'type');
	rebind(indicator, underlyingAlgorithm, 'options');
	rebind(indicator, mergedAlgorithm, 'merge');

	return indicator;
}

import React, { PropsWithChildren } from 'react';
import { AllPermissionRequired } from './permission';
import authService from 'shared/services/auth.service';
import { LoginResponse } from 'features/login/interface/login';
import { isArray } from 'lodash';

/**
 * PermissionManager - render children based on permissions
 * @param permission : number of ALL permissions to check before rendering
 * @param anyOnePermission : number of ANY OF GIVEN permissions to check before rendering
 */

interface Props1 extends AllPermissionRequired, PropsWithChildren {}

const PermissionManager: React.FC<Props1> = (props) => {
	const doesHavePermission = checkPermission(props);
	if (!doesHavePermission) {
		return null;
	}
	return <>{props.children}</>;
};

export default PermissionManager;

export const checkPermission = (data: Props1) => {
	const authData = authService.getAuthData() as LoginResponse;
	if (authData) {
		const { admin } = authData;
		return data.permission.some((role) => isArray(admin) && admin.includes(role));
	} else {
		return false;
	}
};

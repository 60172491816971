import React from 'react';
import i18n from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';
import './emptyDataContainer.css';

interface EmptyDataContainerProps {
	text?: string;
}

const EmptyDataContainer: React.FC<EmptyDataContainerProps> = (props) => {
	const { t } = i18n;
	return <div className='empty-data-wrapper'>{props.text || `${t(localizationConstants.noDataFound)}`}</div>;
};

export default EmptyDataContainer;

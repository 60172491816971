import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useQueryParams = () => {
	const [query, setQuery] = useSearchParams();

	const changeQuery = useCallback(
		(key: string, value: string) => {
			setQuery((query) => {
				query.set(key, value);
				return query;
			});
		},
		[setQuery]
	);

	return useMemo(
		() => ({
			alertId: query.get('alertId'),
			searchWord: query.get('word'),
			globalWordSearch: query.get('globalSearchWord'),
			wordSearch: query.get('wordSearch'),
			duration: query.get('duration'),
			newsId: Number(query.get('newsId')),
			categoryId: query.get('categoryId'),
			continentId: query.get('continentId'),
			tenderId: query.get('tenderId'),
			type: query.get('type'),
			userId: query.get('userId'),
			countryId: query.get('countryId'),
			sectorsId: query.get('sectorId'),
			financerId: query.get('financerId'),
			parentId: query.get('parentId'),
			symbol: query.get('symbol'),
			boerse: query.get('boerse'),
			currency: query.get('currency'),
			country: query.get('country'),
			// materials params
			elementId: query.get('element_id') === null ? '' : query.get('element_id'),
			listenId: query.get('listen_id') || undefined,
			timeSpan: query.get('timeSpan') || '1y',
			waehrung: query.get('waehrung') === null ? '' : query.get('waehrung'),
			whitelabelId: query.get('whitelabelId'),
			activeTab: query.get('activeTab'),
			tab: query.get('tab'),
			query,
			changeQuery
		}),
		[changeQuery, query]
	);
};

import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'shared/components/form/button';
import { BarChartIcon, CalenderIcon, CostModalIcon, CrossIcon, DoughnutChartIcon, TableIcon } from 'shared/icons/icon';
import { customNumberFormatter } from 'shared/util/utility';

import { ICostModal } from '../interface/costModal';
import CostModalChartContainer from './costModalChartContainer';
import CostModalTable from './costModalTable';
import DoughnutChart from './doughnutChart';

interface IProps {
	costModal: ICostModal;
	cardIndex?: number;
	tableType: string;
	cardType: string;
	costModalUpdateView?: (id: number) => void;
	handleDeleteCostmodal: (id: number) => void;
}

const CostModalCard: React.FC<IProps> = (props) => {
	const { costModal, cardIndex, tableType, cardType, costModalUpdateView, handleDeleteCostmodal } = props;
	const navigate = useNavigate();
	const isModalCardIndex = cardIndex && cardIndex > 3;
	const chartContainerRef = useRef<HTMLDivElement>(null);
	const centerTextDesign = [
		{ fontWeight: '500', size: isModalCardIndex ? 10 : 20, fontColor: '#081D34', lineHeight: isModalCardIndex ? 10 : 20 },
		{ fontWeight: '600', size: isModalCardIndex ? 8 : 12, fontColor: '#FF9100', lineHeight: isModalCardIndex ? 8 : 17 },
		{ fontWeight: '400', size: isModalCardIndex ? 6 : 10, fontColor: '#081D34', lineHeight: isModalCardIndex ? 6 : 14 }
	];

	const viewType =
		costModal.view_type === 'chart'
			? 'bg-color--error-color'
			: costModal.view_type === 'doughnut'
			? 'bg-color--accent-yellow-color'
			: 'bg-color--success-color';

	return (
		<div className='cost-modal-card'>
			<div className='cost-modal-card-header__wrapper d-flex justify-content-start align-items-center'>
				<div className='cost-modal-icon__wrapper d-flex justify-content-center align-items-center dashboard-icon__wrapper'>
					<CostModalIcon className='fill--white' />
				</div>
				<div className='unit__wrapper d-flex justify-content-space-between align-items-center'>
					<h3
						className='font--14 line-height--20px font-weight--600 no-margin truncate--text cursor-pointer'
						onClick={() => navigate(`/costModal/${cardType}/${costModal.id}`)}
					>
						{costModal.modal_name}
					</h3>
					<div className='d-flex justify-content-end align-items-center'>
						<h2 className='font--14px line-height--20px font-weight--600 no-margin truncate--text'>
							{customNumberFormatter(costModal.total_price, 2)}
							{`${costModal.currency}`}{' '}
							<span className='font--12px line-height--17px font-weight--400 mr-12 color-grayscale-80'>/ Unit</span>
						</h2>
						<div className={`active-card-circle ${viewType || ''}`} />
						<div className='cross-icon__wrapper d-flex align-items-center justify-content-center'>
							<Button className='no-border height--auto' onClick={() => handleDeleteCostmodal(costModal.id)}>
								<CrossIcon className='cross-icon' />
							</Button>
						</div>
					</div>
				</div>
			</div>
			<div
				className={`cost-modal-card__wrapper chart-component-height ${
					((costModal.view_type === 'chart' || costModal.view_type === 'doughnut') && 'cost-modal-chart__wrapper') || ''
				}`}
				key={costModal.id}
				ref={chartContainerRef}
			>
				{costModal.view_type === 'chart' && (
					<CostModalChartContainer
						cardIndex={cardIndex || 0}
						id={costModal.id}
						showHoverTooltip={false}
						key={costModal.id}
						chartContainerRef={chartContainerRef}
					/>
				)}
				{costModal.view_type === 'doughnut' && (
					<DoughnutChart
						position={'right'}
						id={costModal.id}
						align='start'
						legendsPadding={10}
						textDesign={centerTextDesign}
						action={'chart'}
					/>
				)}
				{costModal.view_type === 'table' && <CostModalTable costModal={costModal} tableType={tableType} />}
			</div>
			<div className='bottom-panel__wrapper full-width d-flex justify-content-start align-items-center'>
				<div className='calender__wrapper d-flex justify-content-start align-items-center '>
					<CalenderIcon className='mr-8' />
					<p className='no-margin font--10px line-height--14px font-weight--400'>1.07.2022-27.07.2022</p>
				</div>
				<div
					className='bottom-modal-icon__wrapper d-flex justify-content-center align-items-center cursor-pointer'
					onClick={() => costModalUpdateView && costModalUpdateView(costModal.id)}
				>
					{costModal.view_type === 'chart' && <BarChartIcon className='fill--white' />}
					{costModal.view_type === 'doughnut' && <DoughnutChartIcon className='fill--white' />}
					{costModal.view_type === 'table' && <TableIcon className='fill--white' />}
				</div>
			</div>
		</div>
	);
};

export default CostModalCard;

import React from 'react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import ErrorBoundary from 'shared/components/errorBoundary/errorBoundary';
import WithErrorHandler from 'shared/hoc/withErrorHandler';
import i18n from 'shared/util/localization';

import App from './App';
import store from './store';
import 'assets/styles/datatables.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'assets/styles/index.scss';

const Root: React.FC = () => (
	<I18nextProvider i18n={i18n}>
		<Provider store={store}>
			<BrowserRouter>
				<ErrorBoundary>
					<WithErrorHandler />
					<App />
				</ErrorBoundary>
			</BrowserRouter>
		</Provider>
	</I18nextProvider>
);

export default Root;

import isEmpty from 'lodash/isEmpty';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import * as LoginActions from 'features/login/store/login.action';
import { API_CONFIG, ENVIRONMENT } from 'shared/constants/constants';
import { IAllGlobalSearchData, State } from 'shared/interface';
import { useWhitelabelConfigStatus } from 'shared/hooks/useWhitelabelConfigStatus';
import { ScaleType } from 'shared/interface/enums';
import httpService from 'shared/services/http.service';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';
import { capitalizeFirstLetter, createAction, createLoadingSelector, debounce, replaceHashtagFromUrl } from 'shared/util/utility';
import { LockIcon, UnlockIcon } from 'shared/icons/icon';
import * as actionTypes from 'store/action-types';

import Confirm from '../confirm/confirm';
import { Loader } from '../spinner/spinner';
import EmptySearchComponent from './emptySearchComponent';
import HeaderButtons from './headerButtons';
import SearchComponent from './searchComponent';

interface UIState {
	isOpen: boolean;
	isPopUpOpen: boolean;
	allGlobalSearchData: IAllGlobalSearchData;
	globalActiveCategories: string;
	isActive: boolean;
	loading: boolean;
}

const TopHeader: React.FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const myRef = useRef<any>(null);
	const { whitelabelId, news_status, material_status } = useWhitelabelConfigStatus();
	const [state, setState] = useState<UIState>({
		isOpen: false,
		isPopUpOpen: false,
		allGlobalSearchData: {} as IAllGlobalSearchData,
		isActive: false,
		globalActiveCategories: material_status === 'hideall' ? 'News' : news_status === 'hideall' ? 'Price' : 'All',
		loading: false
	});
	const [searchTitle, setSearchTitle] = useState('');
	const loadingSelector = createLoadingSelector(['AUTH_LOGOUT']);

	const { authData, userData, language, chartView, logoutLoading } = useSelector((state: State) => {
		return {
			...state.siteConfig,
			...state.auth,
			logoutLoading: loadingSelector(state)
		};
	});
	const dispatch = useDispatch();

	const searchData = useCallback(
		debounce((searchWord: string) => {
			setState((prevState) => ({
				...prevState,
				loading: true,
				allGlobalSearchData: {} as IAllGlobalSearchData
			}));
			setSearchTitle(searchWord);
			if (searchWord !== '') {
				const param = {
					word: searchWord,
					...(whitelabelId && { whitelabel_id: whitelabelId })
				};
				httpService
					.get(API_CONFIG.path.globalSearch, param)
					.then((response) => {
						if (!response.reqCanceled) {
							setState((prevState) => ({
								...prevState,
								allGlobalSearchData: response,
								loading: false
							}));
						}
					})
					.catch((error) => console.error(error));
			}
		}, 300),
		[whitelabelId]
	);

	const handleClickOutside = useCallback(
		(event: any) => {
			if (myRef.current && !myRef.current.contains(event.target) && state.isActive) {
				setSearchTitle('');
				setState((prevState) => ({
					...prevState,
					isActive: false
				}));
			}
		},
		[state.isActive]
	);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleClickOutside]);

	useEffect(() => {
		if (location.pathname) {
			setSearchTitle('');
		}
	}, [location.pathname]);

	const toggleModalStatus = () => {
		setState((prevState) => ({
			...prevState,
			isOpen: !prevState.isOpen
		}));
	};

	const closeGlobalSearch = () => {
		setState((prevState) => ({
			...prevState,
			isPopUpOpen: !prevState.isPopUpOpen
		}));
	};

	const renderSearchComponents = (type: string) => {
		const { allGlobalSearchData, loading } = state;
		switch (type) {
			case 'All':
				return (
					<>
						{isEmpty(allGlobalSearchData) && loading && <Loader />}
						{!isEmpty(allGlobalSearchData.material) && (
							<>
								{allGlobalSearchData.material.map((materialData, index: number) => (
									<div key={index} className='d-flex justify-content-space-between align-items-center pt--10 pb-10'>
										{isEmpty(userData.admin) && (
											<div className='icon__wrapper'>
												{materialData.disallowed === 1 ? (
													<LockIcon className='lock-icon' />
												) : (
													<UnlockIcon className='unlock-icon' />
												)}
											</div>
										)}
										<p
											className='font--14px global-search-title cursor-pointer'
											onClick={() => {
												dispatch(createAction(actionTypes.UPDATE_CHART_CONFIG, true));
												closeGlobalSearch();
												const params = new URLSearchParams(location.search);
												if (params.get('symbol') !== materialData.symbol) {
													navigate(
														`/materials/futureTable?globalSearchWordListenId=${
															materialData.id
														}&boerse=${replaceHashtagFromUrl(materialData.boerse)}&basis_symbol=${
															materialData.basis_symbol
														}&symbol=${replaceHashtagFromUrl(materialData.symbol)}&currency=${materialData.waehrung}`,
														{
															state: {
																chartSelection: {
																	span: '1y',
																	type: chartView === ScaleType.PERCENTAGE_CHANGE ? 'line' : 'area',
																	customChartSpan: undefined
																}
															}
														}
													);
												}
											}}
										>
											{language === 'en' && materialData.name_eng !== '' ? materialData.name_eng : materialData.name_deu}
										</p>
										<p className='font--14px global-search-type'>
											<Translate text={localizationConstants.price} />
										</p>
									</div>
								))}
							</>
						)}
						{!isEmpty(allGlobalSearchData.news) && (
							<>
								{allGlobalSearchData.news.map((newsData, index: number) => (
									<div key={index} className='d-flex justify-content-space-between align-items-center pt--10 pb-10  '>
										{isEmpty(userData.admin) && <div />}
										<p
											className='font--14px global-search-title'
											onClick={() => {
												closeGlobalSearch();
												navigate(
													`/news/fullNewsArticle/0?newsId=${newsData.id}${
														whitelabelId && news_status !== 'inactive' ? `&whitelabelId=${whitelabelId}` : ''
													}`
												);
											}}
										>
											{newsData.titel}
										</p>
										<p className='font--14px global-search-type'>{newsData.type}</p>
									</div>
								))}
							</>
						)}
						{isEmpty(allGlobalSearchData.material) && isEmpty(allGlobalSearchData.news) && !loading && <EmptySearchComponent />}
					</>
				);
			case 'News':
				return (
					<>
						{isEmpty(allGlobalSearchData.news) && loading && <Loader />}
						{!isEmpty(allGlobalSearchData.news) && (
							<>
								{allGlobalSearchData.news.map((newsData, index: number) => (
									<div key={index} className='d-flex justify-content-space-between align-items-center pt--10 pb-10  '>
										<p
											className='font--14px global-search-title cursor-pointer'
											onClick={() => {
												closeGlobalSearch();
												navigate(
													`/news/fullNewsArticle/0?newsId=${newsData.id}${
														whitelabelId && news_status !== 'inactive' ? `&whitelabelId=${whitelabelId}` : ''
													}`
												);
											}}
										>
											{newsData.titel}
										</p>
										<p className='font--14px global-search-type'>{newsData.type}</p>
									</div>
								))}
								<p
									onClick={() => {
										closeGlobalSearch();
										navigate(
											`/news/articleList/0?word=${encodeURIComponent(searchTitle)}${
												whitelabelId && news_status !== 'inactive' ? `&whitelabelId=${whitelabelId}` : ''
											}`
										);
									}}
									className='font--14px global-search-title cursor-pointer'
								>
									<Translate text={localizationConstants.more} />
								</p>
							</>
						)}
						{isEmpty(allGlobalSearchData.news) && !loading && <EmptySearchComponent />}
					</>
				);
			case 'Price':
				return (
					<>
						{isEmpty(allGlobalSearchData.material) && loading && <Loader />}
						{!isEmpty(allGlobalSearchData.material) && (
							<>
								{allGlobalSearchData.material.map((materialData, index: number) => (
									<div key={index} className='d-flex justify-content-space-between align-items-center pt--10 pb-10'>
										{isEmpty(userData.admin) && (
											<div className='icon__wrapper'>
												{materialData.disallowed === 1 ? (
													<LockIcon className='lock-icon' />
												) : (
													<UnlockIcon className='unlock-icon' />
												)}
											</div>
										)}{' '}
										<p
											className='font--14px global-search-title cursor-pointer'
											onClick={() => {
												closeGlobalSearch();
												navigate(
													`/materials/futureTable?globalSearchWordListenId=${
														materialData.id
													}&boerse=${replaceHashtagFromUrl(materialData.boerse)}&basis_symbol=${
														materialData.basis_symbol
													}&symbol=${replaceHashtagFromUrl(materialData.symbol)}&currency=${materialData.waehrung}`
												);
											}}
										>
											{language === 'en' && materialData.name_eng !== '' ? materialData.name_eng : materialData.name_deu}
										</p>
										<p className='font--14px global-search-type'>
											<Translate text={localizationConstants.price} />
										</p>
									</div>
								))}
								<p
									onClick={() => navigate(`/materials/search?wordSearch=${encodeURIComponent(searchTitle)}`)}
									className='font--14px global-search-title cursor-pointer'
								>
									<Translate text={localizationConstants.more} />
								</p>
							</>
						)}
						{isEmpty(allGlobalSearchData.material) && !loading && <EmptySearchComponent />}
					</>
				);
			default:
				break;
		}
	};

	const handleOnEnter = (key: string) => {
		if (key === 'Enter') {
			setSearchTitle('');
			if (state.globalActiveCategories === 'Price' || state.globalActiveCategories === 'All') {
				navigate(`/materials/search?wordSearch=${encodeURIComponent(searchTitle)}`);
			} else if (state.globalActiveCategories === 'News') {
				closeGlobalSearch();
				navigate(
					`/news/articleList/0?&word=${encodeURIComponent(searchTitle)}${
						whitelabelId && news_status !== 'inactive' ? `&whitelabelId=${whitelabelId}` : ''
					}`
				);
			}
		}
	};

	const onLogout = () => LoginActions.logout(dispatch);

	if (!authData.auth) {
		return <Navigate replace to='/' />;
	}

	const handleOnSearch = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchTitle(event.target.value);
		setState((prevState) => ({
			...prevState,
			isPopUpOpen: true,
			isActive: true
		}));
		searchData(event.target.value);
	};

	const { globalActiveCategories, isActive, isPopUpOpen } = state;

	return (
		<>
			<div className='border-bottom full-width top-header' ref={myRef}>
				<div className='navbar navbar-static-top d-flex align-items-center justify-content-space-between '>
					<div className='navbar-header position--relative d-flex justify-content-center align-items-center'>
						{(material_status !== 'hideall' || news_status !== 'hideall') && (
							<SearchComponent
								globalActiveCategories={globalActiveCategories}
								searchTitle={searchTitle}
								handleOnEnter={handleOnEnter}
								handleOnSearch={handleOnSearch}
								placeholder={
									material_status === 'hideall'
										? t(localizationConstants.searchNewsEtc)
										: news_status === 'hideall'
										? t(localizationConstants.searchMaterialEtc)
										: t(localizationConstants.searchNewsMaterialEtc)
								}
							/>
						)}
						{ENVIRONMENT !== 'production' && (
							<p className='no-margin font--16px line-height--20 font-weight--600 ml-15'>{capitalizeFirstLetter(ENVIRONMENT)}</p>
						)}
						{isPopUpOpen && searchTitle !== '' && isActive && (
							<div className='position--absolute  global-search-box'>
								<div className='d-flex global-search-categories align-items-center'>
									{!(material_status === 'hideall' || news_status === 'hideall') && (
										<div>
											<p
												className={`global-categories-text font--14px text-capitalize cursor-pointer ${
													globalActiveCategories === 'All' && 'active'
												} `}
												onClick={() => {
													setState({
														...state,
														globalActiveCategories: 'All'
													});
													myRef.current.scrollTo(0, 0);
												}}
											>
												<Translate text={localizationConstants.all} />
											</p>
										</div>
									)}
									{news_status !== 'hideall' && (
										<div>
											<p
												className={`global-categories-text font--14px cursor-pointer ${
													globalActiveCategories === 'News' && 'active'
												} `}
												onClick={() => {
													setState((state) => ({ ...state, globalActiveCategories: 'News' }));
													myRef.current.scrollTo(0, 0);
												}}
											>
												<Translate text={localizationConstants.news} />
											</p>
										</div>
									)}
									{material_status !== 'hideall' && (
										<div>
											<p
												className={`global-categories-text font--14px cursor-pointer ${
													globalActiveCategories === 'Price' && 'active'
												} `}
												onClick={() => {
													setState((state) => ({ ...state, globalActiveCategories: 'Price' }));
													myRef.current.scrollTo(0, 0);
												}}
											>
												<Translate text={localizationConstants.price} />
											</p>
										</div>
									)}
								</div>
								<div className='global-search-content'>{renderSearchComponents(globalActiveCategories)}</div>
							</div>
						)}
					</div>
					<div className='d-flex justify-content-center align-items-center '>
						<p className='no-margin font--14px line-height--25 font-weight--600 mr-6'>
							{userData.vorname} {userData.nachname}
						</p>
						<div className='d-flex'>
							<HeaderButtons toggleModalStatus={toggleModalStatus} />
						</div>
					</div>
				</div>
				{state.isOpen && (
					<Confirm
						show={state.isOpen}
						handleClose={toggleModalStatus}
						handleConfirm={onLogout}
						loading={logoutLoading}
						message={`${t(localizationConstants.confirmLogout)}`}
					/>
				)}
			</div>
		</>
	);
};

export default TopHeader;

import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { isEmpty, every, filter, has, maxBy } from 'lodash';
import { v4 as uuid } from 'uuid';

import { Loader } from 'shared/components/spinner/spinner';
import { API_CONFIG, STROKESCOLORS, colorPallet } from 'shared/constants/constants';
import { useWhitelabelConfigStatus } from 'shared/hooks/useWhitelabelConfigStatus';
import { State } from 'shared/interface';
import { ChartAxisPosition, ScaleType } from 'shared/interface/enums';
import httpService from 'shared/services/http.service';
import localizationConstants from 'shared/util/translation/constants';
import { chartTimeRangeMapperForNumber, defineChartMargins, replaceHashtagFromUrl } from 'shared/util/utility';

import StockChart from 'features/futuretable/component/stockChart';
import {
	IAdvanceChartConfigType,
	IChartData,
	IChartDesignConfig,
	IChartDetails,
	IChartPayloadType,
	IChartSettingsType
} from 'features/futuretable/interface/futureTable';
import { IndicatorsDropdown, indicatorRange, indicatorsWindowWidth } from 'features/futuretable/util/chartUtility';
import DashboardCardHeader from './dashboardCardHeader';
import { Translate } from 'shared/util/translation/translate';

interface Props {
	deleteData: (data: string, action: boolean) => void;
	chartInfo: any;
	cardIndex: number;
}

let mainChartData: { [key: string]: IChartData[] } = {};
let dataForIndicators: {
	[key: string]: {
		[key: string]: IChartData[];
	};
} = {};
let chartDetails: {
	[key: string]: {
		[key: string]: IChartDetails;
	};
} = {};
let advanceChartConfig: { [key: string]: IAdvanceChartConfigType } = {};

const ChartComponent = (props: Props) => {
	const { material_status } = useWhitelabelConfigStatus();
	const navigate = useNavigate();
	const params = useParams();
	const location = useLocation();
	const { chartInfo } = props;
	const { language, chartView } = useSelector((state: State) => ({ ...state.siteConfig }));
	const [chartData, setChartData] = useState<IChartData[]>([]);
	const [chartDesignConfig, setChartDesignConfig] = useState({} as { [key: string]: IChartDesignConfig });
	const [strokeColors, setStrokeColors] = useState(STROKESCOLORS);
	const [separateLoading, setSeparateLoading] = useState({} as { [key: string]: boolean });
	const [rearrangedData, setRearrangedData] = useState([] as any);
	const chartSelection = chartInfo.chartConfig.chartSelection;
	const [updatedChartSettings, setUpdatedChartSettings] = useState<IChartSettingsType[]>([]);

	const [barWidth, setBarWidth] = useState<{ [key: string]: number }>({});
	useEffect(() => {
		mainChartData = {
			...mainChartData,
			[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`]:
				mainChartData[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`] || []
		};
		dataForIndicators = {
			...dataForIndicators,
			[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`]:
				dataForIndicators[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`] || []
		};
		advanceChartConfig = {
			...advanceChartConfig,
			[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`]: advanceChartConfig[
				`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`
			] || {
				percentageChange: chartView === ScaleType.PERCENTAGE_CHANGE || chartView === ScaleType.PERCENTAGE_WITH_LOGARITHMIC,
				logarithmicYAxis: chartView === ScaleType.LOGARITHAMIC_AXIS || chartView === ScaleType.PERCENTAGE_WITH_LOGARITHMIC,
				scaleType: chartSelection.chartView || chartView,
				chartSettings: []
			}
		};
		setStrokeColors({ ...strokeColors, ...chartInfo.chartConfig.strokeColors });
		fetchChartData();
	}, [chartInfo]);

	const chartOrder = useCallback(
		(data: any) => {
			const chartSettings = advanceChartConfig[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`].chartSettings;
			const convertedArray = chartSettings.map((item, settingIndex) => {
				const maxPrice = maxBy(data, (d: any) => d[`cv${item.symbol}-${item.boerse}-${settingIndex}`]?.l);
				return {
					oldKey: `cv${item.symbol}-${item.boerse}-${settingIndex}`,
					value: maxPrice,
					index: settingIndex,
					symbol: item.symbol,
					boerse: item.boerse
				};
			});
			return convertedArray;
		},
		[advanceChartConfig]
	);

	const fetchChartData = async () => {
		const isIframe = location.pathname.includes('iframe');
		try {
			setSeparateLoading({
				...separateLoading,
				[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`]: true
			});
			const chartIndicators = chartSelection.indicator.map((indi: any) => {
				if (typeof indi === 'string') {
					if (indi === 'sto') {
						return {
							...indicatorRange.sto,
							value: indi,
							label: IndicatorsDropdown.find((value) => value.value === indi)?.label || '',
							symbol: chartInfo.symbol,
							boerse: chartInfo.boerse
						};
					} else if (indi === 'macd') {
						return {
							...indicatorRange.macd,
							value: indi,
							label: IndicatorsDropdown.find((value) => value.value === indi)?.label || '',
							symbol: chartInfo.symbol,
							boerse: chartInfo.boerse
						};
					} else {
						return {
							value: indi,
							label: IndicatorsDropdown.find((value) => value.value === indi)?.label || '',
							symbol: chartInfo.symbol,
							boerse: chartInfo.boerse,
							calculatedValue: indicatorsWindowWidth[indi]
						};
					}
				} else if (typeof indi === 'object') {
					return { ...indi };
				}
			});

			const axisPosition = has(chartSelection, 'axis') ? chartSelection.axis : chartView === ScaleType.PERCENTAGE_CHANGE ? 'left' : 'right';
			const chartSettingsFromComparativeArray = [
				{
					id: uuid(),
					name: '',
					symbol: chartInfo.symbol,
					boerse: chartInfo.boerse,
					unit: { label: chartSelection?.unit || '', value: chartSelection?.unit || '' },
					currency: {
						label: chartSelection.currency || '',
						value: chartSelection.currency || ''
					},
					indicators:
						!has(chartInfo.chartConfig, 'advanceChartConfig') || chartSelection.indicator.length > 0
							? chartIndicators
							: chartInfo.chartConfig.advanceChartConfig.chartSettings[0].indicators,
					history: {
						label: chartSelection?.history || t(localizationConstants.none),
						value: chartSelection?.history || ''
					},
					axis: {
						value: axisPosition,
						label: has(chartSelection, 'axis')
							? chartSelection.axis.charAt(0).toUpperCase() + chartSelection.axis.slice(1)
							: chartView === ScaleType.PERCENTAGE_CHANGE
							? 'Left'
							: 'Right'
					},
					color: chartSelection.chartColor || colorPallet[0]
				}
			] as IChartSettingsType[];
			if (chartInfo.chartConfig.comparativeChartIndicator.length > 0) {
				chartInfo.chartConfig.comparativeChartIndicator.map((chart: any) => {
					chartSettingsFromComparativeArray.push({
						id: chart?.id || uuid(),
						name: '',
						symbol: chart.symbol,
						boerse: chart.boerse,
						unit: { label: chart.unit?.value || '', value: chart.unit?.value || '' },
						currency: { label: chart.waehrung || chart.currency?.label || '', value: chart.waehrung || chart.currency?.value || '' },
						indicators: chart.indicators || [],
						history: { label: chart?.history?.value || t(localizationConstants.none), value: chart?.history?.value || '' },
						axis: chart.axis || { value: 'right', label: 'Right' },
						color: chart.color || colorPallet[chartSettingsFromComparativeArray.length],
						disallowed: chart.disallowed,
						basis_symbol: chart.basis_symbol
					});
				});
			}
			advanceChartConfig = {
				...advanceChartConfig,
				[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`]: {
					...advanceChartConfig[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`],
					scaleType: chartSelection.chartView || chartView,
					chartSettings: [...chartSettingsFromComparativeArray]
				}
			};
			let payload: IChartPayloadType = {
				data: advanceChartConfig[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`].chartSettings.map((chart) => {
					return {
						symbol: chart.symbol,
						boerse: chart.boerse,
						unit: chart.unit?.value,
						waehrung: chart.currency?.value,
						year: parseInt(chart.history.value)
					};
				})
			};
			const customChartSpan = chartInfo.chartConfig.customChartSpan;
			if (
				customChartSpan?.von !== undefined &&
				customChartSpan?.bis !== undefined &&
				customChartSpan?.von !== '' &&
				customChartSpan?.bis !== ''
			) {
				payload = {
					...payload,
					start_date: customChartSpan?.von,
					end_date: customChartSpan?.bis
				};
			} else {
				payload = {
					...payload,
					span:
						chartSelection.type === 'bar'
							? 'max'
							: chartTimeRangeMapperForNumber(
									chartData,
									typeof chartSelection.span === 'string' ? chartSelection.span : chartSelection.span.toString()
							  )
				};
			}
			const response = await httpService.post(
				isIframe ? `${API_CONFIG.path.getIFrameChartData}/${params?.id}` : API_CONFIG.path.getChartData4,
				payload,
				{},
				{ isAccessTokenRequire: !isIframe }
			);
			const chartHighValues = chartOrder(response.data);
			const sortedData = [...chartHighValues].sort((a, b) => a.value - b.value);
			const newData = sortedData.map((entry, index) => ({
				...entry,
				newIndex: index,
				updatedKey: `cv${entry.symbol}-${entry.boerse}-${index}`
			}));
			response.data.forEach((objToUpdate: any) => {
				newData.forEach((update) => {
					const { oldKey, updatedKey } = update;
					if (Object.prototype.hasOwnProperty.call(objToUpdate, oldKey)) {
						objToUpdate[updatedKey] = objToUpdate[oldKey];
						if (oldKey !== updatedKey) {
							delete objToUpdate[oldKey];
						}
					}
				});
			});
			advanceChartConfig = {
				...advanceChartConfig,
				[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`]: {
					...advanceChartConfig[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`],
					scaleType: chartSelection.chartView || chartView,
					chartSettings: advanceChartConfig[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`].chartSettings.map(
						(chart, index) => {
							return {
								...chart,
								name:
									(language === 'en' &&
										response.headers[`${chart.symbol}-${chart.boerse}-${index}`]?.name_eng !== '' &&
										response.headers[`${chart.symbol}-${chart.boerse}-${index}`]?.name_eng) ||
									response.headers[`${chart.symbol}-${chart.boerse}-${index}`]?.name ||
									'',
								unit: {
									label: response.headers[`${chart.symbol}-${chart.boerse}-${index}`].einheit,
									value: response.headers[`${chart.symbol}-${chart.boerse}-${index}`].einheit
								},
								currency: {
									label: response.headers[`${chart.symbol}-${chart.boerse}-${index}`].waehrung,
									value: response.headers[`${chart.symbol}-${chart.boerse}-${index}`].waehrung
								},
								basis_symbol: response.headers[`${chart.symbol}-${chart.boerse}-${index}`].basis_symbol
							};
						}
					)
				}
			};
			mainChartData = {
				...mainChartData,
				[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`]: response.data
			};
			setRearrangedData(newData);
			setChartData(mainChartData[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`]);
			const tempPayload: any = [];
			const tempObj: any = {};
			for (let i = 0; i < newData.length; i++) {
				const { index, symbol, boerse, newIndex } = newData[i];
				tempPayload.push(payload.data[index]);
				tempObj[`${symbol}-${boerse}-${newIndex}`] = response.headers[`${symbol}-${boerse}-${index}`];
			}
			chartDetails = {
				...chartDetails,
				[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`]: { ...tempObj }
			};

			setChartDesignConfig({
				...chartDesignConfig,
				[`${chartInfo.symbol}${props.cardIndex}`]: {
					margin: {
						left: defineChartMargins(
							advanceChartConfig[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`].chartSettings,
							true
						)?.left as number,
						right: defineChartMargins(
							advanceChartConfig[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`].chartSettings,
							true
						)?.right as number,
						top: 10,
						bottom: props.cardIndex > 1 ? 30 : 40
					},
					fontSize: props.cardIndex >= 2 ? 10 : 12,
					rectWidth: props.cardIndex >= 2 ? 40 : 55,
					rectHeight: props.cardIndex >= 2 ? 15 : 20
				}
			});
			setSeparateLoading({
				...separateLoading,
				[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`]: false
			});
		} catch (error) {
			console.error('error', error);
			setSeparateLoading({
				...separateLoading,
				[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`]: false
			});
		}
	};

	const currency = isEmpty(chartSelection.currency) ? 'EUR' : chartSelection.currency;
	const isLeftAxis =
		filter(advanceChartConfig[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`]?.chartSettings, {
			axis: { value: ChartAxisPosition.LEFT_AXIS }
		}).length > 0;
	const isRightAxis =
		filter(advanceChartConfig[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`]?.chartSettings, {
			axis: { value: ChartAxisPosition.RIGHT_AXIS }
		}).length > 0;
	const chartDetailsIndex = rearrangedData.filter((item: any) => {
		return chartInfo.boerse === item.boerse && chartInfo.symbol === item.symbol;
	});
	useEffect(() => {
		const chartSettings = advanceChartConfig[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`].chartSettings;
		const tempArray = [];
		if (!isEmpty(rearrangedData)) {
			for (let i = 0; i < rearrangedData.length; i++) {
				const { index } = rearrangedData[i];
				if (chartSettings[index]) {
					tempArray.push(chartSettings[index]);
				}
			}
		}
		setUpdatedChartSettings(tempArray);
	}, [chartInfo.boerse, chartInfo.symbol, rearrangedData]);

	const onMouseEnter = () => {
		const pageWrapper = document.getElementById('dashboard-scrollable--wrapper');
		if (pageWrapper) {
			pageWrapper.style.overflow = 'hidden';
		}
	};
	const onMouseLeave = () => {
		const pageWrapper = document.getElementById('dashboard-scrollable--wrapper');
		if (pageWrapper) {
			pageWrapper.style.overflow = 'auto';
		}
	};

	const navigateToPage = useCallback(() => {
		const navigateUrl = !isEmpty(chartInfo.chartConfig.comparativeChartIndicator)
			? `/materials/chartScreen?timeSpan=${chartSelection.span}&currency=${currency}&element_id=${props.chartInfo.element_id}`
			: `/materials/futureTable?element_id=${props.chartInfo.element_id}&boerse=${replaceHashtagFromUrl(props.chartInfo.boerse)}&basis_symbol=${
					props.chartInfo.basis_symbol
			  }&materialParentId=${0}&symbol=${replaceHashtagFromUrl(props.chartInfo.symbol)}&currency=${currency}`;
		if (material_status === 'hideall') {
			return;
		} else {
			navigate(navigateUrl, {
				state: {
					chartSelection: { ...chartSelection, customChartSpan: chartInfo.chartConfig.customChartSpan },
					customChartSpan: chartInfo.chartConfig.customChartSpan,
					comparativeChartIndicator: chartInfo.chartConfig.comparativeChartIndicator || [],
					strokeColors: chartInfo.chartConfig.strokeColors,
					disallowed:
						chartDetails[chartInfo.symbol + '-' + chartInfo.boerse + '-' + chartInfo.element_id][
							`${chartInfo.symbol}-${chartInfo.boerse}-0`
						]?.disallowed,
					advanceChartConfig: advanceChartConfig[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`]
				}
			});
		}
	}, [
		chartInfo.boerse,
		chartInfo.chartConfig.comparativeChartIndicator,
		chartInfo.chartConfig.customChartSpan,
		chartInfo.chartConfig.strokeColors,
		chartInfo.element_id,
		chartInfo.symbol,
		chartSelection,
		currency,
		material_status,
		navigate,
		props.chartInfo.basis_symbol,
		props.chartInfo.boerse,
		props.chartInfo.element_id,
		props.chartInfo.symbol
	]);

	return (
		<>
			{!isEmpty(advanceChartConfig[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`]) && (
				<DashboardCardHeader
					name={language === 'de' ? props.chartInfo.name : props.chartInfo.name_eng || props.chartInfo.name}
					action={true}
					deleteData={props.deleteData}
					elementId={props.chartInfo.element_id}
					section='chart'
					advanceChartConfig={{ ...advanceChartConfig[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`] }}
					updatedChartSettings={updatedChartSettings}
					cardIndex={props.cardIndex}
					navigateToPage={navigateToPage}
					chartDetails={chartDetails[chartInfo.symbol + '-' + chartInfo.boerse + '-' + chartInfo.element_id]}
				/>
			)}
			<div
				className={`chart-wrapper ${isLeftAxis && isRightAxis ? 'left-right-axis' : isRightAxis ? 'right-axis' : ''}`}
				id={`chart-container-dashboard-${chartInfo.symbol}-${props.cardIndex}`}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				{separateLoading[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`] && <Loader className='pt-10' />}
				{!separateLoading[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`] &&
					!isEmpty(chartDetails[chartInfo.symbol + '-' + chartInfo.boerse + '-' + chartInfo.element_id]) &&
					isEmpty(
						Object.values(chartDetails[chartInfo.symbol + '-' + chartInfo.boerse + '-' + chartInfo.element_id]).filter(
							(item) => item.disallowed === 0
						)
					) && <div className='d-flex justify-content-center align-items-center locked-chart' style={{ height: '100%' }} />}
				{isEmpty(chartData) &&
					!separateLoading[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`] &&
					!isEmpty(chartDetails[chartInfo.symbol + '-' + chartInfo.boerse + '-' + chartInfo.element_id]) &&
					every(Object.values(chartDetails[chartInfo.symbol + '-' + chartInfo.boerse + '-' + chartInfo.element_id]), ['disallowed', 0]) && (
						<p className='no-result-title pt--20 text-align-center font--20px'>
							<Translate text={localizationConstants.noDataFound} />
						</p>
					)}
				{!isEmpty(chartData) &&
					chartDetailsIndex &&
					!separateLoading[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`] &&
					!isEmpty(updatedChartSettings) &&
					!isEmpty(chartDetails[chartInfo.symbol + '-' + chartInfo.boerse + '-' + chartInfo.element_id]) &&
					!isEmpty(
						Object.values(chartDetails[chartInfo.symbol + '-' + chartInfo.boerse + '-' + chartInfo.element_id]).filter(
							(item) => item.disallowed === 0
						)
					) &&
					!isEmpty(advanceChartConfig[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`]) && (
						<StockChart
							chartData={chartData}
							chartType={chartSelection.chartView === ScaleType.PERCENTAGE_CHANGE ? 'line' : chartSelection.type}
							showZoomButton={false}
							loading={separateLoading[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`]}
							chartDetails={
								chartDetails[chartInfo.symbol + '-' + chartInfo.boerse + '-' + chartInfo.element_id][
									`${chartInfo.symbol}-${chartInfo.boerse}-${[chartDetailsIndex[0].newIndex]}`
								]
							}
							cardIndex={props.cardIndex}
							chartDesignConfig={chartDesignConfig[`${chartInfo.symbol}${props.cardIndex}`]}
							spanObj={{
								span: chartTimeRangeMapperForNumber(
									chartData,
									typeof chartSelection.span === 'string' ? chartSelection.span : chartSelection.span.toString()
								)
							}}
							chartName='dashboard'
							chartSettings={updatedChartSettings}
							scaleType={advanceChartConfig[`${chartInfo.symbol}-${chartInfo.boerse}-${chartInfo.element_id}`].scaleType}
							barWidth={barWidth}
							setBarWidth={setBarWidth}
							allChartDetails={chartDetails[chartInfo.symbol + '-' + chartInfo.boerse + '-' + chartInfo.element_id]}
						/>
					)}
			</div>
		</>
	);
};

export default ChartComponent;

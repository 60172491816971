import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import Button from 'shared/components/form/button';
import httpService from 'shared/services/http.service';
import { API_CONFIG, WINDOW_WIDTH } from 'shared/constants/constants';
import ColorLayout from 'shared/hoc/colorLayout/colorLayout';
import { BackwardArrow, ForwarArrow, MatflixxEndBmeIcon } from 'shared/icons/icon';
import { createAction } from 'shared/util/utility';

import FavouriteSortableItem from 'features/dashboard/component/favouriteSortableItem';
import { IDashboardData } from 'features/dashboard/interface/dashboard';
import { createChartConfig } from 'features/futuretable/util/chartUtility';
import * as actionTypes from 'store/action-types';
import '../style/dashboardIframe.scss';

const DashboardIframe: React.FC = () => {
	const [data, setData] = useState<IDashboardData[]>([]);
	const [loading, setLoading] = useState(false);
	const params = useParams();
	const dispatch = useDispatch();

	const getIframeData = useCallback(() => {
		setLoading(true);
		httpService
			.get(
				`${API_CONFIG.path.getDashboardIFrameData}/${params.id}`,
				{},
				{
					isAccessTokenRequire: false
				}
			)
			.then((response) => {
				dispatch(
					createAction(actionTypes.UPDATE_SITE_CONFIG, {
						language: response.language
					})
				);
				const chartData = createChartConfig(response.dashboard);
				setData(chartData);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.error(err);
			});
	}, [dispatch, params.id]);

	useEffect(() => {
		getIframeData();
		const interval = setInterval(() => {
			getIframeData();
		}, 30 * 60 * 1000);
		return () => clearInterval(interval);
	}, [getIframeData]);

	return (
		<ColorLayout>
			<div className={`iframe-container ${params.resolution}-view`}>
				<div className='matflixx-bme-icon__wrapper d-flex justify-content-start align-items-center'>
					<MatflixxEndBmeIcon />
				</div>
				{!isEmpty(data) && (
					<div className={`d-flex mb-10 widget-container mt-23 position--relative`}>
						<Fragment>
							{WINDOW_WIDTH < 640 && (
								<div className={`swiper-arrow__wrapper left-arrow d-flex justify-content-space-between align-items-center`}>
									<BackwardArrow className={`slick-prev  cursor-pointer`} />
								</div>
							)}
							<Swiper
								pagination={{
									enabled: true,
									type: 'bullets',
									dynamicBullets: true,
									dynamicMainBullets: 3
								}}
								loop={false}
								navigation={false}
								modules={[Pagination, Navigation]}
								className='mySwiper'
								enabled={true}
								breakpoints={{
									// when window width is >= 320px
									320: {
										slidesPerView: 1,
										spaceBetween: 30,
										navigation: {
											nextEl: `.slick-next`,
											prevEl: `.slick-prev`,
											enabled: true,
											disabledClass: 'slick-disabled'
										}
									},
									// when window width is >= 640px
									640: {
										slidesPerView: 3,
										spaceBetween: 30
									}
								}}
							>
								{data.map((item, index) => {
									return (
										<SwiperSlide key={item.element_id}>
											<FavouriteSortableItem
												value={item}
												key={`${item.name}_${index}`}
												cardIndex={index}
												deleteData={() => undefined}
												section={''}
												newsLength={0}
												onSaveNews={() => undefined}
												onSaveTender={() => undefined}
												toggleCardView={() => undefined}
												costModalUpdateView={() => undefined}
												news_widget_animation={false}
											/>
										</SwiperSlide>
									);
								})}
							</Swiper>
							{WINDOW_WIDTH < 640 && (
								<div className={`swiper-arrow__wrapper right-arrow d-flex justify-content-space-between align-items-center`}>
									<ForwarArrow className={`slick-next  cursor-pointer`} />
								</div>
							)}
						</Fragment>
					</div>
				)}
				{isEmpty(data) && !loading && <h2 className='text-align-center mb-30'>Keine Daten gefunden</h2>}
				<div className='text-align-center'>
					<a href='https://www.matflixx.de/bme24' target='_blank'>
						<Button btnType='iframe-gradient' className='register-btn text-align-center'>
							JETZT REGISTRIEREN
						</Button>
					</a>
				</div>
			</div>
		</ColorLayout>
	);
};
export default DashboardIframe;

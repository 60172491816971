import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'shared/components/form/button';
import { API_CONFIG } from 'shared/constants/constants';
import httpService from 'shared/services/http.service';
import ColorLayout from 'shared/hoc/colorLayout/colorLayout';

import MatfoxxWithCap from 'assets/images/matfoxx-cap.png';
import './error.scss';

interface IProps {
	errorMessage: string;
	resetError: () => void;
}

const ErrorPage: React.FC<IProps> = ({ errorMessage, resetError }) => {
	const navigate = useNavigate();

	const sendErrorToSlack = useCallback(() => {
		httpService
			.post(API_CONFIG.path.sharedSlackError, { message: errorMessage })
			.then((response) => {
				console.log('Error sent to Slack:', response);
			})
			.catch((error) => {
				console.error('Error sending to Slack:', error);
			});
	}, [errorMessage]);

	useEffect(() => {
		sendErrorToSlack();
	}, [sendErrorToSlack]);

	const handleButtonClick = () => {
		resetError();
		navigate('/dashboard');
	};

	return (
		<ColorLayout>
			<div className='error-page__wrapper d-flex justify-content-center align-items-center flex-column'>
				<div className='matfoxx__wrapper'>
					<img src={MatfoxxWithCap} alt='Matfoxx with Cap' />
				</div>
				<p className='color-white font--60px line-height--88px font-weight--600 text-align-center'>
					Entschuldigung, es ist ein Fehler unterlaufen.
				</p>
				<p className='color-white font--60px line-height--88px font-weight--600 text-align-center'>Wir sind schon daran ihn zu beheben.</p>
				<Button btnType='accent' className='button-size--medium mt-95' onClick={handleButtonClick}>
					Zurück
				</Button>
			</div>
		</ColorLayout>
	);
};

export default ErrorPage;

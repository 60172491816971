export enum CardView {
	LIST_VIEW = 'listView',
	GRID_VIEW = 'gridView'
}

export enum Alert {
	ALLALERTS = 'allAlerts',
	COURSE_EXCEEDED = 'course exceeded',
	COURSE_UNDERSHOT = 'course undershot',
	DIFFERENCE = 'abs. perc. deviation d.K. exceeded',
	KURS_EXCEEDED = 'Kurs überschritten',
	KURS_UNDERSHOT = 'Kurs unterschritten',
	KURS_DIFFERENCE = 'Abs. proz. Abweichung d. K. überschritten',
	POSITIVE_DIFFERENCE_GERMEN = '% positive Abweichung',
	POSITIVE_DIFFERENCE = '% positive deviation',
	NEGATIVE_DIFFERENCE_GERMEN = '% negative Abweichung',
	NEGATIVE_DIFFERENCE = '% negative deviation',
	OTHER_ALERTS = 'other alerts'
}

export enum AlertPlaceHolder {
	EXCEEDED = 'z.B. 438.35',
	UNDERSHOT = 'z.B. 358.65',
	POSITIVE_DIFFERENCE = 'z.B. 4,6%',
	NEGATIVE_DIFFERENCE = 'z.B. -4,6%'
}

export enum OnboardingActions {
	FIRST_STEP = 'firstStep',
	SECOND_STEP = 'secondStep'
}

export enum DashboardSection {
	FAVORITE_SECTION = 'favorite',
	MATERIAL_SECTION = 'myData',
	NEWS_SECTION = 'myNews'
}

export enum NewsSection {
	ALL = 'All'
}

export enum ScaleType {
	PERCENTAGE_CHANGE = 'percentageChange',
	LOGARITHAMIC_AXIS = 'logarithamicYaxis',
	PRICE_AXIS = 'price',
	PERCENTAGE_WITH_LOGARITHMIC = 'percentageWithLogarithmic',
	SESSION_CHART = 'sessionChart'
}

export enum ChartAxisPosition {
	LEFT_AXIS = 'left',
	RIGHT_AXIS = 'right'
}

export enum COMMON_FORM_TYPE {
	DATA = 'data',
	NEWS = 'news'
}

import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom'; // Import useLocation for getting the current path

import localizationConstants from 'shared/util/translation/constants';
import { createAction } from 'shared/util/utility';

import * as LoginActions from 'features/login/store/login.action';
import { useSelector } from 'react-redux';
import { State } from 'shared/interface';
import * as actionTypes from 'store/action-types';
import { isEmpty } from 'lodash';

export const useInactivityTimer = (timeout = 1800000) => {
	const { t } = useTranslation();
	const timerRef = useRef<ReturnType<typeof setTimeout>>();
	const dispatch = useDispatch();
	const location = useLocation(); // Get the current path
	const { userData } = useSelector((state: State) => state.auth);

	const onLogout = useCallback(() => {
		LoginActions.logout(dispatch);
		dispatch(
			createAction(actionTypes.UPDATE_SITE_CONFIG, {
				sessionExpireMessage: t(localizationConstants.inActivityMessage)
			})
		);
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}
	}, [dispatch, t]);

	const resetTimer = useCallback(() => {
		// If user is on the login page, don't reset the timer
		if (location.pathname === '/login' || !isEmpty(userData.admin)) return;

		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}
		timerRef.current = setTimeout(onLogout, timeout);
	}, [location.pathname, userData.admin, onLogout, timeout]);

	useEffect(() => {
		resetTimer();
		return () => {
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
		};
	}, [resetTimer]);

	return resetTimer;
};

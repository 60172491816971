import { CrossIcon, SearchIcon } from 'shared/icons/icon';

interface Props {
	searchTitle: string | number;
	handleOnSearch: (value: React.ChangeEvent<HTMLInputElement>) => void;
	iconClassName?: string;
	className?: string;
	placeholder: string;
	disabled?: boolean;
	globalActiveCategories?: string;
	handleOnEnter?: (key: string) => void;
	clearable?: boolean;
	clearSearch?: () => void;
}
const SearchComponent = (props: Props) => {
	const { searchTitle, handleOnSearch, className, placeholder, iconClassName, disabled, handleOnEnter, clearSearch } = props;

	return (
		<>
			<div className={`${iconClassName ? iconClassName : 'search-icon position--absolute mr-10'}`}>
				<SearchIcon />
			</div>
			{props.clearable && (
				<div onClick={clearSearch} className='cross-icon__wrapper cursor-pointer position--absolute right-0'>
					<CrossIcon className='cross-icon' />
				</div>
			)}
			<input
				className={`${className ? className : 'search-input'} ${disabled && 'cursor-not-allowed'}`}
				type='search'
				name=''
				id=''
				value={searchTitle}
				placeholder={placeholder}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
					const value = e.target.value;
					if (value.length === 1 && value.startsWith(' ')) {
						e.target.value = ''; // Prevent leading whitespace
					} else {
						handleOnSearch && handleOnSearch(e);
					}
				}}
				disabled={disabled}
				onKeyUp={(event) => {
					handleOnEnter && handleOnEnter(event.key);
				}}
			/>
		</>
	);
};

export default SearchComponent;

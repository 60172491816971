import { Formik, FormikValues } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { NavLink } from 'react-router-dom';
import Button from './button';
import { FieldConfig, Input, InputDatePicker } from './inputTypes';
import { Translate } from 'shared/util/translation/translate';
import localizationConstants from 'shared/util/translation/constants';
import { DATE_FORMAT_MAPPER } from 'shared/constants/constants';
import { useSelector } from 'react-redux';
import { State } from 'shared/interface';

interface CustomFormProps {
	schema?: Yup.ObjectSchema<any>;
	onSubmit: (values: FormikValues) => void;
	loading: boolean;
	fieldConfig: FieldConfig[];
	initialValues?: any;
	dataTestId?: string;
	submitBtnText?: string | JSX.Element;
	validateOnBlur?: boolean;
	validateOnChange?: boolean;
	showMinDate?: boolean;
	title?: string;
	cancelSubmit?: React.ReactEventHandler<Record<string, never>>;
	customFormat?: boolean;
	formClassName?: string;
	inputDivClassName?: string;
	submitBtnClassName?: string;
	cancleBtnClassName?: string;
	error?: string;
	showLabels?: boolean;
	cancelBtnName?: string;
	showTitle?: boolean;
	dynamicPlaceholder?: {
		dependent: string;
		self: string;
		placeHolderMapper: {
			[key: string]: string | JSX.Element;
		};
	};
	login?: boolean;
	dirty?: boolean;
}

/**
 * common form to be rendered
 */
const CustomForm: React.FC<CustomFormProps> = (props) => {
	const validateOnBlur = props.validateOnBlur === undefined ? true : props.validateOnBlur;
	const validateOnChange = props.validateOnChange === undefined ? false : props.validateOnChange;
	const initialValues = { ...(props.initialValues || {}) };
	props.fieldConfig.forEach((config) => {
		if (!initialValues[config.name]) {
			initialValues[config.name] = '';
		}
	});
	const { dateFormat } = useSelector((state: State) => state.siteConfig);

	return (
		<div className=''>
			{!props.showTitle && (
				<div className=''>{!!props.title && <h5 className='text-align-left mt-28 mb-28 font--18px text--dark-blue'>{props.title}</h5>}</div>
			)}
			{!!props.title && !!props.showTitle && <h2 className='text-align-center'>{props.title}</h2>}
			<Formik
				initialValues={initialValues}
				validateOnBlur={validateOnBlur}
				validateOnChange={validateOnChange}
				onSubmit={props.onSubmit}
				validationSchema={props.schema}
				enableReinitialize
			>
				{({ handleSubmit, setFieldValue, values, dirty }) => {
					return (
						<form onSubmit={handleSubmit} className={props.formClassName || ''}>
							{props.fieldConfig.map((config, index) => {
								return config.type === 'date' ? (
									<InputDatePicker
										key={index}
										label={config.label}
										setFieldValue={setFieldValue}
										placeholder={config.placeHolder}
										name={config.name}
										minDate={
											(config.minDate &&
												((typeof config.minDate === 'string' && (values[config.minDate] || new Date())) || config.minDate)) ||
											(props.showMinDate && new Date())
										}
										maxDate={config.maxDate && config.maxDate}
										timeIntervals={1}
										dateFormat={props.customFormat && DATE_FORMAT_MAPPER[dateFormat]}
										onChange={(startDate: Date) => {
											// try {
											// 	if (props.customFormat && target.value && target.value.length > 0) {
											// 		let value = target.value;
											// 		if (target.value.length === 2) {
											// 			value = target.value + '/';
											// 		} else if (target.value.length === 5) {
											// 			value = target.value + '/';
											// 		}
											// 		console.log(value);

											// 		setFieldValue(config.name, value);
											// 	} else {
											setFieldValue(config.name, startDate);
											// 	}
											// } catch (error) {
											// 	console.log(error);
											// }
										}}
									/>
								) : (
									<Input
										showLabels={props.showLabels}
										key={index}
										setFieldValue={setFieldValue}
										config={config}
										placeholder={
											props.dynamicPlaceholder && props.dynamicPlaceholder.self === config.name
												? props.dynamicPlaceholder.placeHolderMapper[values[props.dynamicPlaceholder.dependent]] ||
												  config.placeHolder ||
												  config.label
												: config.placeHolder
										}
										type={config.type}
										name={config.name}
										className={`${props.inputDivClassName || ''} full-width mb-8`}
									/>
								);
							})}
							<div className=''>{!!props.error && <p className='error text-align-center'>{props.error}</p>}</div>
							<div className={`${props.cancleBtnClassName && 'd-flex '}`}>
								{props.cancelSubmit && (
									<Button
										btnType='secondary'
										onClick={props.cancelSubmit}
										type='button'
										className={`mr-2 ${props.cancleBtnClassName}`}
									>
										{props.cancelBtnName ? props.cancelBtnName : <Translate text={localizationConstants.cancel} />}
									</Button>
								)}
								<Button
									disabled={props.dirty ? !props.dirty : !dirty}
									className={`${props.submitBtnClassName}`}
									loading={props.loading}
									type='submit'
									btnType='primary'
								>
									{props.submitBtnText || 'Submit'}
								</Button>
							</div>
							{props.login && (
								<div className='d-flex align-items-end flex-column'>
									<p className='no-margin font--14px mb-10 text-grey'>
										<NavLink to='/'>Forgotten your password? </NavLink>
										Or are you not a subscriber yet?
									</p>
									<p className='font--14px text-grey'>
										<NavLink to='/'>Register</NavLink> now and test Matflixx for free.
									</p>
								</div>
							)}
						</form>
					);
				}}
			</Formik>
		</div>
	);
};

export { CustomForm };

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { API_CONFIG } from 'shared/constants/constants';
import { State } from 'shared/interface';
import { AnalyticService } from 'shared/services/api.service';
import AuthService from 'shared/services/auth.service';
import { getDifferenceInMinutes } from 'shared/util/utility';

import { IAnalyticsStore } from 'features/login/interface/login';

export const useSessionTracking = () => {
	const { benutzer_id, admin, isTakeOver } = useSelector((state: State) => ({ ...state.auth.userData, ...state.siteConfig }));
	const { pathname } = useLocation();
	const intervalRef = useRef<any>(null);
	const timeOutRef = useRef<any>(null);

	const [isActiveTab, setIsActiveTab] = useState(true);

	const shouldTrack = !isTakeOver;

	const isAdmin = useMemo(() => !isEmpty(admin), [admin]);

	const userSessionTrackingAnalytics = useCallback(async () => {
		const analyticsStore = AuthService.getAnalyticsSessionStore() as IAnalyticsStore;
		const { sessionStreamUserId, startTime: sessionStartTime, endTime } = analyticsStore;
		const sessionEndTime = new Date(new Date(endTime).getTime() + 10 * 60000).toISOString();

		const payload = {
			stream_id: process.env.REACT_APP_ANALYTICS_SESSION_STREAM_ID,
			data: {
				user_id: `${benutzer_id}`,
				session_started_at: sessionStartTime,
				session_ended_at: sessionEndTime,
				minutes: getDifferenceInMinutes(sessionStartTime, sessionEndTime),
				is_admin: isAdmin
			}
		};
		const response = await AnalyticService.post(
			API_CONFIG.path.userSessionAnalytics,
			payload,
			{},
			{
				apiKey: process.env.REACT_APP_ANALYTICS_API_KEY,
				streamUserId: sessionStreamUserId
			}
		);
		return {
			sessionStreamUserId: response.stream_user_id,
			endTime: sessionEndTime,
			startTime: sessionStartTime
		};
	}, [benutzer_id, isAdmin]);

	const trackingForEveryPageChange = useCallback(async () => {
		const analyticsStore = AuthService.getAnalyticsSessionStore() as IAnalyticsStore;
		const { pageVisitsStreamId, sessionStreamUserId } = analyticsStore;
		const payload = {
			stream_id: process.env.REACT_APP_ANALYTICS_PAGE_VISIT_STREAM_ID,
			data: { user_id: `${benutzer_id}`, is_admin: isAdmin }
		};
		const response = await AnalyticService.post(
			API_CONFIG.path.userPageVisitAnalytics,
			payload,
			{},
			{
				apiKey: process.env.REACT_APP_ANALYTICS_API_KEY,
				streamUserId: pageVisitsStreamId
			}
		);
		if (!pageVisitsStreamId && sessionStreamUserId) {
			AuthService.setAnalyticsSessionStore({
				...analyticsStore,
				pageVisitsStreamId: response.stream_user_id
			});
		}
		return { pageVisitsStreamId: response.stream_user_id };
	}, [benutzer_id, isAdmin]);

	const trackSession = useCallback(async () => {
		if (isActiveTab) {
			const analyticsStore = AuthService.getAnalyticsSessionStore() as IAnalyticsStore;
			const response = await userSessionTrackingAnalytics();
			AuthService.setAnalyticsSessionStore({
				...analyticsStore,
				sessionStreamUserId: response.sessionStreamUserId,
				endTime: response.endTime
			});
		}
	}, [isActiveTab, userSessionTrackingAnalytics]);

	useEffect(() => {
		if (!shouldTrack) return;

		const handleVisibilityChange = () => {
			const visibilityState = document.visibilityState;
			setIsActiveTab(visibilityState === 'visible');
			if (visibilityState === 'visible') {
				const analyticsStore = AuthService.getAnalyticsSessionStore() as IAnalyticsStore;
				const currentTime = new Date().setMilliseconds(0);
				const endTime = new Date(analyticsStore.endTime).setMilliseconds(0);

				if (currentTime > endTime) {
					AuthService.setAnalyticsSessionStore({
						...analyticsStore,
						sessionStreamUserId: '',
						startTime: new Date().toISOString(),
						endTime: new Date().toISOString()
					});
					if (intervalRef.current) {
						clearInterval(intervalRef.current);
						intervalRef.current = null;
					}
				}
			}
		};

		document.addEventListener('visibilitychange', handleVisibilityChange);
		return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
	}, [shouldTrack]);

	const startInterval = useCallback(() => {
		if (!intervalRef.current) {
			intervalRef.current = setInterval(() => {
				trackSession();
			}, intervalDuration);
		}
	}, [trackSession]);

	useEffect(() => {
		if (!shouldTrack) return;

		const analytics = AuthService.getAnalyticsSessionStore() as IAnalyticsStore;
		const currentTime = new Date().setMilliseconds(0);
		const endTime = new Date(analytics.endTime).setMilliseconds(0);
		const startTime = new Date(analytics.startTime).setMilliseconds(0);

		const isRefreshed = currentTime < endTime && startTime !== currentTime;

		const elapsedTime = currentTime - startTime;
		const timeUntilNextCall = intervalDuration - (elapsedTime % intervalDuration);

		if (analytics.sessionStreamUserId && isRefreshed) {
			if (!timeOutRef.current) {
				timeOutRef.current = setTimeout(() => {
					trackSession();
					startInterval();
				}, timeUntilNextCall);
			}
		} else if (!analytics.sessionStreamUserId && analytics.pageVisitsStreamId) {
			trackSession();
			startInterval();
		}

		return () => {
			clearInterval(intervalRef.current);
			clearTimeout(timeOutRef.current);
			intervalRef.current = null;
			timeOutRef.current = null;
		};
	}, [shouldTrack, startInterval, trackSession]);

	useEffect(() => {
		if (!shouldTrack) return;

		const analyticsStore = AuthService.getAnalyticsSessionStore() as IAnalyticsStore;
		const initializeAnalytics = async () => {
			try {
				const [sessionStreamData, pageVisitsStreamData] = await Promise.all([userSessionTrackingAnalytics(), trackingForEveryPageChange()]);
				AuthService.setAnalyticsSessionStore({
					...analyticsStore,
					pageVisitsStreamId: pageVisitsStreamData.pageVisitsStreamId,
					endTime: sessionStreamData.endTime,
					startTime: sessionStreamData.startTime,
					sessionStreamUserId: sessionStreamData.sessionStreamUserId
				});
			} catch (error) {
				console.error(error);
			}
		};
		if (isActiveTab && !analyticsStore.pageVisitsStreamId && !analyticsStore.sessionStreamUserId) {
			initializeAnalytics();
		}
	}, [isActiveTab, shouldTrack, trackingForEveryPageChange, userSessionTrackingAnalytics]);

	useEffect(() => {
		if (!shouldTrack) return;

		const analyticsStore = AuthService.getAnalyticsSessionStore() as IAnalyticsStore;
		if (!pathname.includes('login') && analyticsStore.pageVisitsStreamId) {
			trackingForEveryPageChange();
		}
	}, [trackingForEveryPageChange, pathname, shouldTrack]);
};

const intervalDuration = 600000; // 10 minutes

import React, { useEffect, useState, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import CreatableSelect from 'react-select/creatable';

import Button from 'shared/components/form/button';
import CustomModal from 'shared/components/modal/modal';
import { notify } from 'shared/components/notification/notification';
import { Loader } from 'shared/components/spinner/spinner';
import { API_CONFIG } from 'shared/constants/constants';
import httpService from 'shared/services/http.service';
import i18n from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';

import { IMaterialPriceList } from 'features/material/interface/material';
import { IMaterialPriceListDropDown } from '../interface/futureTable';

interface IProps {
	boerse: string | null;
	symbol: string | null;
	setAction: (action: string) => void;
}
interface Option {
	readonly label: string;
	readonly value: string;
}

const AddPricePopup: React.FC<IProps> = (props) => {
	const { t } = i18n;
	const { setAction, boerse, symbol } = props;
	const [courseDropDownList, setCourseDropDownList] = useState<IMaterialPriceList[]>([]);
	const [selectedValue, setSelectedValue] = useState<Option | null>(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		materialCourseListDropDown();
	}, []);

	const materialCourseListDropDown = () => {
		httpService
			.get(API_CONFIG.path.courseListDropDown)
			.then((response) => {
				const tempArr = [] as any;
				response.map((label: IMaterialPriceListDropDown) => {
					tempArr.push({ label: label.listen_name });
				});
				setCourseDropDownList(tempArr);
			})
			.catch((error) => {
				console.error('error', error);
			});
	};

	const createOption = (label: string) => ({
		label,
		value: label.toLowerCase().replace(/\W/g, '')
	});

	const handleOnChangeCourseList = useCallback((inputValue: string | undefined) => {
		const newOption: string | Option | undefined = !inputValue ? inputValue : createOption(inputValue);
		setSelectedValue(newOption as Option);
	}, []);
	const handleOnAddCourseList = () => {
		const payload = {
			savalist: [{ boerse: boerse, symbol: symbol }],
			listen_name: selectedValue?.label
		};
		setLoading(true);
		httpService
			.post(API_CONFIG.path.saveMaterial, payload)
			.then(() => {
				notify(t(localizationConstants.addedToList), 'success', { id: 'addedToList' });
				setAction('');
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				console.error('error', error);
			});
	};

	return (
		<CustomModal handleClose={() => setAction('')} show={true} className={'course-list-popup'}>
			{loading && <Loader />}
			{!loading && (
				<>
					<h2 className='modal-title font--24px font-weight--500'>
						<Translate text={localizationConstants.addToPrice} />
					</h2>
					<CreatableSelect
						isClearable={true}
						options={courseDropDownList}
						onChange={(newValue) => {
							handleOnChangeCourseList(newValue?.label);
						}}
						onCreateOption={handleOnChangeCourseList}
						value={selectedValue}
						placeholder={`${t(localizationConstants.mbiListPlaceHolder)}`}
						formatCreateLabel={(inputValue) => `${t(localizationConstants.create)} ${inputValue}`}
					/>
					<div className='full-width mt-10 text-align-right'>
						<Button
							onClick={handleOnAddCourseList}
							disabled={isEmpty(selectedValue) && true}
							btnType='primary'
							className='button-size--medium'
						>
							<Translate text={localizationConstants.add} />
						</Button>
					</div>
				</>
			)}
		</CustomModal>
	);
};

export default AddPricePopup;

import * as d3 from 'd3';
import { format } from 'd3-format';
import { Chart } from 'react-stockcharts';
import { MouseCoordinateY } from 'react-stockcharts/lib/coordinates';
import { LineSeries, MACDSeries, RSISeries, StochasticSeries } from 'react-stockcharts/lib/series';
import { MACDTooltip, RSITooltip, SingleValueTooltip, StochasticTooltip } from 'react-stockcharts/lib/tooltip';

import {
	atrFunction,
	externalChartIndicatorHeightConfig,
	fullSTOFunction,
	macdCalculatorFunction,
	rsiCalculatorFunction
} from 'features/futuretable/util/chartUtility';
import { STROKESCOLORS, mouseEdgeAppearance } from 'shared/constants/constants';
import { ScaleType } from 'shared/interface/enums';
import { germanNumberFormattter, renderTicks } from 'shared/util/utility';

import { IChartDesignConfig, IChartIndicators, IChartSettingsType } from '../interface/futureTable';
import ChartAxis from './chartAxis';
import { IMACDIndicatorInputType, ISTOIndicatorType } from './stockChart';

interface IProps {
	indi: IChartIndicators;
	chart: IChartSettingsType;
	cardIndex: number;
	chartType: string;
	chartDesignConfig: IChartDesignConfig;
	chartSettings: IChartSettingsType[];
	scaleType: string;
	index: number;
	data: any;
	span: string;
	isCompareWithSelfEnabled: boolean;
	chartName: string;
}

const macdAppearance = {
	stroke: {
		macd: '#FF0000',
		signal: '#00F300'
	},
	fill: {
		divergence: '#fd79a8'
	}
};

const stoAppearance = {
	stroke: Object.assign({}, StochasticSeries.defaultProps.stroke)
};

export const RenderExternalIndicators = (props: IProps) => {
	const { chartSettings, scaleType, cardIndex, chart, chartDesignConfig, chartType, indi, index, data, span, isCompareWithSelfEnabled, chartName } =
		props;
	const getRSIyExtent = [
		d3.min(data, (d: any) => d[`rsi-${chart.symbol}-${chart.boerse}-${chart.id}`]),
		d3.max(data, (d: any) => d[`rsi-${chart.symbol}-${chart.boerse}-${chart.id}`])
	];
	const getchart = () => {
		// const tickFormat = scaleType === ScaleType.PERCENTAGE_CHANGE ? format('.0%') : format('.2f');
		switch (indi.value) {
			case 'macd':
				return (
					<Chart
						id={`${chart.id}${chart.symbol}-${chart.boerse}-5`}
						height={(cardIndex < 2 && 135) || 100}
						yExtents={(d: any) => d[`macd-${chart.symbol}-${chart.boerse}-${chart.id}`]}
						origin={(w: any, h: any) => externalChartIndicatorHeightConfig(h, 'macd', cardIndex, chartSettings, chart)}
						padding={{ top: 35, bottom: 0 }}
					>
						<ChartAxis
							tickFormat={germanNumberFormattter}
							chartType={chartType}
							data={data}
							span={span as string}
							axisAt={chart.axis.value}
							chartDesignConfig={chartDesignConfig}
							xTicks={renderTicks(cardIndex, chartType === 'bar', chartName).xTicks}
							yTicks={5}
							isCompareWithSelfEnabled={isCompareWithSelfEnabled}
						/>
						<MouseCoordinateY
							at='right'
							orient='right'
							displayFormat={format('.2f')}
							fontSize={chartDesignConfig.fontSize}
							{...mouseEdgeAppearance}
						/>
						<MACDSeries yAccessor={(d: any) => d[`macd-${chart.symbol}-${chart.boerse}-${chart.id}`]} {...macdAppearance} />
						<MACDTooltip
							origin={[0, 30]}
							yAccessor={(d: any) => d[`macd-${chart.symbol}-${chart.boerse}-${chart.id}`]}
							options={macdCalculatorFunction(
								`compareValue${chart.symbol}-${chart.boerse}${chart.id}`,
								`macd-${chart.symbol}-${chart.boerse}-${chart.id}`,
								{
									kurz: indi.kurz,
									lang: indi.lang,
									signal: indi.signal
								} as IMACDIndicatorInputType
							).options()}
							appearance={macdAppearance}
							fontSize={chartDesignConfig.fontSize}
						/>
						<SingleValueTooltip
							key={`${chart.symbol}-${chart.boerse}`}
							yAccessor={(d: any) => d[`compareValue${chart.symbol}-${chart.boerse}${chart.id}`]}
							yLabel={chart.name}
							valueFill='transparent'
							origin={[300, 30]}
							fontSize={chartDesignConfig.fontSize}
						/>
					</Chart>
				);
			case 'rsi':
				return (
					<Chart
						id={`${chart.id}${chart.symbol}-${chart.boerse}-6`}
						yExtents={getRSIyExtent}
						height={(cardIndex < 2 && 135) || 100}
						origin={(w: any, h: any) => externalChartIndicatorHeightConfig(h, 'rsi', cardIndex, chartSettings, chart)}
						padding={{ top: 35, bottom: 0 }}
					>
						<ChartAxis
							tickFormat={germanNumberFormattter}
							chartType={chartType}
							data={data}
							span={span as string}
							axisAt={chart.axis.value}
							chartDesignConfig={chartDesignConfig}
							xTicks={renderTicks(cardIndex, chartType === 'bar', chartName).xTicks}
							yTicks={5}
							isCompareWithSelfEnabled={isCompareWithSelfEnabled}
						/>
						<MouseCoordinateY
							at='right'
							orient='right'
							displayFormat={format('.2f')}
							fontSize={chartDesignConfig.fontSize}
							{...mouseEdgeAppearance}
						/>
						<RSISeries yAccessor={(d: any) => d[`rsi-${chart.symbol}-${chart.boerse}-${chart.id}`]} />
						<RSITooltip
							yAccessor={(d: any) => d[`rsi-${chart.symbol}-${chart.boerse}-${chart.id}`]}
							options={rsiCalculatorFunction(
								`compareValue${chart.symbol}-${chart.boerse}${chart.id}`,
								`rsi-${chart.symbol}-${chart.boerse}-${chart.id}`,
								indi.calculatedValue
							).options()}
							origin={[0, 30]}
							fontSize={chartDesignConfig.fontSize}
						/>
						<SingleValueTooltip
							key={`${chart.symbol}-${chart.boerse}`}
							yAccessor={(d: any) => d[`compareValue${chart.symbol}-${chart.boerse}${chart.id}`]}
							yLabel={chart.name}
							valueFill='transparent'
							origin={[100, 30]}
							fontSize={chartDesignConfig.fontSize}
						/>
					</Chart>
				);
			case 'atr':
				return (
					<Chart
						id={`${chart.id}${chart.symbol}-${chart.boerse}-7`}
						yExtents={(d: any) => {
							return d[`atr-${chart.symbol}-${chart.boerse}-${chart.id}`];
						}}
						height={(cardIndex < 2 && 135) || 100}
						origin={(w: any, h: any) => externalChartIndicatorHeightConfig(h, 'atr', cardIndex, chartSettings, chart)}
						padding={{ top: 35, bottom: 0 }}
					>
						<ChartAxis
							tickFormat={germanNumberFormattter}
							chartType={chartType}
							data={data}
							span={span as string}
							axisAt={chart.axis.value}
							chartDesignConfig={chartDesignConfig}
							xTicks={renderTicks(cardIndex, chartType === 'bar', chartName).xTicks}
							yTicks={5}
							isCompareWithSelfEnabled={isCompareWithSelfEnabled}
						/>
						<MouseCoordinateY
							at='right'
							orient='right'
							displayFormat={scaleType === ScaleType.PERCENTAGE_CHANGE ? format('.3f') : format('.2f')}
							fontSize={chartDesignConfig.fontSize}
							{...mouseEdgeAppearance}
						/>
						<LineSeries
							yAccessor={(d: any) => {
								return d[`atr-${chart.symbol}-${chart.boerse}-${chart.id}`];
							}}
							stroke={STROKESCOLORS['atr']}
						/>
						<SingleValueTooltip
							yAccessor={(d: any) => d[`atr-${chart.symbol}-${chart.boerse}-${chart.id}`]}
							yLabel={`ATR (${
								atrFunction(
									`cv${chart.symbol}-${chart.boerse}${chart.id}`,
									`atr-${chart.symbol}-${chart.boerse}-${chart.id}`,
									indi.calculatedValue,
									scaleType
								).options().windowSize
							})`}
							yDisplayFormat={scaleType === ScaleType.PERCENTAGE_CHANGE ? format('.3f') : format('.2f')}
							valueStroke={STROKESCOLORS['atr']}
							labelStroke={STROKESCOLORS['atr']}
							origin={[0, 30]}
							fontSize={chartDesignConfig.fontSize}
						/>
						<SingleValueTooltip
							key={`${chart.symbol}-${chart.boerse}`}
							yAccessor={(d: any) => d[`cv${chart.symbol}-${chart.boerse}$-${index}`]}
							yLabel={chart.name}
							valueFill='transparent'
							origin={[100, 30]}
							fontSize={chartDesignConfig.fontSize}
						/>
					</Chart>
				);
			case 'sto':
				return (
					<Chart
						id={`${chart.id}-${chart.symbol}-${chart.boerse}-8`}
						yExtents={(d: any) => d[`sto-${chart.symbol}-${chart.boerse}-${chart.id}`]}
						height={(cardIndex < 2 && 135) || 100}
						origin={(w: any, h: any) => externalChartIndicatorHeightConfig(h, 'sto', cardIndex, chartSettings, chart)}
						padding={{ top: 35, bottom: 0 }}
					>
						<ChartAxis
							tickFormat={germanNumberFormattter}
							chartType={chartType}
							data={data}
							span={span as string}
							axisAt={chart.axis.value}
							chartDesignConfig={chartDesignConfig}
							xTicks={renderTicks(cardIndex, chartType === 'bar', chartName).xTicks}
							yTicks={5}
							isCompareWithSelfEnabled={isCompareWithSelfEnabled}
						/>
						<MouseCoordinateY
							at='right'
							orient='right'
							displayFormat={format('.2f')}
							fontSize={chartDesignConfig.fontSize}
							{...mouseEdgeAppearance}
						/>
						<StochasticSeries yAccessor={(d: any) => d[`sto-${chart.symbol}-${chart.boerse}-${chart.id}`]} {...stoAppearance} />
						<StochasticTooltip
							origin={[0, 30]}
							yAccessor={(d: any) => d[`sto-${chart.symbol}-${chart.boerse}-${chart.id}`]}
							options={fullSTOFunction(
								`compareValueObj${chart.symbol}-${chart.boerse}${chart.id}`,
								`sto-${chart.symbol}-${chart.boerse}-${chart.id}`,
								{
									datum: indi.datum,
									k: indi.k,
									tage: indi.tage
								} as ISTOIndicatorType,
								scaleType
							).options()}
							appearance={stoAppearance}
							label='STO'
							fontSize={chartDesignConfig.fontSize}
						/>
						<SingleValueTooltip
							key={`${chart.symbol}-${chart.boerse}-${chart.id}`}
							yAccessor={(d: any) => d[`compareValueObj${chart.symbol}-${chart.boerse}${chart.id}`]}
							yLabel={chart.name}
							valueFill='transparent'
							origin={[200, 30]}
							fontSize={chartDesignConfig.fontSize}
						/>
					</Chart>
				);
		}
	};
	return getchart();
};

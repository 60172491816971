import React, { useState } from 'react';

import { IChartData, IChartDetails, IChartSettingsType, ICompareChartIndicators, ISearchMaterialData, ISpan } from '../interface/futureTable';
import AdvanceExportChartDataPopup from './advanceExportChartPopup';
import ExportChartPopup from './exportChartPopup';

interface IProps {
	chartDetails: IChartDetails;
	spanObj: ISpan;
	setAction: (action: string) => void;
	chartType: string;
	id: string;
	symbol: string;
	comparativeChartIndicator: ICompareChartIndicators[] | ISearchMaterialData[] | IChartSettingsType[];
	chartSettings: IChartSettingsType[];
	scaleType: string;
	chartData: IChartData[];
}

const ExportDataPopup: React.FC<IProps> = (props) => {
	const { spanObj, chartDetails, setAction, chartType, id, symbol, comparativeChartIndicator, chartSettings, scaleType, chartData } = props;
	props;
	const [isExcelDownloadOpen, setIsExcelDownloadOpen] = useState(false);
	return (
		<>
			{isExcelDownloadOpen ? (
				<AdvanceExportChartDataPopup
					chartSettings={chartSettings}
					spanObj={spanObj}
					scaleType={scaleType}
					chartDetails={chartDetails}
					chartType={chartType}
					id={id}
					setAction={setAction}
					symbol={symbol}
					setIsExcelDownloadOpen={setIsExcelDownloadOpen}
					comparativeChartIndicator={comparativeChartIndicator}
				/>
			) : (
				<ExportChartPopup
					chartDetails={chartDetails}
					chartType={chartType}
					id={id}
					setAction={setAction}
					spanObj={spanObj}
					isExcelDownloadOpen={isExcelDownloadOpen}
					setIsExcelDownloadOpen={setIsExcelDownloadOpen}
					chartData={chartData}
				/>
			)}
		</>
	);
};

export default ExportDataPopup;

import { IDropDownOption } from 'shared/interface';

interface Props {
	title: string;
	options: IDropDownOption[];
	listClassName?: string;
	listWrapperClassName?: string;
	selectedData?: string | number;
	openCalenderPopUp?: () => void;
	handleClick: (value: any) => void;
}
const selectedValueArray = [] as any;
const CustomIndicatorsDropdown = (props: Props) => {
	const { title, options, listClassName, listWrapperClassName, selectedData, handleClick, openCalenderPopUp } = props;
	return (
		<div className='tooltips position--relative'>
			<p className='no-margin font--12px truncate--text width--120px' title={title}>
				{title || ''}
			</p>
			<div className={`tooltiptext position--absolute ${listWrapperClassName || ''}`}>
				<div className='indicator-option-wrapper'>
					<ul className='indicator-option-list'>
						{options.map((option, index: number) => (
							<li
								key={index}
								className={`option  ${listClassName ? listClassName : ''}  ${
									listClassName === 'indicators-options-list'
										? selectedValueArray.map((ele: string) => {
												return ele === option.value ? 'selected font-weight--500' : '';
										  })
										: selectedData?.toString() === option.value.toString()
										? 'selected font-weight--500'
										: ''
								}`}
								onClick={() => {
									if (option.value !== 'custom') {
										handleClick(option.value);
									} else {
										openCalenderPopUp && openCalenderPopUp();
									}
								}}
							>
								<span>{option.label}</span>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default CustomIndicatorsDropdown;

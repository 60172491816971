import React, { useState } from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import Button from 'shared/components/form/button';
import CustomModal from 'shared/components/modal/modal';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';

interface IProps {
	handleClose: () => void;
	handleSubmit: (color: string) => void;
}

const ChangeChartColorPopup: React.FC<IProps> = (props) => {
	const { handleClose, handleSubmit } = props;
	const [selectedColor, setSelectedColor] = useState('');

	return (
		<CustomModal handleClose={handleClose} show>
			<ChromePicker
				className='react-color-picker'
				color={selectedColor}
				onChangeComplete={(color: ColorResult) => setSelectedColor(color.hex)}
			/>
			<div className='full-width mt-8'>
				<Button type='button' btnType='primary' className='mr-8 button-size--medium' onClick={() => handleSubmit(selectedColor)}>
					<Translate text={localizationConstants.submit} />
				</Button>
				<Button className='button-size--medium' onClick={handleClose}>
					<Translate text={localizationConstants.cancel} />
				</Button>
			</div>
		</CustomModal>
	);
};

export default ChangeChartColorPopup;

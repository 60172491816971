import React, { useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { API_CONFIG } from 'shared/constants/constants';
import { BackwardArrow, ForwarArrow } from 'shared/icons/icon';
import httpService from 'shared/services/http.service';

import { IPartnersLink } from 'features/dashboard/interface/dashboard';
import 'swiper/css';
import 'swiper/scss/pagination';

const PartnerLinkSlider: React.FC = () => {
	const [loading, setLoading] = useState(false);
	const [partnerLinks, setPartnerLinks] = useState([] as IPartnersLink[]);

	const getPartnerLinks = useCallback(() => {
		setLoading(true);
		httpService
			.get(API_CONFIG.path.getPartnerLink)
			.then((response) => {
				setPartnerLinks(response);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		getPartnerLinks();
	}, [getPartnerLinks]);

	return (
		<div className='partner-links-swiper__wrapper full-width'>
			<h4 className='no-margin color-white font--16px line-height--22px font-weight--500 mb-10'>Partner</h4>
			{!loading && !isEmpty(partnerLinks) && (
				<>
					<Swiper
						pagination={{
							el: '.swiper-arrow__wrapper .swiper-custom-pagination',
							enabled: true,
							type: 'bullets',
							dynamicBullets: true,
							dynamicMainBullets: 3
						}}
						loop={true}
						navigation={{
							nextEl: `.slick-next`,
							disabledClass: 'slick-disabled',
							prevEl: '.slick-prev'
						}}
						speed={600}
						modules={[Pagination, Navigation, Autoplay]}
						className='partner-swiper'
						slidesPerView={1}
						spaceBetween={11}
						key='partner_link'
						autoplay={{ delay: 15000, disableOnInteraction: false, waitForTransition: true }}
					>
						{partnerLinks.map((data) => {
							return (
								<SwiperSlide key={data.link_id}>
									<a href={data.ziel_url} target='_blank' rel='noreferrer'>
										<div className='sidenav-main'>
											<div className='shortcut-main'>
												<div className='image-container'>
													<img className='shortcut-image' src={data.bild_url} alt='shortcut' />
												</div>
											</div>
										</div>
									</a>
								</SwiperSlide>
							);
						})}
					</Swiper>
					<div className='full-width d-flex justify-content-center align-items-center'>
						<div className={`swiper-arrow__wrapper d-flex justify-content-space-between align-items-center`}>
							<BackwardArrow className={`slick-prev cursor-pointer fill--white`} />
							<div className='swiper-custom-pagination' />
							<ForwarArrow className={`slick-next cursor-pointer fill--white`} />
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default PartnerLinkSlider;

import { Action, SideNavConfig } from 'shared/interface';
import * as actionTypes from 'store/action-types';

const initialState = {
	show_partner_section: false
};

const sideNavConfig = (state: SideNavConfig = initialState, action: Action): SideNavConfig => {
	switch (action.type) {
		case actionTypes.UPDATE_SHOW_PARTNER_SECTION:
			return { ...state, ...action.payload };
		default:
			return state;
	}
};

export default sideNavConfig;

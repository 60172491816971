import CostModalCard from 'features/costModal/component/costModalCard';
import { ICostModal } from 'features/costModal/interface/costModal';
import { get, isEmpty } from 'lodash';
import {
	IChartComponent,
	IDashboardData,
	IDashboardType,
	IFutureTableComponentType,
	IFutureTableParams,
	IListComponent,
	IListComponentParams,
	INewsComponent,
	INewsParams
} from 'features/dashboard/interface/dashboard';
import FutureTableComponent from './futureTableComponent';
import ListComponent from './listComponent';
import NewsComponent from './newsComponent';
import ChartComponent from './chartComponent';
import TenderComponent from './tenderComponent';

interface Props {
	value: IDashboardData;
	cardIndex: number;
	section: string;
	newsLength: number;
	news_widget_animation: boolean;
	deleteData: (id: string, isDelete: boolean) => void;
	onSaveNews: (newsId: number, action: boolean, index: number, section: string) => void;
	onSaveTender: (tenderId: number, action: boolean, index: number, section: string, rubrics: string) => void;
	toggleCardView: (elementId: string, view: string) => void;
	costModalUpdateView: (id: number) => void;
}

const FavouriteSortableItem = (props: Props) => {
	return (
		<div
			className={`favourite-item__wrapper--${props.cardIndex} grid--${props.cardIndex} favourite-item__wrapper ${
				(props.value.type !== 'chart' && 'overflow-hidden') || ''
			}`}
		>
			{props.value.type === 'list' && (
				<ListComponent value={props.value as IDashboardType<IListComponent, IListComponentParams>} deleteData={props.deleteData} />
			)}
			{props.value.type == 'future-table' && (
				<FutureTableComponent
					deleteData={props.deleteData}
					futureTableData={props.value as IDashboardType<IFutureTableComponentType[], IFutureTableParams>}
				/>
			)}
			{props.value.type === 'news' && (
				<NewsComponent
					deleteData={props.deleteData}
					newsData={props.value as IDashboardType<INewsComponent[], INewsParams>}
					cardIndex={props.cardIndex}
					section={props.section}
					newsLength={props.newsLength}
					onSaveNews={props.onSaveNews}
					toggleCardView={props.toggleCardView}
					news_widget_animation={props.news_widget_animation}
				/>
			)}
			{props.value.type === 'chart' && (
				<ChartComponent cardIndex={props.cardIndex} deleteData={props.deleteData} chartInfo={props.value as IChartComponent} />
			)}
			{props.value.type === 'cost_model' && !isEmpty(get(props.value as IDashboardType<ICostModal, any>, 'component_data', {})) && (
				<CostModalCard
					costModal={(props.value as IDashboardType<ICostModal, any>).component_data}
					costModalUpdateView={props.costModalUpdateView}
					handleDeleteCostmodal={() => false}
					cardIndex={props.cardIndex}
					cardType=''
					tableType='listTable'
				/>
			)}
			{props.value.type === 'tenders' && (
				<TenderComponent
					cardIndex={props.cardIndex}
					tenderData={props.value as any}
					section={props.section}
					newsLength={props.newsLength}
					deleteData={props.deleteData}
					onSaveTender={props.onSaveTender}
				/>
			)}
		</div>
	);
};

export default FavouriteSortableItem;

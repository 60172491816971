import isEmpty from 'lodash/isEmpty';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Chart, ChartCanvas } from 'react-stockcharts';
import { XAxis, YAxis } from 'react-stockcharts/lib/axes';
import { CrossHairCursor, CurrentCoordinate, MouseCoordinateX, MouseCoordinateY } from 'react-stockcharts/lib/coordinates';
import { bollingerBand, ema, sma, tma, wma } from 'react-stockcharts/lib/indicator';
import { discontinuousTimeScaleProvider } from 'react-stockcharts/lib/scale';
import { BollingerSeries, LineSeries } from 'react-stockcharts/lib/series';
import { HoverTooltip, MovingAverageTooltip } from 'react-stockcharts/lib/tooltip';
import { last } from 'react-stockcharts/lib/utils';
import EmptyDataContainer from 'shared/components/emptyDataContainer/emptyDataContainer';
import { Loader } from 'shared/components/spinner/spinner';
import { customNumberFormatter, formatDate } from 'shared/util/utility';
import { ICostModalChart } from '../interface/costModal';

interface IProps {
	id: number;
	indicators?: string[];
	timeSpan?: number;
	type?: string;
	selectedCurrancy?: string;
	customSpan?: { von: string; bis: string };
	chartData: ICostModalChart[];
	loading: boolean;
	showHoverTooltip: boolean;
	cardIndex: number;
	chartContainerRef: React.RefObject<HTMLDivElement>;
}

const CostModalChartContainer: React.FC<IProps> = (props) => {
	const [containerWidth, setContainerWidth] = useState(0);
	const [containerHeight, setContainerHeight] = useState(0);
	const { indicators, timeSpan, selectedCurrancy, chartData, loading, showHoverTooltip, cardIndex, chartContainerRef, customSpan } = props;

	const handleResize = useCallback(() => {
		if (chartContainerRef.current) {
			setContainerWidth(chartContainerRef.current.offsetWidth);
			setContainerHeight(chartContainerRef.current.offsetHeight);
		}
	}, [chartContainerRef]);

	useEffect(() => {
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [cardIndex, handleResize]);

	const ema12 = ema()
		.id(2)
		.options({ windowSize: 20 })
		.merge((d: any, c: any) => {
			d.ema12 = c;
		})
		.accessor((d: any) => d.ema12);

	const sma26 = sma()
		.options({ windowSize: 20 })
		.merge((d: any, c: any) => {
			d.sma26 = c;
		})
		.accessor((d: any) => d.sma26);

	const tma20 = tma()
		.options({ windowSize: 20 })
		.merge((d: any, c: any) => {
			d.tma20 = c;
		})
		.accessor((d: any) => d.tma20);

	const bb = bollingerBand()
		.options({ windowSize: 20 })
		.merge((d: any, c: any) => {
			d.bb = c;
		})
		.accessor((d: any) => d.bb);

	const wma20 = wma()
		.options({ windowSize: 20 })
		.merge((d: any, c: any) => {
			d.wma20 = c;
		})
		.accessor((d: any) => d.wma20);

	const width = containerWidth;
	const height = containerHeight;
	const margin = { left: 0, right: 59, top: 22, bottom: 30 };
	const gridWidth = width - margin.left - margin.right;
	const showGrid = true;
	const yGrid = showGrid
		? {
				innerTickSize: -1 * gridWidth,
				tickStrokeDasharray: 'Solid',
				tickStrokeOpacity: 0.1,
				tickStrokeWidth: 0.5,
				stroke: '#EEF4FB'
		  }
		: {};
	const chartMemo = useMemo(() => {
		const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor((d: any) => d.date);
		const calculatedData = ema12(sma26(wma20(tma20(bb(chartData)))));
		const { data, xScale, xAccessor, displayXAccessor } = xScaleProvider(calculatedData);
		let xExtents = [] as any;
		if (customSpan?.von && customSpan?.bis) {
			const startIndex = data.findIndex((ele: any) => formatDate(ele.date, 'DD/MM/YYYY') === formatDate(customSpan?.von, 'DD/MM/YYYY'));
			const endIndex = data.findIndex((ele: any) => formatDate(ele.date, 'DD/MM/YYYY') === formatDate(customSpan?.bis, 'DD/MM/YYYY'));
			xExtents = [startIndex === -1 ? xAccessor(data[0]) : startIndex, endIndex === -1 ? xAccessor(last(data)) : endIndex];
		} else {
			const start = xAccessor(last(data));
			const end = xAccessor(data[Math.max(0, data.length - (timeSpan ? timeSpan : 365))]);
			xExtents = [start, end];
		}
		return {
			data,
			xScale,
			xAccessor,
			displayXAccessor,
			xExtents
		};
	}, [chartData.length, chartData, indicators && indicators.length, timeSpan && timeSpan, customSpan?.von, customSpan?.bis]);

	const bbStroke = {
		top: '#964B00',
		middle: '#000000',
		bottom: '#964B00'
	};

	const bbFill = '#4682B4';

	const renderIndicatorsOnchart = (type: string, index: number) => {
		switch (type) {
			case 'ema':
				return (
					<Fragment key={index}>
						<LineSeries yAccessor={ema12.accessor()} />
						<CurrentCoordinate yAccessor={ema12.accessor()} />
					</Fragment>
				);
			case 'sma':
				return (
					<Fragment key={index}>
						<LineSeries yAccessor={sma26.accessor()} />
						<CurrentCoordinate yAccessor={sma26.accessor()} />
					</Fragment>
				);
			case 'tma':
				return (
					<Fragment key={index}>
						<LineSeries yAccessor={tma20.accessor()} />
						<CurrentCoordinate yAccessor={tma20.accessor()} />
					</Fragment>
				);
			case 'bb':
				return <BollingerSeries yAccessor={bb.accessor()} stroke={bbStroke} fill={bbFill} key={index} />;
			case 'wma':
				return (
					<Fragment key={index}>
						<LineSeries yAccessor={wma20.accessor()} />
						<CurrentCoordinate yAccessor={wma20.accessor()} />
					</Fragment>
				);

			default:
				break;
		}
	};
	const indicatorMapper: any = {
		ema: ema12,
		sma: sma26,
		tma: tma20,
		wma: wma20
	};
	const renderIndicatorToolTip = (typeArray: any) => {
		const array = [] as any;
		typeArray &&
			typeArray.map((value: string) => {
				if (value === 'ema' || value === 'sma' || value === 'tma' || value === 'wma') {
					array.push({
						yAccessor: indicatorMapper[value].accessor(),
						type: `${value.toUpperCase()} `,
						windowSize: indicatorMapper[value].options().windowSize,
						echo: 'some echo here',
						label: `${indicatorMapper[value].type()}(${indicatorMapper[value].options().windowSize})`,
						value: (d: any) => customNumberFormatter(indicatorMapper[value].accessor()(d))
					});
				}
			});
		return array;
	};
	function tooltipContent(ys: any) {
		return ({ currentItem, xAccessor }: any) => {
			return {
				x: formatDate(xAccessor(currentItem)).replaceAll('-', '.'),
				y: [
					{
						label: ' ',
						value: `${currentItem.low && customNumberFormatter(currentItem.low)} ${selectedCurrancy} / Unit`
					}
				]
					.concat(
						ys.map((each: any) => ({
							label: each.label,
							value: each.value(currentItem),
							stroke: each.stroke
						}))
					)
					.filter((line) => line.value)
			};
		};
	}
	return (
		<>
			{loading && <Loader />}
			{!loading && chartMemo.data.length === 0 && <EmptyDataContainer />}
			{!loading && chartMemo.data.length > 0 && chartMemo.xExtents.length > 0 && (
				<ChartCanvas
					height={height}
					width={width}
					ratio={1}
					margin={margin}
					seriesName='MSFT'
					data={chartMemo.data}
					xScale={chartMemo.xScale}
					xAccessor={chartMemo.xAccessor}
					type={'svg'}
					clamp={true}
					xExtents={chartMemo.xExtents}
					displayXAccessor={chartMemo.displayXAccessor}
				>
					<Chart
						id={1}
						yExtents={[
							(d: any) => {
								return [d.high, d.low];
							},
							ema12.accessor(),
							sma26.accessor(),
							tma20.accessor(),
							bb.accessor(),
							wma20.accessor()
						]}
					>
						<CrossHairCursor strokeDasharray='Solid' />
						<defs>
							<linearGradient id='MyGradient' x1='0' y1='100%' x2='0' y2='0%'>
								<stop offset='0%' stopColor='#b5d0ff' stopOpacity={0.2} />
								<stop offset='70%' stopColor='#6fa4fc' stopOpacity={0.4} />
								<stop offset='100%' stopColor='#4286f4' stopOpacity={0.8} />
							</linearGradient>
						</defs>
						<XAxis axisAt='bottom' orient='bottom' ticks={renderTicks(cardIndex).xTicks} />
						<YAxis axisAt='right' orient='right' ticks={renderTicks(cardIndex).yTicks} {...yGrid} />
						<CurrentCoordinate yAccessor={(d: any) => d.low} fill={'#081d34'} />
						<MouseCoordinateX at='bottom' orient='bottom' displayFormat={() => null} />
						<MouseCoordinateY at='right' orient='right' displayFormat={() => null} />
						{indicators &&
							!isEmpty(indicators) &&
							indicators.map((indicator: string, index: number) => renderIndicatorsOnchart(indicator, index))}
						{indicators && !isEmpty(indicators) && <MovingAverageTooltip options={renderIndicatorToolTip(indicators as any)} />}
						{/* <RenderChart type={(type && type) || 'area'} /> */}
						{showHoverTooltip && (
							<HoverTooltip
								yAccessor={(d: any) => d.accessor()}
								tooltipContent={tooltipContent([])}
								fontSize={15}
								fill='white'
								fontFill='#000000'
								opacity={1}
								fontFamily='Outfit'
								bgFill='transparent'
							/>
						)}
					</Chart>
				</ChartCanvas>
			)}
		</>
	);
};

const renderTicks = (index: number) => {
	switch (index) {
		case 2:
		case 3:
			return {
				xTicks: 3,
				yTicks: 5
			};
		case 4:
		case 5:
		case 6: {
			return {
				xTicks: 2,
				yTicks: 2
			};
		}
		default:
			return {
				xTicks: 4,
				yTicks: 5
			};
	}
};

export default CostModalChartContainer;

import React, { useEffect, useState } from 'react';

import { customNumberFormatter } from 'shared/util/utility';

import { IChartData, IChartDetails, IChartItem, IChartSettingsType } from 'features/futuretable/interface/futureTable';

interface IProps {
	elementId: string;
	cardIndex: number;
	chartLastObj: IChartData;
	updatedChartSettings: IChartSettingsType[];
	chartDetails?: { [key: string]: IChartDetails };
}

const ChartHoverTooltip: React.FC<IProps> = (props) => {
	const { elementId, cardIndex, chartLastObj, updatedChartSettings, chartDetails } = props;
	const [containerHeight, setContainerHeight] = useState(0);

	useEffect(() => {
		handleResize(true);
		window.addEventListener('resize', () => handleResize(false));
		return () => window.removeEventListener('resize', () => handleResize(false));
	}, []);

	const handleResize = (isSetHeight: boolean) => {
		const id = document.getElementById(`chart-tooltip-${elementId}`);
		if (isSetHeight && id) {
			setContainerHeight(id.offsetHeight);
		}
	};

	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		setIsMounted(true);
		return () => {
			setIsMounted(false);
		};
	}, []);

	return (
		<div
			className={`dashboard-chart-hover-tooltip d-flex flex-column justify-content-space-around ${cardIndex === 0 ? 'left-align' : ''}`}
			id={`chart-tooltip-${elementId}`}
			style={isMounted ? { top: `-${containerHeight - 10}px`, animation: 'inAnimation 200ms ease-in' } : { visibility: 'hidden' }}
			key={elementId}
		>
			{updatedChartSettings.length > 0 &&
				updatedChartSettings.map((chart, index) => {
					const isShowUnitCurrency =
						chart.currency.value !== null && chart.currency.value !== '' && chart.unit.value !== null && chart.unit.value !== '';
					const chartUnitCurrency = isShowUnitCurrency ? `${chart.currency.value}/ ${chart.unit.value}` : '';
					const chartHeaderPrice = (chartLastObj[`cv${chart.symbol}-${chart.boerse}-${index}`] as IChartItem)?.l
						? `${customNumberFormatter(
								(chartLastObj[`cv${chart.symbol}-${chart.boerse}-${index}`] as IChartItem)?.l,
								(chartDetails && chartDetails[`${chart.symbol}-${chart.boerse}-${index}`].nkstellen) || 2
						  )} ${chartUnitCurrency}`
						: '';

					return (
						<div key={chart.id}>
							<div className='chart-hover-tooltip-name__wrapper d-flex justify-content-start align-items-start '>
								<div className='chart-box--wrapper' style={{ backgroundColor: chart.color }} />
								<h4 className='chart-name no-margin font--13px font-weight--400 line-height--18px'>
									{chart.name}: <span className='font-weight--700 font--13px'>{chartHeaderPrice}</span>
								</h4>
							</div>
							{(chart.indicators.length > 0 && (
								<div className='indicator__wrapper d-flex align-items-center'>
									{chart.indicators.map((indi) => {
										const indicatorName =
											indi.value === 'bb' ||
											indi.value === 'macd' ||
											indi.value === 'sto' ||
											indi.value === 'atr' ||
											indi.value === 'rsi'
												? indi.value === 'bb'
													? `${indi.value.toUpperCase()} (close, ${indi.calculatedValue}, sma)`
													: ''
												: `${indi.value.toUpperCase()} (${indi.calculatedValue})`;

										const indicatorPrice = chartLastObj[`${indi.value}-${chart.symbol}-${chart.boerse}-${chart.id}`] as any;

										return (
											<div className={`indicator-name__wrapper d-flex justify-content-space-between align-items-center`}>
												{indicatorName !== '' && (
													<div className='chart-box--wrapper' style={{ backgroundColor: chart.color }} />
												)}
												<h6 className='indicator-name font--11px font-weight--400 no-margin d-flex align-items-center'>
													<span className='mr-2'>{indicatorName}</span>
													<span className='font-weight--700 font--11px'>
														{indi.value === 'bb' ||
														indi.value === 'macd' ||
														indi.value === 'sto' ||
														indi.value === 'atr' ||
														indi.value === 'rsi'
															? indi.value === 'bb'
																? `${
																		indicatorPrice?.top
																			? customNumberFormatter(indicatorPrice?.top as number)
																			: ''
																  } ${
																		indicatorPrice?.middle
																			? customNumberFormatter(indicatorPrice?.middle as number)
																			: ''
																  } ${
																		indicatorPrice?.bottom
																			? customNumberFormatter(indicatorPrice?.bottom as number)
																			: ''
																  }`
																: ''
															: indicatorPrice
															? customNumberFormatter(indicatorPrice)
															: ''}
													</span>
												</h6>
											</div>
										);
									})}
								</div>
							)) || <></>}
						</div>
					);
				})}
		</div>
	);
};

export default ChartHoverTooltip;

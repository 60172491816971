import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { PbBody, PbCell, PbHead, PbRow, PbTable } from 'shared/components/table';
import { LockIcon } from 'shared/icons/icon';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';

import { IDashboardType, IFutureTableComponentType, IFutureTableParams } from 'features/dashboard/interface/dashboard';
import DashboardCardHeader from './dashboardCardHeader';
import { replaceHashtagFromUrl } from 'shared/util/utility';
import { State } from 'shared/interface';

interface Props {
	deleteData: (data: string, action: boolean) => void;
	futureTableData: IDashboardType<IFutureTableComponentType[], IFutureTableParams>;
}
const FutureTableComponent = (props: Props) => {
	const { element_id, name, params, component_data, name_eng } = props.futureTableData;
	const symbol = params.symbol ? `&symbol=${replaceHashtagFromUrl(params.symbol)}` : ``;
	const basis_symbol = params.basis_symbol ? `&basis_symbol=${params.basis_symbol}` : ``;
	const navigationUrl = `/materials/futureTable?element_id=${element_id}&boerse=${replaceHashtagFromUrl(
		params.boerse
	)}&materialParentId=${0}&currency=${params.waehrung}${symbol}${basis_symbol}`;
	const { language } = useSelector((state: State) => state.siteConfig);
	return (
		<>
			<DashboardCardHeader
				action={true}
				deleteData={props.deleteData}
				elementId={element_id}
				name={(language === 'en' && name_eng !== '' && name_eng) || name}
				navigateUrl={navigationUrl.trim()}
				section='future-table'
			/>
			<div className={`card-items overflow-x-hidden`}>
				<PbTable>
					<PbHead>
						<PbRow>
							<PbCell className={`table-heading font--10px font-weight--400 line-height--14px `} header>
								<Translate text={localizationConstants.dueDate} />
							</PbCell>
							<PbCell className={`table-heading text-align-center font--10px font-weight--400 line-height--14px`} header>
								<Translate text={localizationConstants.courses} />
							</PbCell>
							<PbCell className={`table-heading text-align-center font--10px font-weight--400 line-height--14px truncate--text`} header>
								<Translate text={localizationConstants.change} />
							</PbCell>
						</PbRow>
					</PbHead>
					<PbBody>
						{!isEmpty(component_data) &&
							component_data.map((data: IFutureTableComponentType, index: number) => (
								<PbRow key={index}>
									<PbCell className='font--14px font-weight--600'>{data.faelligkeit_f}</PbCell>
									<PbCell className='text-align-center font--14px font-weight--500'>
										{data.disallowed ? <LockIcon /> : data.hauptkurs ? data.hauptkurs.toFixed(2) : '-'}
									</PbCell>
									<PbCell
										className={`text-align-center font--12px font-weight--500 ${data.diff_prozent < 0 && 'text-red'} ${
											data.diff_prozent > 0 && 'text-green'
										}`}
									>
										{!data.disallowed && data.diff_prozent > 0 && '+'}
										{!data.disallowed ? data.diff_prozent ? parseFloat(data.diff_prozent.toFixed(2)) + '%' : '-' : <LockIcon />}
									</PbCell>
								</PbRow>
							))}
					</PbBody>
				</PbTable>
			</div>
		</>
	);
};

export default FutureTableComponent;

import * as actionTypes from 'store/action-types';
import { Action } from 'shared/interface';
import { IChartReducerState } from '../interface/futureTable';

const initialState: IChartReducerState = {
	isRedirectFromGlobalSearch: false,
	chartLastObj: {}
};

const chartConfigReducer = (state: IChartReducerState = initialState, action: Action): IChartReducerState => {
	switch (action.type) {
		case actionTypes.UPDATE_CHART_CONFIG:
			return {
				...state,
				isRedirectFromGlobalSearch: action.payload
			};
		case actionTypes.GET_CHART_LAST_OBJ:
			return {
				...state,
				chartLastObj: { ...state.chartLastObj, [action.payload.key]: action.payload.data }
			};
		case actionTypes.RESET_CHART_LAST_OBJ:
			return {
				...state,
				chartLastObj: action.payload
			};
		default:
			return state;
	}
};
export default chartConfigReducer;

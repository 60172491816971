import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import * as actionTypes from 'store/action-types';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';

import { createAction } from 'shared/util/utility';
import HttpService from 'shared/services/http.service';
import authService from 'shared/services/auth.service';
import { API_CONFIG } from 'shared/constants/constants';
import { Translate } from 'shared/util/translation/translate';
import localizationConstants from 'shared/util/translation/constants';

import { LoginResponse } from 'features/login/interface/login';
interface IProps {
	adminId: number | null;
}

const ReturnTakeOverPopup: React.FC<IProps> = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleReturnToAccount = useCallback(async () => {
		try {
			const res = await HttpService.post(`${API_CONFIG.path.returnTakeOver}/${props.adminId as number}`);
			let userData = authService.getAuthData() as LoginResponse;
			userData = {
				...res
			};
			localStorage.setItem('isAnnouncement', JSON.stringify(1));
			authService.setMatfoxxSettings(res.matfoxx_settings);
			authService.setDateFormate(res.date_format);
			localStorage.setItem('recordPerPage', JSON.stringify(res.page_limit));
			localStorage.setItem('lang', res.sprache);
			localStorage.setItem('isTakeOver', '0');
			dispatch(createAction(actionTypes.AUTH_SUCCESS, res));
			dispatch(
				createAction(actionTypes.UPDATE_SITE_CONFIG, {
					language: res.sprache,
					dateFormat: res.date_format,
					matfoxxConfig: res.matfoxx_settings,
					recordPerPage: res.page_limit,
					admin: res.admin,
					isTakeOver: false
				})
			);
			dispatch(createAction(actionTypes.RESET_CHART_LAST_OBJ, {}));
			i18next.changeLanguage(res.sprache);
			authService.setAuthData(userData);
			navigate(`/admin?tab=users&userId=${userData.benutzer_id}&type=user`);
		} catch (error) {
			console.error(error);
		}
	}, [dispatch, navigate, props.adminId]);

	return (
		<div className='bottom-popup'>
			<h4 className='bottom-popup__header no-margin font--16px font-weight--400'>
				<Translate text={localizationConstants.takeOverFirstMessage} />{' '}
				<span className='cursor-pointer text--underline' onClick={handleReturnToAccount}>
					<Translate text={localizationConstants.here} />
				</span>
				<Translate text={localizationConstants.takeOverSecondMessage} />{' '}
			</h4>
		</div>
	);
};

export default ReturnTakeOverPopup;

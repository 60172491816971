import i18n from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';

import { LogOutIcon, ProfileIcon } from 'shared/icons/icon';
import { Link } from 'react-router-dom';
interface Props {
	toggleModalStatus: () => void;
}
const HeaderButtons = (props: Props) => {
	const { t } = i18n;
	return (
		<div className='d-flex justify-content-space-between align-items-center'>
			{/* <div
				className='header-icons d-flex justify-content-center align-items-center cursor-pointer'
				title={`${t(localizationConstants.notification)}`}
			>
				<NotificationIcon />
			</div> */}
			<Link to='/Profile'>
				<div
					className='header-icons d-flex justify-content-center align-items-center ml-10 cursor-pointer'
					title={`${t(localizationConstants.profile)}`}
				>
					<ProfileIcon />
				</div>
			</Link>
			<div
				className='header-icons d-flex justify-content-center align-items-center ml-10 cursor-pointer'
				onClick={props.toggleModalStatus}
				title={`${localizationConstants.logout}`}
			>
				<LogOutIcon />
			</div>
		</div>
	);
};

export default HeaderButtons;

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Button from 'shared/components/form/button';
import { BackwardArrow, CradViewChange, ForwarArrow } from 'shared/icons/icon';
import { CardView } from 'shared/interface/enums';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';

import DashboardCardHeader from './dashboardCardHeader';
import NewsContent from './newsContent';

import { IDashboardType, INewsComponent, INewsParams } from 'features/dashboard/interface/dashboard';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWhitelabelConfigStatus } from 'shared/hooks/useWhitelabelConfigStatus';
import { State } from 'shared/interface';
import 'swiper/scss/pagination';
import 'swiper/css';
interface Props {
	cardIndex: number;
	newsData: IDashboardType<INewsComponent[], INewsParams>;
	section: string;
	newsLength: number;
	news_widget_animation: boolean;
	deleteData: (data: string, action: boolean) => void;
	onSaveNews: (newsId: number, action: boolean, index: number, section: string) => void;
	toggleCardView: (elementId: string, view: string) => void;
}
const NewsComponent: React.FC<Props> = (props) => {
	const { newsData, cardIndex, newsLength, section, news_widget_animation, deleteData, onSaveNews, toggleCardView } = props;
	const location = useLocation();
	const isIframe = location.pathname.includes('iframe');
	const { language } = useSelector((state: State) => state.siteConfig);
	const navigate = useNavigate();
	const { whitelabelId, news_status } = useWhitelabelConfigStatus();

	const listViewCondition = get(newsData, 'view', CardView.LIST_VIEW) === CardView.LIST_VIEW;
	const gridViewCondition = newsData.view === CardView.GRID_VIEW;

	const buildHeaderPath = () => {
		const parentId = newsData.parent_id === '' || !newsData.parent_id ? 0 : newsData.parent_id;
		const baseUrl = `/news/articleList/${parentId}?categoryId=${newsData.params.kid}`;
		const queryParam = newsData.params.q ? `&word=${encodeURIComponent(newsData.params.q)}` : '';
		const whitelabelIdParam = newsData.params.whitelable_id ? `&whitelabelId=${newsData.params.whitelable_id}` : '';

		const url = baseUrl + queryParam + whitelabelIdParam;
		const isInactiveMaterialStatus = news_status === 'inactive' && !newsData.params.whitelable_id;
		const parsedWhitelabelId = parseInt(newsData.params.whitelable_id as string);
		const isParsedWhitelabelIdEqual = parsedWhitelabelId === whitelabelId;
		const isActiveMaterialStatus = news_status === 'active' && isParsedWhitelabelIdEqual;
		const isActiveOriginalStatus = news_status === 'active+original' && (!newsData.params.whitelable_id || isParsedWhitelabelIdEqual);
		const isUnassignedWhitelabel = !whitelabelId && !newsData.params.whitelable_id;

		if (isInactiveMaterialStatus || isActiveMaterialStatus || isActiveOriginalStatus || isUnassignedWhitelabel) {
			navigate(url);
		}
	};

	return (
		<>
			<DashboardCardHeader
				name={(language === 'en' && newsData.name_eng !== '' && newsData.name_eng) || newsData.name}
				action={true}
				deleteData={deleteData}
				elementId={newsData.element_id}
				section='news'
				navigateToPage={buildHeaderPath}
			/>
			<div
				className={`card-items d-flex flex-column justify-content-space-between ${
					(newsData.view === CardView.LIST_VIEW && 'flex-column') || ''
				}`}
			>
				{listViewCondition && !isEmpty(newsData.component_data) && (
					<div className='card-content'>
						{!isEmpty(newsData.component_data) &&
							newsData.component_data.map((data: INewsComponent) => (
								<NewsContent
									key={data.id}
									newsData={newsData}
									view={(newsData.view && newsData.view) || CardView.LIST_VIEW}
									cardIndex={cardIndex}
									className='medium-news-card__wrapper d-flex jusify-content-start align-items-center'
									data={data}
									onSaveNews={onSaveNews}
									section={section}
								/>
							))}
					</div>
				)}
				{isEmpty(newsData.component_data) && (
					<p className='no-result-title pt--20 text-align-center font--20px'>
						<Translate text={localizationConstants.noNewsArticleFound} />
					</p>
				)}
				{gridViewCondition && !isEmpty(newsData.component_data) && (
					<Swiper
						pagination={{
							el: `.swiper-arrow__wrapper .swiper-custom-pagination-${cardIndex}-${section}`,
							enabled: cardIndex < 2 && gridViewCondition,
							type: 'bullets',
							dynamicBullets: true,
							dynamicMainBullets: 3
						}}
						loop={true}
						navigation={{
							nextEl: `.slick-next-${cardIndex}-${section}`,
							prevEl: `.slick-prev-${cardIndex}-${section}`,
							enabled: newsLength > 1,
							disabledClass: 'slick-disabled'
						}}
						speed={600}
						autoplay={
							news_widget_animation && (cardIndex === 0 || cardIndex === 1)
								? { delay: 15000, disableOnInteraction: false, waitForTransition: true }
								: false
						}
						modules={[Pagination, Navigation, Autoplay]}
						className='mySwiper'
						key={cardIndex}
						enabled={gridViewCondition}
					>
						{!isEmpty(newsData.component_data) &&
							newsData.component_data.map((data: INewsComponent) => (
								<SwiperSlide key={data.id}>
									<NewsContent
										newsData={newsData}
										view={(newsData.view && newsData.view) || CardView.GRID_VIEW}
										cardIndex={cardIndex}
										className={`${(cardIndex >= 2 && !isIframe && 'medium-news-card__wrapper') || 'news-card__wrapper'} pt-16`}
										data={data}
										onSaveNews={onSaveNews}
										section={section}
									/>
								</SwiperSlide>
							))}
					</Swiper>
				)}
				{
					<div className='card-bottom d-flex justify-content-space-between align-items-center'>
						<h1
							className={`${
								(cardIndex < 2 && !isIframe && 'width-33') || 'width--fit-content'
							}  no-margin font--13px line-height-20px font-weight--400`}
						>
							<Translate text={localizationConstants.latestNews} /> ({newsData.component_data.length})
						</h1>
						{newsLength > 1 && gridViewCondition && (
							<div
								className={`swiper-arrow__wrapper d-flex justify-content-space-between align-items-center ${
									(cardIndex > 1 && 'width--52px') || ''
								}`}
							>
								<BackwardArrow className={`slick-prev slick-prev-${cardIndex}-${section} cursor-pointer`} />
								<div className={`swiper-custom-pagination swiper-custom-pagination-${cardIndex}-${section}`} />
								<ForwarArrow className={`slick-next slick-next-${cardIndex}-${section} cursor-pointer`} />
							</div>
						)}
						{cardIndex < 2 && !isIframe && (
							<div className='width-33 full-height card-change-icon__wrapper d-flex justify-content-end align-items-center'>
								<Button
									className='card-change__button full-height'
									onClick={() => toggleCardView(newsData.element_id, (newsData.view && newsData.view) || CardView.GRID_VIEW)}
								>
									<CradViewChange className='card-change-icon' />
								</Button>
							</div>
						)}
					</div>
				}
			</div>
		</>
	);
};

export default NewsComponent;

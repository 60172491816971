import size from 'lodash/size';
import React, { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { discontinuousTimeScaleProvider } from 'react-stockcharts/lib/scale';
import { last } from 'react-stockcharts/lib/utils';
import { PbBody, PbCell, PbHead, PbRow, PbTable } from 'shared/components/table';
import { englishLocale, germanLocale } from 'shared/constants/constants';
import { AskIcon, BidIcon, HighIcon, LowIcon, VolumeIcon } from 'shared/icons/icon';
import { State } from 'shared/interface';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';
import { chartTimeRangeMapper, formatDate } from 'shared/util/utility';
import { IChartData, IChartDetails, ISpan } from '../interface/futureTable';
import i18n from 'shared/util/localization';

interface IProps {
	chartData: IChartData[];
	spanObj: ISpan;
	chartDetails: IChartDetails;
}

const ChartTableInfo: React.FC<IProps> = (props) => {
	const {
		chartData,
		spanObj: { span, endDate, startDate },
		chartDetails
	} = props;
	const { language } = useSelector((state: State) => state.siteConfig);
	const { t } = i18n;

	const spanDropDown = useMemo(
		() => [
			{ label: `1 ${t(localizationConstants.day)}`, value: '1d' },
			{ label: `1 ${t(localizationConstants.week)}`, value: '1w' },
			{ label: `1 ${t(localizationConstants.month)}`, value: '1m' },
			{ label: `3 ${t(localizationConstants.months)}`, value: '3m' },
			{ label: `6 ${t(localizationConstants.months)}`, value: '6m' },
			{ label: 'YTD', value: 'ytd' },
			{ label: `1 ${t(localizationConstants.year)}`, value: '1y' },
			{ label: `3 ${t(localizationConstants.year)}`, value: '3y' },
			{ label: `5 ${t(localizationConstants.year)}`, value: '5y' },
			{ label: `10 ${t(localizationConstants.year)}`, value: '10y' },
			{ label: 'Max', value: 'max' },
			{ label: `${t(localizationConstants.custom)}`, value: 'custom' }
		],
		[t]
	);

	const xScaleProvider = discontinuousTimeScaleProvider
		.inputDateAccessor((d: any) => {
			return new Date(d.date);
		})
		.setLocale(language == 'en' ? englishLocale : germanLocale);
	const { data, xAccessor } = xScaleProvider(chartData);

	const dataRange = useMemo(() => {
		if (startDate && endDate) {
			const startIndex = data.findIndex((ele: any) => formatDate(ele.date, 'DD/MM/YYYY') === formatDate(startDate, 'DD/MM/YYYY'));
			const endIndex = data.findIndex((ele: any) => formatDate(ele.date, 'DD/MM/YYYY') === formatDate(endDate, 'DD/MM/YYYY'));
			return [startIndex === -1 ? xAccessor(data[0]) : startIndex, endIndex === -1 ? xAccessor(last(data)) : endIndex];
		} else {
			const tempSpan = chartTimeRangeMapper(chartData)[span];
			const end = xAccessor(last(data));
			const start = xAccessor(data[Math.max(0, data.length - (tempSpan ? tempSpan : 365))]);
			return [start, end];
		}
	}, [data, startDate, endDate, span, xAccessor]);

	const calculateHighAndLowValues = useCallback(
		(value: string) => {
			const data = chartData.slice(dataRange[0] + 1, dataRange[1] + 1);
			const high = Math.max(...data.map((item: any) => item[`cv${chartDetails.symbol}-${chartDetails.boerse}-0`]?.l));
			const low = Math.min(...data.map((item: any) => item[`cv${chartDetails.symbol}-${chartDetails.boerse}-0`]?.l));
			if (value === 'high') {
				return high;
			} else {
				return low;
			}
		},
		[chartData, dataRange]
	);

	return (
		<div className='width-30 info-table-container'>
			<PbTable className='table-main'>
				<PbHead>
					<PbRow>
						<PbCell className='font--16px font-weight--700 color-white' colSpan={2} header>
							<Translate text={localizationConstants.numberTableHeader} />
						</PbCell>
					</PbRow>
				</PbHead>
				<PbBody>
					<PbRow>
						<PbCell>
							<div>
								<span className='mr-10'>
									<BidIcon />
								</span>
								Bid (
								<span className='font-style--italic'>
									{span !== '' ? spanDropDown.find((value) => value.value === span)?.label : t(localizationConstants.shortCustom)}
								</span>
								)
							</div>
						</PbCell>
						<PbCell>{chartData[size(chartData) - 1].dividend || '-'}</PbCell>
					</PbRow>
					<PbRow>
						<PbCell>
							<div>
								<span className='mr-10'>
									<AskIcon />
								</span>
								Ask (
								<span className='font-style--italic'>
									{span !== '' ? spanDropDown.find((value) => value.value === span)?.label : t(localizationConstants.shortCustom)}
								</span>
								)
							</div>
						</PbCell>
						<PbCell>{chartData[size(chartData) - 1].absoluteChange || '-'}</PbCell>
					</PbRow>
					<PbRow>
						<PbCell>
							<div>
								<span className='mr-10'>
									<VolumeIcon />
								</span>
								Volume (
								<span className='font-style--italic'>
									{span !== '' ? spanDropDown.find((value) => value.value === span)?.label : t(localizationConstants.shortCustom)}
								</span>
								)
							</div>
						</PbCell>
						<PbCell>-</PbCell>
					</PbRow>
					<PbRow>
						<PbCell>
							<div>
								<span className='mr-10'>
									<HighIcon />
								</span>
								<Translate text={localizationConstants.high} /> (
								<span className='font-style--italic'>
									{span !== '' ? spanDropDown.find((value) => value.value === span)?.label : t(localizationConstants.shortCustom)}
								</span>
								)
							</div>
						</PbCell>
						<PbCell>{calculateHighAndLowValues('high').toFixed(2)}</PbCell>
					</PbRow>
					<PbRow>
						<PbCell>
							<div>
								<span className='mr-10'>
									<LowIcon />
								</span>
								<Translate text={localizationConstants.low} /> (
								<span className='font-style--italic'>
									{span !== '' ? spanDropDown.find((value) => value.value === span)?.label : t(localizationConstants.shortCustom)}
								</span>
								)
							</div>
						</PbCell>
						<PbCell>{calculateHighAndLowValues('low').toFixed(2)}</PbCell>
					</PbRow>
				</PbBody>
			</PbTable>
		</div>
	);
};

export default ChartTableInfo;

import React from 'react';
import { Chart } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart as ChartJS, ChartDataset, ChartOptions, Legend, LinearScale, Title, Tooltip, TooltipItem } from 'chart.js';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
import { orderBy, round } from 'lodash';

import { IProductValue } from './doughnutChart';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TreemapController, TreemapElement);

interface IProps {
	productValues: IProductValue[];
	chartColors: string[];
	currancy: string;
}

export type TreemapChartDataset = ChartDataset & {
	percentLabels?: string[];
	treeLabels?: string[];
	tree?: number[];
	fontColor?: string;
	fontFamily?: string;
	fontSize?: number;
	fontStyle?: string;
	treeLongLabels?: string[];
	backgroundColor?: (ctx: any) => string;
};

const PriceDriveComponent: React.FC<IProps> = (props) => {
	const { productValues, chartColors, currancy } = props;
	const acendingOrderProductValue = orderBy(productValues, 'value', 'desc');
	const treemapDataset: TreemapChartDataset = {
		label: 'ChartData', // well, use for nothing, it's chartjs's label
		tree: productValues.map((product) => product.value),
		backgroundColor: function (ctx: any) {
			const colorValue = chartColors[ctx.dataIndex];
			return colorValue;
		},
		data: productValues.map((product) => product.value),
		labels: {
			align: 'center',
			color: 'white',
			display: true,
			font: {
				size: 24,
				family: 'Outfit',
				weight: 500
			},
			position: 'middle'
		}
	};
	const data: any = {
		datasets: [treemapDataset] as TreemapChartDataset[]
	};

	const options: ChartOptions<'treemap'> = {
		aspectRatio: 0,
		maintainAspectRatio: false,
		responsive: true,
		plugins: {
			title: {
				display: false
			},
			legend: {
				display: false
			},
			tooltip: {
				enabled: true,
				axis: 'xy',
				position: 'average',
				usePointStyle: true,
				backgroundColor: '#FFFFFF',
				bodyAlign: 'center',
				displayColors: false,
				titleColor: '#081D34',
				bodyColor: '#081D34',
				titleAlign: 'center',
				padding: 4,
				footerColor: '#61656A',
				bodyFont: {
					weight: 500,
					size: 10
				},
				footerFont: {
					weight: 400,
					size: 9
				},
				titleFont: {
					weight: 600,
					size: 12
				},
				footerAlign: 'center',
				callbacks: {
					title: (tooltipItem: TooltipItem<'treemap'>[]) => {
						return `${acendingOrderProductValue[tooltipItem[0].dataIndex].value}%`;
					},
					label: (tooltipItem: TooltipItem<'treemap'>) => {
						return `${acendingOrderProductValue[tooltipItem.dataIndex].name}`;
					},
					labelColor: () => {
						return {
							borderColor: '',
							backgroundColor: '#081D34',
							fontWeight: '500' // add fontWeight property
						};
					},
					footer: (tooltipItem: TooltipItem<'treemap'>[]) => {
						return `${round(acendingOrderProductValue[tooltipItem[0].dataIndex].productPrice, 2)} ${currancy} / Unit`;
					}
				}
			}
		}
	};
	return (
		<div className='price-drive__wrapper'>
			<Chart type='treemap' data={data} options={options} />
		</div>
	);
};

export default PriceDriveComponent;

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from 'shared/components/form/button';
import { BackButtonIcon } from 'shared/icons/icon';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';

import '../style/chartScreenContainer.scss';
import ChartScreenContainer from './chartScreenContainer';

const FutureTableContainer: React.FC = () => {
	const location = useLocation();
	const navigate = useNavigate();

	return (
		<div className='future-table__container' id='scrollable-wrapper'>
			{!location.hash && (
				<section className='futuretable-section full-width'>
					<div className='futuretable-header d-flex justify-content-space-between align-items-center'>
						<Button
							btnType='secondary'
							onClick={() => navigate(-1)}
							className='button button-secondary padding-left-right--16px text-capitalize back--btn d-flex justify-content-space-between align-items-center  font--14px  b-radius--8'
						>
							<BackButtonIcon className='mr-6' />
							<Translate text={localizationConstants.back} />
						</Button>
					</div>
					<ChartScreenContainer />
				</section>
			)}
		</div>
	);
};

export default FutureTableContainer;

import { IUserWhiteLabelConfig } from 'features/login/interface/login';
import { useSelector } from 'react-redux';
import { State } from 'shared/interface';

export const useWhitelabelConfigStatus = () => {
	const { userData } = useSelector((state: State) => state.auth);
	const { whitelable_config } = userData;
	const { material_status, news_status, id: whitelabelId, forecast_status, pdfModule_status } = whitelable_config as IUserWhiteLabelConfig;
	return {
		material_status,
		news_status,
		whitelabelId,
		forecast_status,
		pdfModule_status
	};
};

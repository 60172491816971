import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormikValues } from 'formik';
import some from 'lodash/some';

import { CustomForm } from 'shared/components/form/form';
import { FieldConfig } from 'shared/components/form/inputTypes';
import CustomModal from 'shared/components/modal/modal';
import { DATE_PLACEHOLDER_MAPPER } from 'shared/constants/constants';
import { setPeriodValidationSchema } from 'shared/constants/validation-schema';
import { WhiteSettingIcon } from 'shared/icons/icon';
import { IDropDownOption, State } from 'shared/interface';
import i18n from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';
import { capitalizeFirstLetter } from 'shared/util/utility';

import { IChartDetails, ISearchMaterialData, ISpan } from '../interface/futureTable';
import CustomIndicatorsDropdown from './CustomIndicatorsDropdown';
import ComparisionSearchBar from './comparisionSearchBar';

interface IProps {
	chartType: string;
	spanObj: ISpan;
	chartDetails: IChartDetails;
	handleSpan: (value: any) => void;
	handleChartType: (value: string) => void;
	getComparativeChartData: (comparativeData: ISearchMaterialData) => void;
	setAction: (action: string) => void;
	disabledSearch: boolean;
	setIsChartSettingUpdate: () => void;
	setSpanObj: (span: ISpan) => void;
	allChartDetails: { [key: string]: IChartDetails };
}

const ChartConfigurationDropdowns: React.FC<IProps> = (props) => {
	const {
		chartType,
		spanObj: { span, endDate, startDate },
		handleChartType,
		handleSpan,
		getComparativeChartData,
		disabledSearch,
		chartDetails,
		setIsChartSettingUpdate,
		setSpanObj,
		allChartDetails
	} = props;
	const { t } = i18n;
	const { language, dateFormat } = useSelector((state: State) => state.siteConfig);
	const [action, setAction] = useState('');

	const spanDropDown = useMemo(
		() => [
			{ label: `1 ${t(localizationConstants.week)}`, value: '1w' },
			{ label: `1 ${t(localizationConstants.month)}`, value: '1m' },
			{ label: `3 ${t(localizationConstants.months)}`, value: '3m' },
			{ label: `6 ${t(localizationConstants.months)}`, value: '6m' },
			{ label: 'YTD', value: 'ytd' },
			{ label: `1 ${t(localizationConstants.year)}`, value: '1y' },
			{ label: `3 ${t(localizationConstants.years)}`, value: '3y' },
			{ label: `5 ${t(localizationConstants.years)}`, value: '5y' },
			{ label: `10 ${t(localizationConstants.years)}`, value: '10y' },
			{ label: 'Max', value: 'max' },
			{ label: `${t(localizationConstants.custom)}`, value: 'custom' }
		],
		[t]
	);

	const barSpanDropDown = useMemo(
		() => [
			{ label: `${t(localizationConstants.weekly)}`, value: '1w' },
			{ label: `${t(localizationConstants.monthly)}`, value: '1m' },
			{ label: `${t(localizationConstants.yearly)}`, value: '1y' }
		],
		[t]
	);

	const candleStickSpanDropDown = useMemo(
		() => [
			{ label: `1 ${t(localizationConstants.month)}`, value: '1m' },
			{ label: `3 ${t(localizationConstants.months)}`, value: '3m' },
			{ label: `6 ${t(localizationConstants.months)}`, value: '6m' }
		],
		[t]
	);

	const getSpanDropDownOptions = useMemo(() => {
		if (chartType === 'bar') {
			if (some(Object.values(allChartDetails), { notierung: 'M' })) {
				return [
					{ label: `${t(localizationConstants.monthly)}`, value: '1m' },
					{ label: `${t(localizationConstants.yearly)}`, value: '1y' }
				];
			} else if (some(Object.values(allChartDetails), { notierung: 'Q' })) {
				return [{ label: `${t(localizationConstants.yearly)}`, value: '1y' }];
			} else if (some(Object.values(allChartDetails), { notierung: 'Y' })) {
				return [{ label: `${t(localizationConstants.yearly)}`, value: '1y' }];
			} else if (some(Object.values(allChartDetails), { notierung: 'D' })) {
				return [
					{ label: `${t(localizationConstants.weekly)}`, value: '1w' },
					{ label: `${t(localizationConstants.monthly)}`, value: '1m' },
					{ label: `${t(localizationConstants.yearly)}`, value: '1y' }
				];
			} else {
				return barSpanDropDown;
			}
		} else if (chartType === 'candle') {
			return candleStickSpanDropDown;
		} else if ((chartType === 'area' || chartType === 'line') && chartDetails?.isIntraday) {
			return [{ label: `1 ${t(localizationConstants.day)}`, value: '1d' }, ...spanDropDown];
		} else {
			return spanDropDown;
		}
	}, [allChartDetails, barSpanDropDown, candleStickSpanDropDown, chartDetails?.isIntraday, chartType, spanDropDown, t]);

	const chartMapper: any = {
		defaultArea: `${t(localizationConstants.chartType)}`,
		area: `${t(localizationConstants.area)}`,
		candle: `${t(localizationConstants.candle)}`,
		line: `${t(localizationConstants.line)}`,
		bar: `${t(localizationConstants.bar)}`
	};

	const chartTypes: IDropDownOption[] = useMemo(
		() => [
			{
				label: `${t(localizationConstants.bar)}`,
				value: 'bar'
			},
			{
				label: `${t(localizationConstants.area)}`,
				value: 'area'
			},
			{
				label: `${t(localizationConstants.line)}`,
				value: 'line'
			}
		],
		[t]
	);

	const chartTypeOptions: IDropDownOption[] = useMemo(() => {
		if (chartDetails.isIntraday) {
			return [
				...chartTypes,
				{
					label: `${t(localizationConstants.candle)}`,
					value: 'candle'
				}
			];
		} else {
			return chartTypes;
		}
	}, [chartDetails.isIntraday, chartTypes, t]);

	const handleCustomSpan = useCallback(
		(values: FormikValues) => {
			setSpanObj({
				span: '',
				startDate: values.von,
				endDate: values.bis
			});
			setAction('');
			setIsChartSettingUpdate();
		},
		[setIsChartSettingUpdate, setSpanObj]
	);

	const fieldConfig: FieldConfig[] = [
		{
			type: 'date',
			label: `${t(localizationConstants.von)}`,
			name: 'von',
			placeHolder: `${DATE_PLACEHOLDER_MAPPER(language)[dateFormat]}`,
			maxDate: new Date(),
			minDate: new Date('01-01-2000')
		},
		{
			type: 'date',
			label: `${t(localizationConstants.bis)}`,
			name: 'bis',
			placeHolder: `${DATE_PLACEHOLDER_MAPPER(language)[dateFormat]}`,
			minDate: 'von'
		}
	];

	return (
		<>
			<div className='d-flex justify-content-space-between align-items-center chart-indicators pt-24'>
				<div className='d-flex align-items-center indicator-section-1'>
					<div onClick={() => props.setAction('chartSettings')} className='settings-icon d-flex cursor-pointer'>
						<WhiteSettingIcon />
					</div>
					<CustomIndicatorsDropdown
						title={capitalizeFirstLetter(chartMapper[chartType])}
						options={chartTypeOptions}
						handleClick={handleChartType}
						selectedData={chartType}
					/>
					<CustomIndicatorsDropdown
						title={`${t(localizationConstants.span)}`}
						options={getSpanDropDownOptions}
						handleClick={handleSpan}
						openCalenderPopUp={() => setAction('openCalender')}
						selectedData={endDate && startDate ? 'custom' : span}
					/>
					<ComparisionSearchBar disabledSearch={disabledSearch} getComparativeChartData={getComparativeChartData} />
				</div>
			</div>
			{action === 'openCalender' && (
				<CustomModal show={true} handleClose={() => setAction('')}>
					<h2 className='no-margin font--20px'>
						<Translate text={localizationConstants.setPeriod} />
					</h2>
					<CustomForm
						schema={setPeriodValidationSchema}
						onSubmit={handleCustomSpan}
						loading={false}
						fieldConfig={fieldConfig}
						showLabels
						formClassName='mt-10'
						submitBtnClassName={'popup-submit font--14px font-weight--400 line-height--20px full-width mt-32'}
						cancleBtnClassName={' font--14px font-weight--400 line-height--20px full-width mt-32'}
						submitBtnText={`${t(localizationConstants.takeOver)}`}
						cancelSubmit={() => setAction('')}
						cancelBtnName={`${t(localizationConstants.abort)}`}
						customFormat
						validateOnChange
					/>
				</CustomModal>
			)}
		</>
	);
};

export default ChartConfigurationDropdowns;

import React from 'react';
import * as Sentry from '@sentry/browser';

import authService from 'shared/services/auth.service';
import withRouter from 'shared/hoc/withRouter';
import { RouteComponentParams } from 'shared/interface';

import { LoginResponse } from 'features/login/interface/login';
import ErrorPage from './errorPage';

interface OwnProps extends RouteComponentParams {
	children?: any;
}

const userDetails: LoginResponse = authService.getAuthData() as LoginResponse;

class ErrorBoundary extends React.Component<OwnProps> {
	state: {
		error: Error | null;
	} = {
		error: null
	};
	static getDerivedStateFromError(error: Error | null) {
		return { error };
	}

	componentDidMount() {
		//window.onerror = this.logError;
	}

	componentDidCatch(error: Error | null, info: any) {
		Sentry.withScope((scope) => {
			Object.keys(info).forEach((key) => {
				scope.setExtra(key, info[key]);
			});
			scope.setExtra('user', {
				user: userDetails.email,
				userId: userDetails.benutzer_id
			});

			Sentry.captureException(error);
		});
		this.logError(error);
	}

	render() {
		if (this.state.error) {
			const { message, name, stack, cause } = this.state.error;
			const errorDetails = {
				message,
				name,
				stack,
				cause
			};
			return <ErrorPage errorMessage={JSON.stringify(errorDetails)} resetError={this.resetError} />;
		}
		return this.props.children;
	}

	logError = (args: Error | null) => {
		console.log(args);
	};

	resetError = () => {
		this.setState({ error: null });
	};
}

export default withRouter(ErrorBoundary);

import { ArcElement, Chart, LayoutPosition, Legend, Tooltip } from 'chart.js';
import React, { useEffect, useState } from 'react';
import { API_CONFIG } from 'shared/constants/constants';
import HttpService from 'shared/services/http.service';
import { ICostModal } from '../interface/costModal';
import DoughnutChartContainer from './doughnutChartContainer';

export interface IProductValue {
	name: string;
	value: number;
	productPrice: number;
}

Chart.register(ArcElement, Legend, Tooltip);

export const chartColors = ['#84878B', '#FF9100', '#999CA2', '#D4D6D9', '#61656A', '#44474D', '#272A30', '#081D34', '#EDEEEF', '#40AD1A'];

interface centerTextDesign {
	fontWeight: string;
	size: number;
	fontColor: string;
	lineHeight: number;
}

interface IProps {
	position: LayoutPosition;
	id: number;
	align: 'start' | 'center' | 'end' | undefined;
	legendsPadding: number;
	textDesign: centerTextDesign[];
	action: string;
}

const DoughnutChart: React.FC<IProps> = (props) => {
	const [dougnutChartData, setDougnutChartData] = useState({} as ICostModal);
	const [loading, setLoading] = useState(false);
	const { id, position, align, legendsPadding, action, textDesign } = props;
	useEffect(() => {
		getDoughnutChartData();
	}, [id]);

	const getDoughnutChartData = async () => {
		setLoading(true);
		try {
			const response = await HttpService.get(`${API_CONFIG.path.getDoughnutChartData}/${id}`);
			setDougnutChartData(response);
			setLoading(false);
		} catch {
			setLoading(false);
		}
	};

	return (
		<DoughnutChartContainer
			action={action}
			align={align}
			dougnutChartData={dougnutChartData}
			id={id}
			legendsPadding={legendsPadding}
			position={position}
			textDesign={textDesign}
			loading={loading}
		/>
	);
};

export default DoughnutChart;

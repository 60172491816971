import React from 'react';

interface IProps {
	item: any;
}

const TooltipBackgroundShapeSVG: React.FC<IProps> = (props) => {
	const { item } = props;

	return (
		<>
			<defs>
				<filter id='boxShadow' x='-50%' y='-50%' width='200%' height='200%'>
					<feOffset in='SourceAlpha' dx='0' dy='10' />
					<feGaussianBlur stdDeviation='10' />
					<feColorMatrix
						type='matrix'
						values='0 0 0 0 0
               	 				0 0 0 0 0
                				0 0 0 0 0
                				0 0 0 0.15 0'
					/>
					<feMerge>
						<feMergeNode in='SourceGraphic' />
						<feMergeNode />
					</feMerge>
				</filter>
			</defs>
			<g>
				<rect
					x={0}
					y={0}
					width={item.width}
					height={item.height}
					rx={10}
					ry={10}
					fill={'#F5F9FC'}
					opacity={1}
					stroke={'#F5F9FC'}
					filter='url(#boxShadow)' // Apply the box shadow
					style={{
						padding: '10px',
						stroke: '#F5F9FC'
					}}
				/>
				<rect
					x={0}
					y={0}
					width={item.width}
					height={item.height}
					rx={10}
					ry={10}
					fill={'#F5F9FC'}
					opacity={1}
					stroke={'#F5F9FC'}
					style={{
						padding: '10px',
						stroke: '#F5F9FC'
					}}
				/>
			</g>
		</>
	);
};

export default TooltipBackgroundShapeSVG;

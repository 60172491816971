import { rebind, merge } from 'react-stockcharts/lib/utils';

import baseIndicator from 'react-stockcharts/lib/indicator/baseIndicator';
import stoClaculator from './stoCalculator';

const ALGORITHM_TYPE = 'STO';

export default function (sourcePath: string, scaleType: string): any {
	const base = baseIndicator().type(ALGORITHM_TYPE);

	const underlyingAlgorithm = stoClaculator(sourcePath, scaleType);
	const mergedAlgorithm = merge()
		.algorithm(underlyingAlgorithm)
		.merge((datum: any, indicator: any) => {
			datum.sto = indicator;
		});

	const indicator = function (data: any, options = { merge: true }) {
		if (options.merge) {
			if (!base.accessor()) throw new Error(`Set an accessor to ${ALGORITHM_TYPE} before calculating`);
			return mergedAlgorithm(data);
		}
		return underlyingAlgorithm(data);
	};

	rebind(indicator, base, 'id', 'accessor', 'stroke', 'fill', 'echo', 'type');
	rebind(indicator, underlyingAlgorithm, 'options', 'undefinedLength');
	rebind(indicator, mergedAlgorithm, 'merge', 'skipUndefined');
	return indicator;
}

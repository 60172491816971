import { combineReducers } from 'redux';
import login from 'features/login/store/login.reducer';
import loadingReducer from './loadingReducer';
import siteConfig from './siteConfigReducer';
import { State, Action } from 'shared/interface';

import * as actionTypes from 'store/action-types';
import chartConfigReducer from 'features/futuretable/store/chart.reducer';
import dashboardChartReducer from 'features/dashboard/store/dashboard.reducer';
import tenderReducer from 'features/tenders/store/tender.reducer';
import sideNavConfig from './sideNavReducer';

const appReducer = combineReducers({
	siteConfig: siteConfig,
	auth: login,
	loading: loadingReducer,
	chartConfigReducer: chartConfigReducer,
	dashboardChartReducer: dashboardChartReducer,
	tenderReducer: tenderReducer,
	sideNavConfig: sideNavConfig
});

const rootReducer = (state: State | undefined, action: Action): State => {
	if (action.type === actionTypes.AUTH_LOGOUT_SUCCESS || action.type === actionTypes.AUTH_LOGOUT_FAIL) {
		localStorage.removeItem('authData');
		localStorage.removeItem('userData');
		state = state?.siteConfig.isForgotPassword
			? ({ siteConfig: { isForgotPassword: true } } as State)
			: ({ siteConfig: { sessionExpireMessage: state?.siteConfig.sessionExpireMessage } } as State);
	}
	return appReducer(state, action);
};

export default rootReducer;

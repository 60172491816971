import React, { useEffect, useState } from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { formatLocale } from 'd3-format';
import { useSelector } from 'react-redux';
import { Chart } from 'react-stockcharts';
import { AreaSeries } from 'react-stockcharts/lib/series';
import { XAxis, YAxis } from 'react-stockcharts/lib/axes';
import { HoverTooltip } from 'react-stockcharts/lib/tooltip';
import { discontinuousTimeScaleProvider } from 'react-stockcharts/lib/scale';
import { MouseCoordinateY, CrossHairCursor } from 'react-stockcharts/lib/coordinates';

import { englishLocale, germanLocale } from 'shared/constants/constants';
import { State } from 'shared/interface';
import { IFutureTable } from '../interface/futureTable';
import { Translate } from 'shared/util/translation/translate';
import localizationConstants from 'shared/util/translation/constants';
import ChartCanvas from 'features/futuretable/util/chartCanvas';
interface IProps {
	futureTableData: IFutureTable;
	chartId: string;
}

const ForwardCurve: React.FC<IProps> = (props) => {
	const { futureTableData, chartId } = props;
	const { language } = useSelector((state: State) => state.siteConfig);
	const [containerWidth, setContainerWidth] = useState(0);
	const [containerHeight, setContainerHeight] = useState(0);

	const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor((d: any) =>
		d.faelligkeit_f !== '(Cont.)' ? new Date(d.faelligkeit_f) : new Date()
	);
	const { data: chartData, xScale, xAccessor, displayXAccessor } = xScaleProvider(futureTableData.items);

	const handleResize = () => {
		const id = document.getElementById(chartId);
		const widthId = document.getElementById(`forward-curve`);
		if (id && widthId) {
			setContainerWidth(id.offsetWidth);
			setContainerHeight(widthId.offsetHeight);
		}
	};

	const germanNumberFormattter = (number: number) => {
		const numberFormatter = formatLocale({
			decimal: '.',
			thousands: ',',
			grouping: [3],
			currency: ['', '']
		});
		const num = numberFormatter.format(',.2f')(number);
		const splitNumber = num.split('.');
		if (splitNumber[1] === '00') {
			return numberFormatter.format(',.0f')(number);
		} else {
			return numberFormatter.format(',.2f')(number);
		}
	};

	const tooltipContent =
		() =>
		({ currentItem }: any) => {
			moment.updateLocale(language, language === 'de' ? germanLocale : englishLocale);
			return {
				x: [`      ${currentItem.faelligkeit_f}`],
				y: [
					{
						label: `${
							(language === 'en' && futureTableData.details?.name_eng !== '' && `${futureTableData.details?.name_eng}:`) ||
							`${futureTableData.details?.name_deu}:`
						} `,
						value: `${currentItem.hauptkurs}`
					}
				]
			};
		};

	const mouseEdgeAppearance = {
		textFill: '#542605',
		stroke: '#05233B',
		strokeOpacity: 1,
		fill: '#FFF'
	};

	const onMouseEnter = () => {
		const pageWrapper = document.getElementById('scrollable-wrapper');
		if (pageWrapper) {
			pageWrapper.style.overflow = 'hidden';
		}
	};
	const onMouseLeave = () => {
		const pageWrapper = document.getElementById('scrollable-wrapper');
		if (pageWrapper) {
			pageWrapper.style.overflow = 'auto';
		}
	};

	useEffect(() => {
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<div
			id={chartId}
			className='forward-curve-container mr-34 shadow-box width-half bg-color--white br--6px'
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<div className='bg-color--primary text-white forward-curve-header font--16px font-weight--700 line-heigh--22pxt pl-24 pt-8 pb-8 br--6px'>
				<Translate text={localizationConstants.forwardCurve} />
			</div>
			{!isEmpty(futureTableData.items) && (
				<div className='chart-container no-padding' id={`forward-curve`}>
					<ChartCanvas
						width={containerWidth || 444}
						height={containerHeight || 263}
						ratio={2}
						margin={{ left: 20, right: 60, top: 20, bottom: 50 }}
						type='svg'
						seriesName='Stock Prices'
						data={chartData}
						xScale={xScale}
						xAccessor={xAccessor}
						displayXAccessor={displayXAccessor}
						disableMouseMoveEvents={true} // Disable mouse move events
						mouseMoveEvent={true}
						panEvent={true} // Disable panning
						zoomEvent={false}
						clamp={true}
						zoomEnabled={false}
						pointsPerPxThreshold={10}
					>
						<Chart id={0} yExtents={(d: any) => [d.hauptkurs]}>
							<defs>
								<linearGradient id='chartGradiant' x1='150.13' y1='-2.52979' x2='150.13' y2='149.669' gradientUnits='userSpaceOnUse'>
									<stop stopColor='#D5E9FD' />
									<stop offset='0.869054' stopColor='#D5E9FD' stopOpacity='0' />
								</linearGradient>
							</defs>
							<XAxis
								zoomEnabled={false}
								axisAt='bottom'
								orient='bottom'
								tickFormat={(d: number) => {
									return chartData[d].faelligkeit_f;
								}}
								fontWeight={400}
								fontSize={8}
							/>
							<YAxis
								axisAt='right'
								orient='right'
								zoomEnabled={false}
								fontSize={8}
								fontWeight={400}
								tickFormat={(d: any) => germanNumberFormattter(d)}
							/>
							<CrossHairCursor fontSize={10} strokeDasharray='Solid' />
							<AreaSeries zoomEnabled={false} yAccessor={(d: any) => d.hauptkurs} fill='url(#chartGradiant)' stroke='#14304F' />
							<MouseCoordinateY
								at='right'
								orient='right'
								fontSize={10}
								{...mouseEdgeAppearance}
								displayFormat={germanNumberFormattter}
							/>
							<HoverTooltip tooltipContent={tooltipContent()} fontSize={10} fill='#FFF' bgFill='transparent' />
						</Chart>
					</ChartCanvas>
				</div>
			)}
		</div>
	);
};

export default ForwardCurve;

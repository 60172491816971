import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/constants';
import { Loader } from 'shared/components/spinner/spinner';
import { useWhitelabelConfigStatus } from 'shared/hooks/useWhitelabelConfigStatus';

import { IReportsBoxes } from '../interface/forecast';
import AllForecastCards from './allForecastCards';
import { isEmpty } from 'lodash';
import EmptyDataContainer from 'shared/components/emptyDataContainer/emptyDataContainer';

const WhiteLabelForecastList = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [forecastList, setForecastList] = useState<IReportsBoxes>({ boxes: [], noGroup: [] });
	const { whitelabelId } = useWhitelabelConfigStatus();
	const navigate = useNavigate();

	const fetchForecastList = useCallback(() => {
		setIsLoading(true);
		httpService
			.get(`${API_CONFIG.path.fetchWhiteLabelForecastBoxes}/${whitelabelId}`)
			.then((response) => {
				setIsLoading(false);
				setForecastList(response);
			})
			.catch((err) => {
				setIsLoading(false);
				console.error('error', err);
			});
	}, [whitelabelId]);

	useEffect(() => {
		fetchForecastList();
	}, [fetchForecastList]);

	const handleOnClick = useCallback(
		(groupId: number, type: string) => {
			if (type === 'box') {
				navigate(`/forecasts/list/${groupId}?whitelabelId=${whitelabelId}`);
			} else {
				navigate(`/forecasts/data/${groupId}?whitelabelId=${whitelabelId}`);
			}
		},
		[whitelabelId]
	);

	return (
		<>
			{isLoading && <Loader />}
			{!isLoading && (
				<div className='forecast-list'>
					{isEmpty(forecastList?.boxes) && isEmpty(forecastList?.noGroup) && <EmptyDataContainer />}
					<div className={`d-flex flex-wrap text-align-center justify-content-center mb-68 reports-box--wrapper`}>
						<AllForecastCards
							forecastList={forecastList?.boxes || []}
							className='reports--box'
							type='box'
							handleOnClick={handleOnClick}
						/>
					</div>
					<div className={`d-flex flex-wrap text-align-center justify-content-center reports-box--wrapper`}>
						<AllForecastCards
							forecastList={forecastList?.noGroup || []}
							className='reports-small--box'
							type='group'
							handleOnClick={handleOnClick}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default WhiteLabelForecastList;

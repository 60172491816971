import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import Button from 'shared/components/form/button';
import CustomModal from 'shared/components/modal/modal';
import { notify } from 'shared/components/notification/notification';
import { API_CONFIG, reactSelectStyles } from 'shared/constants/constants';
import { IDropDownOption, State } from 'shared/interface';
import httpService from 'shared/services/http.service';
import i18n from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';
import Select, { ValueType } from 'react-select';

interface IProps {
	symbol: string;
	boerse: string;
	setAction: (action: string) => void;
	getAdminCategory: () => void;
}

const AddCourseListPopup: React.FC<IProps> = (props) => {
	const { t } = i18n;
	const { boerse, symbol, setAction } = props;
	const { language } = useSelector((state: State) => state.siteConfig);
	const [selectedOption, setSelectedOption] = useState({} as IDropDownOption);
	const [dropDownOptions, setDropDownOptions] = useState([] as IDropDownOption[]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		getAllMBIListDropdown();
	}, []);

	const getAllMBIListDropdown = () => {
		httpService
			.get(API_CONFIG.path.mbiListDropdown)
			.then((res) => {
				const tempArray = [] as IDropDownOption[];
				res.map((value: any) => {
					tempArray.push({
						value: value.listen_id,
						label: language === 'en' && value.listen_name_eng !== '' ? value.listen_name_eng : value.listen_name
					});
				});
				setDropDownOptions(tempArray);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const addCourseTOMbiList = () => {
		setIsLoading(true);
		const params = {
			listen_id: selectedOption.value,
			symbol,
			boerse
		};
		httpService
			.post(API_CONFIG.path.addMBIList, params)
			.then((response) => {
				if (!response.isError) {
					setIsLoading(false);
					setAction('');
					notify(t(localizationConstants.addedToList), 'success', { id: 'addedToList' });
					props.getAdminCategory();
				}
			})
			.catch((err) => {
				setIsLoading(false);
				console.error(err);
			});
	};

	return (
		<CustomModal show={true} handleClose={() => setAction('')} className={'course-list-popup'}>
			<h2 className='modal-title font--20px line-height--28px '>
				<Translate text={localizationConstants.addCourseToCourseList} />
			</h2>
			<Select
				style={reactSelectStyles}
				selectedValue={selectedOption}
				onChange={(selected: ValueType<IDropDownOption, false>) => {
					setSelectedOption(selected as IDropDownOption);
				}}
				options={dropDownOptions}
				placeholder={`${t(localizationConstants.mbiListPlaceHolder)}`}
				isSearchable={true}
				isClearable={!isEmpty(selectedOption)}
			/>
			<div className='full-width text-align-right mt-10'>
				<Button
					btnType='primary'
					className='button-size--medium'
					onClick={addCourseTOMbiList}
					loading={isLoading}
					disabled={isLoading || isEmpty(selectedOption)}
				>
					<Translate text={localizationConstants.add} />
				</Button>
			</div>
		</CustomModal>
	);
};

export default AddCourseListPopup;

import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';

import Button from 'shared/components/form/button';
import { notify } from 'shared/components/notification/notification';
import { API_CONFIG } from 'shared/constants/constants';
import { useQuery } from 'shared/hooks/useQuery';
import { PlusWhiteIcon, WhiteDownloadIcon } from 'shared/icons/icon';
import httpService from 'shared/services/http.service';
import i18n from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';

import { has } from 'lodash';
import { useLocation } from 'react-router-dom';
import {
	IChartData,
	IChartDetails,
	IChartSelection,
	IChartSettingsType,
	ICompareChartIndicators,
	ISearchMaterialData,
	ISpan
} from '../interface/futureTable';
import AddPricePopup from './addPricePopup';
import ExportDataPopup from './exportDataPopup';
import SetupPriceAlert from './setUpPriceAlert';

interface IProps {
	chartDetails: IChartDetails;
	chartData: IChartData[];
	chartSelection: IChartSelection;
	comparativeChartIndicator: ICompareChartIndicators[] | ISearchMaterialData[] | IChartSettingsType[];
	strokeColors: any;
	id: string;
	action: string;
	setAction: (action: string) => void;
	symbol: string;
	chartSettings: IChartSettingsType[];
	scaleType: string;
	spanObj: ISpan;
}

const ExportMaterialDataButtons: React.FC<IProps> = (props) => {
	const params = useQuery();
	const { t } = i18n;
	const [loading, setLoading] = useState(false);
	const {
		spanObj,
		chartDetails,
		chartData,
		chartSelection,
		comparativeChartIndicator,
		strokeColors,
		chartSettings,
		scaleType,
		action,
		id,
		setAction,
		symbol
	} = props;
	const location = useLocation();
	const addChartToDashboardFunction = () => {
		const { boerse, symbol, basis_symbol } = chartDetails;
		const updatedCoparativeIndicator = comparativeChartIndicator.map((ele) => {
			if (!has(ele, 'isNormalize')) {
				return {
					...ele,
					isNormalize: true
				};
			} else {
				return ele;
			}
		});
		if (!isEmpty(chartDetails)) {
			const payload = {
				boerse,
				symbol,
				basis_symbol,
				element_id: params.get('element_id'),
				chartConfig: {
					chartSelection: chartSelection,
					customChartSpan: { von: spanObj?.startDate || '', bis: spanObj?.endDate || '' },
					comparativeChartIndicator: updatedCoparativeIndicator,
					strokeColors: strokeColors,
					advanceChartConfig: { chartSettings, scaleType }
				}
			};
			setLoading(true);
			httpService
				.post(API_CONFIG.path.addChartToDashboard, payload)
				.then((res) => {
					res.message === 'success' &&
						notify(
							t(chartDetails.isChartAlreadyAdded ? localizationConstants.updatedToDashboard : localizationConstants.addedToDashboard),
							'success',
							{ id: 'addChart' }
						);
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
					console.error(err);
				});
		}
	};

	return (
		<>
			<div className='d-flex'>
				<Button
					btnType='primary'
					className='mr-8 padding-left-right--16px'
					icon={<PlusWhiteIcon className='mr-10 icon-small ' />}
					iconPosition='left'
					onClick={() => setAction('setAlert')}
					disabled={chartDetails.disallowed === 1 || location.pathname.includes('chartScreen')}
				>
					<Translate text={localizationConstants.setUpPriceAlert} />
				</Button>
				<Button
					btnType='primary'
					className='mr-8 padding-left-right--16px'
					icon={<PlusWhiteIcon className='mr-10 icon-small ' />}
					iconPosition='left'
					onClick={() => setAction('addPrice')}
					disabled={location.pathname.includes('chartScreen')}
				>
					<Translate text={localizationConstants.addToPrice} />
				</Button>
				<Button
					btnType='primary'
					className='mr-8 padding-left-right--16px'
					icon={<PlusWhiteIcon className='mr-10 icon-small ' />}
					iconPosition='left'
					onClick={addChartToDashboardFunction}
					loading={loading}
					disabled={loading}
				>
					<Translate
						text={
							chartDetails.isChartAlreadyAdded
								? localizationConstants.updateChartToDashboard
								: localizationConstants.addChartToDashboard
						}
					/>
				</Button>
				<div className='export-btn-wrapper'>
					<Button
						btnType='primary'
						iconPosition='left'
						icon={<WhiteDownloadIcon className='mr-10' />}
						className=' padding-left-right--16px font-weight--500 line-height--20px border--2px-solid-blue'
						onClick={() => {
							setAction('downloadCourse');
						}}
						disabled={isEmpty(chartData) || chartData.length < 7}
					>
						<Translate text={localizationConstants.exportData} />
					</Button>
				</div>
			</div>
			{action === 'setAlert' && (
				<SetupPriceAlert chartDetails={chartDetails} setAction={setAction} boerse={params.get('boerse')} symbol={params.get('symbol')} />
			)}
			{action === 'addPrice' && <AddPricePopup setAction={setAction} boerse={params.get('boerse')} symbol={params.get('symbol')} />}
			{action === 'downloadCourse' && (
				<ExportDataPopup
					setAction={setAction}
					chartDetails={chartDetails}
					spanObj={spanObj}
					chartType={chartSelection.type}
					id={id}
					symbol={symbol}
					comparativeChartIndicator={comparativeChartIndicator}
					chartSettings={chartSettings}
					scaleType={scaleType}
					chartData={chartData}
				/>
			)}
		</>
	);
};

export default ExportMaterialDataButtons;

import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { LockIcon } from 'shared/icons/icon';
import { State } from 'shared/interface';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';

import { IDashboardType, IItemTypes, IListComponent, IListComponentParams } from 'features/dashboard/interface/dashboard';
import { useWhitelabelConfigStatus } from 'shared/hooks/useWhitelabelConfigStatus';
import { customNumberFormatter, replaceHashtagFromUrl } from 'shared/util/utility';
import DashboardCardHeader from './dashboardCardHeader';

interface Props {
	deleteData: (data: string, action: boolean) => void;
	value: IDashboardType<IListComponent, IListComponentParams>;
}

const ListComponent = ({ value, deleteData }: Props) => {
	const { language } = useSelector((state: State) => state.siteConfig);
	const navigate = useNavigate();
	const { whitelabelId, material_status } = useWhitelabelConfigStatus();

	const buildHeaderPath = () => {
		const base = value.parent_id === null || value.parent_id === undefined ? '/materials/myMaterial/courseList' : '/materials';
		const params = new URLSearchParams({
			listen_id: value.params.lid,
			element_id: value.element_id,
			...(value.params.waehrung && { waehrung: value.params.waehrung }),
			...(value.parent_id && value.parent_id !== null && { parentId: value.parent_id.toString() }),
			...(value.component_data.group_id && { parentSubmenuId: value.component_data.group_id.toString() }),
			...(value.params.whitelabelId && { whitelabelId: value.params.whitelabelId })
		});
		const url = `${base}?${params.toString()}`;
		const isInactiveMaterialStatus = material_status === 'inactive' && !value.params.whitelabelId;
		const parsedWhitelabelId = parseInt(value.params.whitelabelId as string);
		const isParsedWhitelabelIdEqual = parsedWhitelabelId === whitelabelId;
		const isActiveMaterialStatus = material_status === 'active' && isParsedWhitelabelIdEqual;
		const isActiveOriginalStatus = material_status === 'active+original' && (!value.params.whitelabelId || isParsedWhitelabelIdEqual);
		const isUnassignedWhitelabel = !whitelabelId && !value.params.whitelabelId;

		if (isInactiveMaterialStatus || isActiveMaterialStatus || isActiveOriginalStatus || isUnassignedWhitelabel || value.params.bid) {
			navigate(url);
		}
	};

	return (
		<>
			<DashboardCardHeader
				name={(language === 'en' && value.name_eng !== '' && value.name_eng) || value.name}
				deleteData={deleteData}
				action={true}
				elementId={value.element_id}
				section='list'
				navigateToPage={buildHeaderPath}
			/>
			<div className={`card-items overflow-x-hidden`}>
				{!isEmpty(value.component_data.items) ? (
					value.component_data.items.map((data) => <RenderItem data={data} value={value} />)
				) : (
					<p className='no-result-title pt--20 text-align-center font--20px'>
						<Translate text={localizationConstants.noCurrentData} />
					</p>
				)}
			</div>
		</>
	);
};

export default ListComponent;

interface IRenderItemProps {
	data: IItemTypes;
	value: IDashboardType<IListComponent, IListComponentParams>;
}

const RenderItem: React.FC<IRenderItemProps> = ({ data, value }) => {
	const navigate = useNavigate();
	const { language } = useSelector((state: State) => state.siteConfig);
	const navigateToMaterial = (data: IItemTypes) => {
		const url = `/materials/futureTable?materialCourseListListenId=${data.listen_id}&boerse=${replaceHashtagFromUrl(data.boerse)}&basis_symbol=${
			data.basis_symbol
		}&materialParentId=${value.parent_id}&symbol=${replaceHashtagFromUrl(data.symbol)}&currency=${data.waehrung}`;
		navigate(url);
	};

	return (
		<React.Fragment key={data.id}>
			<div className='items border-bottom-grayscale'>
				<div className='item-title '>
					<h1
						className='truncate--text no-margin font--14px line-height--20px font-weight--400 mb-8 cursor-pointer'
						onClick={() => navigateToMaterial(data)}
					>
						{language === 'en' ? data.name_eng : data.name || '-'}
					</h1>
				</div>
				<div className='item-values d-flex justify-content-space-between'>
					<div className='value width-33'>
						<span className='title font--10px mb-2 font-weight--400 line-height--14px'>
							<Translate text={localizationConstants['date']} />
						</span>
						<p className='item font--14px font-weight--500 no-margin line-height--20px truncate--lines-1'>{data.datum}</p>
					</div>
					<div className='value width-33 text-align-center'>
						<span className='title font--10px mb-2 font-weight--400 line-height--14px'>
							<Translate text={localizationConstants.price} />
						</span>
						<p
							className={`item font--14px font-weight--500 no-margin line-height--20px ${
								data.diff_prozent < 0 ? 'text-red' : data.diff_prozent > 0 ? 'text-green' : ''
							}`}
						>
							{data.disallowed === 0 ? (
								data.hauptkurs !== null ? (
									customNumberFormatter(data.hauptkurs, data.nkstellen)
								) : (
									''
								)
							) : (
								<LockIcon className='lock-icon' />
							)}
						</p>
					</div>
					<div className='value width-33 text-align-right'>
						<span className='title font--10px mb-2 font-weight--400 line-height--14px truncate--text'>
							<Translate text={localizationConstants.change} />
						</span>
						<p
							className={`item font--14px font-weight--500 no-margin line-height--20px ${
								data.diff_prozent < 0 ? 'text-red' : data.diff_prozent > 0 ? 'text-green' : ''
							}`}
						>
							{data.diff_prozent > 0 && data.disallowed === 0 && '+'}
							{data.disallowed === 0 ? (
								data.diff_prozent !== null ? (
									`${customNumberFormatter(data.diff_prozent, 2)}%`
								) : (
									''
								)
							) : (
								<LockIcon className='lock-icon' />
							)}
						</p>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

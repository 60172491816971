import React, { useMemo, useState } from 'react';
import { Formik, FormikValues } from 'formik';
import isEmpty from 'lodash/isEmpty';

import Button from 'shared/components/form/button';
import { Input } from 'shared/components/form/inputTypes';
import CustomModal from 'shared/components/modal/modal';
import { shortcutLinkValidation } from 'shared/constants/validation-schema';
import i18n from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';

import { IMyLinkList } from '../interface/dashboard';
import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/constants';
import { notify } from 'shared/components/notification/notification';

interface IProps {
	action: string;
	initialValues: IMyLinkList;
	handleClose: () => void;
	fetchDashboardData: () => void;
	elementId: string;
}

const AddShortcutPopup: React.FC<IProps> = (props) => {
	const { t } = i18n;
	const { action, initialValues, handleClose, fetchDashboardData, elementId } = props;

	const [loading, setLoading] = useState(false);

	const formatValues = useMemo(() => {
		const data: FormikValues = { ...initialValues };
		if (!isEmpty(data)) {
			for (const key in data) {
				if (!['link_name', 'ziel_url', 'bild_url'].includes(key)) {
					delete data[key];
				}
			}
			return data;
		} else {
			return {
				link_name: '',
				ziel_url: '',
				bild_url: ''
			};
		}
	}, [initialValues]);

	const onSubmit = (values: FormikValues) => {
		let data = { ...values };
		data = {
			...data,
			link_name: data.link_name.replace(/  +/g, ' ').trim()
		};
		setLoading(true);
		if (action === 'editShortcut') {
			httpService
				.put(API_CONFIG.path.editShortcut, { ...data, element_id: elementId, type: 'link' })
				.then(() => {
					notify(t(localizationConstants.shortcutUpdated), 'success', { id: 'shortcut_updated' });
					setLoading(false);
					handleClose();
					fetchDashboardData();
				})
				.catch((err) => {
					setLoading(false);
					console.error('error', err);
				});
		} else {
			httpService
				.post(API_CONFIG.path.addShortcut, { ...data, type: 'link' })
				.then(() => {
					notify(t(localizationConstants.shortcutCreated), 'success', { id: 'shortcut_created' });
					setLoading(false);
					handleClose();
					fetchDashboardData();
				})
				.catch((err) => {
					setLoading(false);
					console.error('error', err);
				});
		}
	};

	return (
		<CustomModal show={true} className='width--400px d-flex align-items-center margin--center' handleClose={handleClose}>
			<h2 className='modal-title font--20px font-weight--500'>
				<Translate text={localizationConstants.addShortcut} />
			</h2>
			<Formik initialValues={formatValues} onSubmit={onSubmit} validationSchema={shortcutLinkValidation}>
				{({ handleSubmit, setFieldValue, dirty }) => {
					return (
						<form onSubmit={handleSubmit}>
							<Input
								showLabels
								name='link_name'
								className='full-width mb-10'
								setFieldValue={setFieldValue}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue('link_name', event.target.value.trimStart())}
								type='text'
								placeholder={`${t(localizationConstants.title)}`}
								config={{ name: 'link_name', type: 'text', label: `${t(localizationConstants.title)}` }}
							/>
							<Input
								className='full-width mb-10'
								showLabels
								placeholder={`${t(localizationConstants.urlplaceholder)}`}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue('ziel_url', event.target.value.trim())}
								type='text'
								name='ziel_url'
								config={{ name: 'ziel_url', type: 'text', label: `${t(localizationConstants.targetUrl)}` }}
								setFieldValue={setFieldValue}
							/>
							<Input
								className='full-width mb-10'
								showLabels
								name='bild_url'
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue('bild_url', event.target.value.trim())}
								type='text'
								config={{ name: 'bild_url', type: 'text', label: `${t(localizationConstants.urlForLogoPriview)}` }}
								setFieldValue={setFieldValue}
								placeholder={`${t(localizationConstants.urlplaceholder)}`}
							/>
							<Button loading={loading} disabled={!dirty} btnType='primary' type='submit' className='full-width'>
								<Translate text={action === 'editShortcut' ? localizationConstants.save : localizationConstants.add} />
							</Button>
						</form>
					);
				}}
			</Formik>
		</CustomModal>
	);
};

export default AddShortcutPopup;

import { IWhitelabelGroupsConfig } from 'features/admin/interface/admin';
import { LoginResponse } from 'features/login/interface/login';
import isEmpty from 'lodash/isEmpty';
import React, { PropsWithChildren } from 'react';
import authService from 'shared/services/auth.service';

interface IProps extends PropsWithChildren {
	id?: string;
	className?: string;
}

const ColorLayout: React.FC<IProps> = (props) => {
	const userData = authService.getAuthData() as LoginResponse;
	const whiteLabelConfig = userData ? userData.whitelable_config : ({} as IWhitelabelGroupsConfig);
	return (
		<div
			id={props.id}
			className={props.className}
			style={
				{
					'--primary-color': `${!isEmpty(whiteLabelConfig) ? whiteLabelConfig?.primary_color : '#081d34'}`,
					'--primary-light-color': `${!isEmpty(whiteLabelConfig) ? whiteLabelConfig?.primary_light_color : '#14304f'}`,
					'--primary-lighter-color': `${!isEmpty(whiteLabelConfig) ? whiteLabelConfig?.primary_lighter_color : '#eef4fb'}`,
					'--accent-yellow-color': `${!isEmpty(whiteLabelConfig) ? whiteLabelConfig?.accent_yellow_color : '#ff9100'}`,
					'--success-color': `${!isEmpty(whiteLabelConfig) ? whiteLabelConfig?.success_color : '#40ad1a'}`,
					'--error-color': `${!isEmpty(whiteLabelConfig) ? whiteLabelConfig?.error_color : '#f53030'}`,
					'--red-light-color': `${!isEmpty(whiteLabelConfig) ? whiteLabelConfig?.red_light_color : '#ffd3d3'}`,
					'--blue-color': `${!isEmpty(whiteLabelConfig) ? whiteLabelConfig?.blue_color : '#164e8c'}`,
					'--greyscale-0': `${!isEmpty(whiteLabelConfig) ? whiteLabelConfig?.greyscale_0 : '#ffffff'}`,
					'--greyscale-5': `${!isEmpty(whiteLabelConfig) ? whiteLabelConfig?.greyscale_5 : '#fbfbfb'}`,
					'--greyscale-10': `${!isEmpty(whiteLabelConfig) ? whiteLabelConfig?.greyscale_10 : '#f4f5f5'}`,
					'--greyscale-20': `${!isEmpty(whiteLabelConfig) ? whiteLabelConfig?.greyscale_20 : '#edeeef'}`,
					'--greyscale-25': `${!isEmpty(whiteLabelConfig) ? whiteLabelConfig?.greyscale_25 : '#e2e2e2'}`,
					'--greyscale-30': `${!isEmpty(whiteLabelConfig) ? whiteLabelConfig?.greyscale_30 : '#d4d6d9'}`,
					'--greyscale-40': `${!isEmpty(whiteLabelConfig) ? whiteLabelConfig?.greyscale_40 : '#c8cbcf'}`,
					'--greyscale-50': `${!isEmpty(whiteLabelConfig) ? whiteLabelConfig?.greyscale_50 : '#b3b6bb'}`,
					'--greyscale-60': `${!isEmpty(whiteLabelConfig) ? whiteLabelConfig?.greyscale_60 : '#999ca2'}`,
					'--greyscale-70': `${!isEmpty(whiteLabelConfig) ? whiteLabelConfig?.greyscale_70 : '#84878b'}`,
					'--greyscale-80': `${!isEmpty(whiteLabelConfig) ? whiteLabelConfig?.greyscale_80 : '#61656a'}`,
					'--greyscale-90': `${!isEmpty(whiteLabelConfig) ? whiteLabelConfig?.greyscale_90 : '#44474d'}`,
					'--greyscale-100': `${!isEmpty(whiteLabelConfig) ? whiteLabelConfig?.greyscale_100 : '#272a30'}`
				} as React.CSSProperties
			}
		>
			{props.children}
		</div>
	);
};

export default ColorLayout;

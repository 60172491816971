import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { CrossIcon, MatfoxxIcon } from 'shared/icons/icon';
import { State } from 'shared/interface';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';

const MatfoxxChatBot: React.FC = () => {
	const [isShowIframe, setIsShowIframe] = useState(false);
	const [active, setActive] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const { language } = useSelector((state: State) => state.siteConfig);

	useEffect(() => {
		const interval = setInterval(() => {
			if (!isShowIframe) {
				setActive(true);
			}

			// Remove the class after 5 seconds
			const timeout = setTimeout(() => {
				setActive(false);
			}, 5000);

			// Clear the timeout when the interval is cleared
			return () => clearTimeout(timeout);
		}, 300000); // 5 minutes interval

		// Cleanup the interval on component unmount
		return () => clearInterval(interval);
	}, [isShowIframe]);

	return (
		<div className='matfoxx-chatbot'>
			<div className={(isShowIframe && 'overlay') || ''} onClick={() => setIsShowIframe(false)} />
			<div className={`fox-bubble ${(isShowIframe || !showMessage) && 'fox-bubble--active'}`}>
				<p className='paragraph-9'>
					<Translate text={localizationConstants.askMeSomething} />
				</p>
				<img
					src='https://cdn.prod.website-files.com/6290d82f352a440dc3577a87/66d5c4fe63e9366f95936dcb_Polygon%204.svg'
					loading='lazy'
					alt=''
					className='image-32'
				/>
			</div>
			<div
				className={`matfoxx-ico ${isShowIframe && 'matfoxx-ico--active'} ${active && 'active-matfoxx'}`}
				onMouseEnter={() => setShowMessage(true)}
				onMouseLeave={() => setShowMessage(false)}
			>
				<MatfoxxIcon onClick={() => setIsShowIframe(!isShowIframe)} height={'82px'} width='62px' />
			</div>
			<div className={`chat-window ${isShowIframe && 'chat-window-active'}`}>
				<div className='div-block-53'>
					<img
						src='https://cdn.prod.website-files.com/6290d82f352a440dc3577a87/66d5c4f99c542a7b7ac82a3e_Polygon%203.svg'
						loading='lazy'
						alt=''
						className='white-poly'
					/>
					<div className='chat-header'>
						<p className='paragraph-10'>Matfoxx chat bot</p>
						<div className='cursor-pointer' onClick={() => setIsShowIframe(false)}>
							<CrossIcon className='cross-icon' />
						</div>
					</div>
					<div className='code-embed'>
						<iframe
							src={`https://chat.matflixx.cloud/iframe/${language || 'de'}`}
							allowFullScreen
							style={{
								width: '100%',
								height: '100%',
								border: 'none'
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MatfoxxChatBot;

export const AUTH_INIT = 'AUTH_INIT';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const GET_PROFILE_INIT = 'GET_PROFILE_INIT';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';

export const CHANGE_PASSWORD_INIT = 'CHANGE_PASSWORD_INIT';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

export const UPDATE_PROFILE_INIT = 'UPDATE_PROFILE_INIT';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';

export const FORGET_PASSWORD_INIT = 'FORGET_PASSWORD_INIT';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAIL = 'FORGET_PASSWORD_FAIL';

export const RESET_PASSWORD_INIT = 'RESET_PASSWORD_INIT';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const AUTH_LOGOUT_INIT = 'AUTH_LOGOUT_INIT';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_FAIL = 'AUTH_LOGOUT_FAIL';

export const NO_ACTION = 'NO_ACTION';
export const RESET_MODAL_STATUS = 'RESET_MODAL_STATUS';
export const RESET_MESSAGE = 'RESET_MESSAGE';

export const UPDATE_SITE_CONFIG = 'UPDATE_SITE_CONFIG';

export const UPDATE_CHART_CONFIG = 'UPDATE_CHART_CONFIG';
export const GET_CHART_LAST_OBJ = 'GET_CHART_LAST_OBJ';
export const RESET_CHART_LAST_OBJ = 'RESET_CHART_LAST_OBJ';
export const UPDATE_CHART_CONFIG_FROM_DASHBOARD = 'UPDATE_CHART_CONFIG_FROM_DASHBOARD';

export const GET_SELECTED_SIDE_PANEL_NAME = 'GET_SELECTED_SIDE_PANEL_NAME';

export const UPDATE_WHITELABEL_ACTION = 'UPDATE_WHITELABEL_ACTION';
export const UPDATE_SHOW_PARTNER_SECTION = 'UPDATE_SHOW_PARTNER_SECTION';

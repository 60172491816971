import { merge } from 'd3-array';
import PropTypes from 'prop-types';
import React from 'react';

import { getAxisCanvas } from 'react-stockcharts/lib/GenericComponent';
import StackedBarSeries, { drawOnCanvasHelper, identityStack } from 'react-stockcharts/lib/series/StackedBarSeries';
import { functor, identity } from 'react-stockcharts/lib/utils';
import GenericChartComponent from './genericChartComponent';

class GroupedBarSeries extends React.Component<any> {
	static propTypes = {
		baseAt: PropTypes.oneOfType([PropTypes.number, PropTypes.func]).isRequired,
		direction: PropTypes.oneOf(['up', 'down']).isRequired,
		stroke: PropTypes.bool.isRequired,
		widthRatio: PropTypes.number.isRequired,
		opacity: PropTypes.number.isRequired,
		fill: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
		className: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
		yAccessor: PropTypes.arrayOf(PropTypes.func),
		setBarWidth: PropTypes.any
	};

	static defaultProps = {
		...StackedBarSeries.defaultProps,
		widthRatio: 0.8,
		spaceBetweenBar: 5
	};
	constructor(props: any) {
		super(props);
		this.renderSVG = this.renderSVG.bind(this);
		this.drawOnCanvas = this.drawOnCanvas.bind(this);
	}
	render() {
		return (
			<GenericChartComponent
				svgDraw={this.renderSVG}
				canvasDraw={this.drawOnCanvas}
				canvasToDraw={getAxisCanvas}
				drawOn={['pan']}
				setBarWidth={this.props.setBarWidth}
			/>
		);
	}
	drawOnCanvas = (ctx: any, moreProps: any) => {
		const { xAccessor } = moreProps;

		drawOnCanvasHelper(ctx, this.props, moreProps, xAccessor, identityStack, postProcessor);
	};
	renderSVG = (moreProps: any) => {
		const { xAccessor } = moreProps;

		return <g className='react-stockcharts-grouped-bar-series'>{svgHelper(this.props, moreProps, xAccessor, identityStack, postProcessor)}</g>;
	};
}

export const rotateXY = (array: any) =>
	array.map((each: any) => {
		return {
			...each,
			x: each.y,
			y: each.x,
			height: each.width,
			width: each.height
		};
	});

function svgHelper(props: any, moreProps: any, xAccessor: any, stackFn: any, defaultPostAction = identity, postRotateAction = rotateXY) {
	const {
		xScale,
		chartConfig: { yScale },
		plotData
	} = moreProps;
	const bars = doStuff(props, xAccessor, plotData, xScale, yScale, stackFn, postRotateAction, defaultPostAction);
	return getBarsSVG2(props, bars);
}

const doStuff = (
	props: any,
	xAccessor: any,
	plotData: any,
	xScale: any,
	yScale: any,
	stackFn: any,
	postRotateAction: any,
	defaultPostAction: any
) => {
	const { yAccessor, swapScales } = props;

	const modifiedYAccessor = swapScales ? convertToArray(props.xAccessor) : convertToArray(yAccessor);
	const modifiedXAccessor = swapScales ? yAccessor : xAccessor;

	const modifiedXScale = swapScales ? yScale : xScale;
	const modifiedYScale = swapScales ? xScale : yScale;

	const postProcessor = swapScales ? postRotateAction : defaultPostAction;

	const bars = getBars(props, modifiedXAccessor, modifiedYAccessor, modifiedXScale, modifiedYScale, plotData, stackFn, postProcessor);

	return bars;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function plotDataLengthBarWidth(props: any, moreProps: any) {
	const { widthRatio } = props;
	const { xScale } = moreProps;
	const [l, r] = xScale.range();
	const totalWidth = Math.abs(r - l);
	if (xScale.invert != null) {
		const [dl, dr] = xScale.domain();
		const width = totalWidth / Math.abs(dl - dr);
		return width * widthRatio;
	} else {
		const width = totalWidth / xScale.domain().length;
		return width * widthRatio;
	}
}

/**
 * Generates a width function that calculates the bar width based on the given time interval.
 * @param interval a d3-time time interval.
 * @return {Function} the width function.
 */

export function getBars(
	props: any,
	xAccessor: any,
	yAccessor: any,
	xScale: any,
	yScale: any,
	plotData: any,
	stack = identityStack,
	after = identity
) {
	const { baseAt, className, fill, stroke, spaceBetweenBar = 0 } = props;

	const getClassName = functor(className);
	const getFill = functor(fill);
	const getBase = functor(baseAt);

	// const widthFunctor = functor(props.width);
	const width = plotDataLengthBarWidth(props, {
		xScale,
		xAccessor,
		plotData
	});

	const barWidth = Math.round(width);

	const eachBarWidth = (barWidth - spaceBetweenBar * (yAccessor.length - 1)) / yAccessor.length;

	const offset = barWidth === 1 ? 0 : 0.5 * width;

	const ds = plotData.map((each: any) => {
		// eslint-disable-next-line prefer-const
		const d: any = {
			appearance: {},
			x: xAccessor(each)
		};
		yAccessor.forEach((eachYAccessor: any, i: any) => {
			const key = `y${i}`;
			d[key] = eachYAccessor(each);
			const appearance = {
				className: getClassName(each, i),
				stroke: stroke ? getFill(each, i) : 'none',
				fill: getFill(each, i)
			};
			d.appearance[key] = appearance;
		});
		return d;
	});

	const keys = yAccessor.map((_: any, i: any) => `y${i}`);

	const data = stack().keys(keys)(ds);

	const newData = data.map((each: any, i: any) => {
		const key = each.key;
		return each.map((d: any) => {
			// eslint-disable-next-line prefer-const
			let array: any = [d[0], d[1]];
			array.data = {
				x: d.data.x,
				i,
				appearance: d.data.appearance[key]
			};
			return array;
		});
	});

	const bars = merge(newData)
		.map((d: any) => {
			let y = yScale(d[1]);
			let h = getBase(xScale, yScale, d.data) - yScale(d[1] - d[0]);
			if (h < 0) {
				y = y + h;
				h = -h;
			}
			return {
				...d.data.appearance,
				x: Math.round(xScale(d.data.x) - width / 2),
				y: y,
				groupOffset: Math.round(offset - (d.data.i > 0 ? (eachBarWidth + spaceBetweenBar) * d.data.i : 0)),
				groupWidth: Math.round(eachBarWidth),
				offset: Math.round(offset),
				height: h,
				width: barWidth
			};
		})
		.filter((bar: any) => !isNaN(bar.y));

	return after(bars);
}

function getBarsSVG2(props: any, bars: any) {
	/* eslint-disable react/prop-types */
	const { opacity } = props;
	/* eslint-enable react/prop-types */

	return bars.map((d: any, idx: any) => {
		if (d.width <= 1) {
			return <line key={idx} className={d.className} stroke={d.fill} x1={d.x} y1={d.y} x2={d.x} y2={d.y + d.height} />;
		}
		return (
			<rect
				key={idx}
				className={d.className}
				stroke={d.stroke}
				fill={d.fill}
				x={d.x}
				y={d.y}
				width={d.width}
				fillOpacity={opacity}
				height={d.height}
				rx={4}
				ry={4}
			/>
		);
	});
}

function postProcessor(array: any) {
	return array.map((each: any) => {
		return {
			...each,
			x: each.x + each.offset - each.groupOffset,
			width: each.groupWidth
		};
	});
}

function convertToArray(item: any) {
	return Array.isArray(item) ? item : [item];
}

export default GroupedBarSeries;

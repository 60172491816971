import React from 'react';
import isEmpty from 'lodash/isEmpty';

import InventoryChart from './inventoryChart';
import ChartTableInfo from './chartTableInfo';
import ForwardCurve from './forwardCurveChart';
import ChartInformationTable from './chartInformationTable';
import { IChartData, IChartDetails, IFutureTable, ISpan } from '../interface/futureTable';

interface IProps {
	chartData: IChartData[];
	chartDetails: IChartDetails;
	futureTableData: IFutureTable;
	spanObj: ISpan;
}

const ChartAdditionalInfo: React.FC<IProps> = (props) => {
	const { chartData, futureTableData, chartDetails, spanObj } = props;
	return (
		<div className='chart-additional-info--wrapper mt-20 br--6'>
			<div className='d-flex box-sizing--border-box'>
				<ChartInformationTable className={`${!isEmpty(chartData) ? ' width-70 mr-20' : 'full-width'}`} />
				{!isEmpty(chartData) && <ChartTableInfo chartData={chartData} spanObj={spanObj} chartDetails={chartDetails} />}
			</div>
			<div className='mt-20 d-flex'>
				{!isEmpty(futureTableData.items) && chartDetails.disallowed === 0 && (
					<ForwardCurve
						chartId={`chart-container-forwardCurve-${chartDetails.symbol}-${chartDetails.boerse}`}
						futureTableData={futureTableData}
					/>
				)}
				{chartDetails.lagerbestand_boerse !== '' && chartDetails.lagerbestand_symbol !== '' && !isEmpty(chartDetails) && (
					<InventoryChart chartDetails={chartDetails} />
				)}
			</div>
		</div>
	);
};

export default ChartAdditionalInfo;

import { max, min, mean } from 'd3-array';

import { last, slidingWindow, zipper } from 'react-stockcharts/lib/utils';
import { FullStochasticOscillator as defaultOptions } from 'react-stockcharts/lib/calculator/defaultOptionsForComputation';
import { ScaleType } from 'shared/interface/enums';

export default function stoClaculator(sourcePath: string, scaleType: string): any {
	let options = defaultOptions;
	let source = (d: any) => {
		if (scaleType === ScaleType.PERCENTAGE_CHANGE) {
			return {
				open: sourcePath !== 'percentage' ? d.compare[sourcePath]?.p : d[sourcePath],
				high: sourcePath !== 'percentage' ? d.compare[sourcePath]?.p : d[sourcePath],
				low: sourcePath !== 'percentage' ? d.compare[sourcePath]?.p : d[sourcePath],
				close: sourcePath !== 'percentage' ? d.compare[sourcePath]?.p : d[sourcePath]
			};
		} else {
			return {
				open: d[sourcePath]?.e || d[sourcePath],
				high: d[sourcePath]?.h || d[sourcePath],
				low: d[sourcePath]?.t || d[sourcePath],
				close: d[sourcePath]?.l || d[sourcePath]
			};
		}
	};

	function calculator(data: any) {
		const { windowSize, kWindowSize, dWindowSize } = options;

		const high = (d: any) => source(d).high,
			low = (d: any) => source(d).low,
			close = (d: any) => source(d).close;

		const kWindow = slidingWindow()
			.windowSize(windowSize)
			.accumulator((values: any) => {
				const highestHigh: any = max(values, high);
				const lowestLow: any = min(values, low);

				const currentClose = close(last(values));
				const k = ((currentClose - lowestLow) / (highestHigh - lowestLow)) * 100;

				return k;
			});

		const kSmoothed = slidingWindow()
			.skipInitial(windowSize - 1)
			.windowSize(kWindowSize)
			.accumulator((values: any) => mean(values));

		const dWindow = slidingWindow()
			.skipInitial(windowSize - 1 + kWindowSize - 1)
			.windowSize(dWindowSize)
			.accumulator((values: any) => mean(values));

		const stoAlgorithm = zipper().combine((K: any, D: any) => ({ K, D }));

		const kData = kSmoothed(kWindow(data));
		const dData = dWindow(kData);

		const indicatorData = stoAlgorithm(kData, dData);

		return indicatorData;
	}
	calculator.undefinedLength = function () {
		const { windowSize, kWindowSize, dWindowSize } = options;
		return windowSize + kWindowSize + dWindowSize;
	};
	calculator.source = function (x: any) {
		if (!arguments.length) {
			return source;
		}
		source = x;
		return calculator;
	};
	calculator.options = function (x: any) {
		if (!arguments.length) {
			return options;
		}
		options = { ...defaultOptions, ...x };
		return calculator;
	};

	return calculator;
}

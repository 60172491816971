import { CSSProperties, Fragment, useCallback, useEffect, useState } from 'react';
import { FieldArray, Formik, FormikValues } from 'formik';
import Select, { components } from 'react-select';
import { Tooltip } from 'react-tooltip';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuid } from 'uuid';
import size from 'lodash/size';

import CustomModal from 'shared/components/modal/modal';
import { PbBody, PbCell, PbHead, PbRow, PbTable } from 'shared/components/table';
import { API_CONFIG, chartSettingColorPallet, multiReactSelectStyles } from 'shared/constants/constants';
import { chartSettingValidationSchema } from 'shared/constants/validation-schema';
import { DropDownIcon, DuplicateIcon, QuestionMarkIcon, RedDeleteIcon } from 'shared/icons/icon';
import { IDropDownOption, State } from 'shared/interface';
import HttpService from 'shared/services/http.service';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';
import { replaceHashtagFromUrl } from 'shared/util/utility';
import i18n from 'shared/util/localization';

import { ICurrencyList } from 'features/material/interface/material';
import { IChartDetails, IChartSettingsType, IToolTipMessageMapper } from '../interface/futureTable';
import AdvanceSettingsPopupFooter from './advanceSettingsPopupFooter';
import IndicatorRow from './indicatorRow';
import { indicatorRange } from 'features/futuretable/util/chartUtility';

interface IProps {
	isVisible: boolean;
	scaleType: string;
	chartSettings: IChartSettingsType[];
	updateChartViewLoading: boolean;
	chartType: string;
	setAction: (action: string) => void;
	handleOnSubmit: (values: FormikValues) => void;
	chartDetails: {
		[key: string]: IChartDetails;
	};
}

const { t } = i18n;

const ChartSettingsPopup = (props: IProps) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { setAction, handleOnSubmit, updateChartViewLoading, chartSettings, scaleType, chartType, chartDetails } = props;
	const [currencyList, setCurrencyList] = useState<IDropDownOption[]>([]);
	const [unitObj, setUnitObj] = useState({} as { [key: string]: IDropDownOption[] });
	const { language, admin } = useSelector((state: State) => state.siteConfig);

	const axisDropDown = [
		{
			label: `${t(localizationConstants.right)}`,
			value: 'right'
		},
		{
			label: `${t(localizationConstants.left)}`,
			value: 'left'
		}
	];

	const tableHeaders = [
		{
			id: 'color',
			title: `${t(localizationConstants.color)}`,
			className: 'no-border no-border-bottom pr-0 pl-0',
			isIcon: false
		},
		{
			id: 'name',
			title: `${t(localizationConstants.name)}`,
			className: 'no-border no-border-bottom width--350px',
			isIcon: false
		},
		{
			id: 'unit',
			title: `${t(localizationConstants.unit)}`,
			className: 'no-border no-border-bottom',
			isIcon: false
		},
		{
			id: 'currency',
			title: `${t(localizationConstants.currency)}`,
			className: 'no-border no-border-bottom',
			isIcon: false
		},
		{
			id: 'indicator',
			title: `${t(localizationConstants.indicator)}`,
			className: 'no-border no-border-bottom',
			isIcon: true
		},
		{
			id: 'history',
			title: `${t(localizationConstants.history)}`,
			className: 'no-border no-border-bottom',
			isIcon: true
		},
		{
			id: 'axis',
			title: `${t(localizationConstants.axis)}`,
			className: 'no-border no-border-bottom',
			isIcon: true
		},
		{
			id: 'action',
			title: '',
			className: 'no-border no-border-bottom',
			isIcon: false
		}
	];

	const IndicatorsDropdown = [
		{ label: `${t(localizationConstants.ema)}`, value: 'ema', calculatedValue: indicatorRange.ema },
		{ label: `${t(localizationConstants.sma)}`, value: 'sma', calculatedValue: indicatorRange.sma },
		{ label: `${t(localizationConstants.bb)}`, value: 'bb', calculatedValue: indicatorRange.bb },
		{ label: `${t(localizationConstants.tma)}`, value: 'tma', calculatedValue: indicatorRange.tma },
		{ label: `${t(localizationConstants.wma)}`, value: 'wma', calculatedValue: indicatorRange.wma },
		{ label: `${t(localizationConstants.atr)}`, value: 'atr', calculatedValue: indicatorRange.atr },
		{ label: `${t(localizationConstants.rsi)}`, value: 'rsi', calculatedValue: indicatorRange.rsi },
		{ label: `${t(localizationConstants.macd)}`, value: 'macd', kurz: 26, signal: 9, lang: 12 },
		{ label: `${t(localizationConstants.sto)}`, value: 'sto', datum: 3, tage: 14, k: 3 }
	];

	const handleUnitArray = useCallback(async () => {
		try {
			chartSettings.forEach((chart) => {
				if (chart.unit.value && chart.unit.value !== '') {
					HttpService.get(`${API_CONFIG.path.getUnitData}?unit=${chart.unit.value}`).then((response) => {
						setUnitObj((tempObj) => ({
							...tempObj,
							[`${chart.symbol}-${chart.boerse}`]: response.map((ele: any) => {
								return {
									label: ele.einheit,
									value: ele.einheit
								};
							})
						}));
					});
				}
			});
		} catch (error) {
			console.error(error);
		}
	}, [chartSettings]);

	const [isColorPalletOpen, setIsColorPalletOpen] = useState(-1);
	const [historyDropDown, setHistoryDropdown] = useState({} as { [key: string]: { label: string; value: string }[] });

	const getCurrencyList = () => {
		HttpService.get(API_CONFIG.path.currencyList)
			.then((response) => {
				const modifiedCurrencyList = response.map((currency: ICurrencyList) => {
					return {
						label: currency.name,
						value: currency.waehrung
					};
				});
				setCurrencyList(modifiedCurrencyList);
			})
			.catch((error) => {
				console.error('error', error);
			});
	};

	useEffect(() => {
		getCurrencyList();
	}, []);

	const handleOnColorSelection = (action: number) => {
		setIsColorPalletOpen(action);
	};

	const tooltipMessageMapper: IToolTipMessageMapper = {
		indicator: `${t(localizationConstants.indicatorTooltipMessage)}`,
		history: `${t(localizationConstants.historyTooltipMessage)}`,
		axis: `${t(localizationConstants.axisTooltipMessage)}`
	};

	const handleHistoryData = useCallback(() => {
		try {
			chartSettings.forEach((chart) => {
				HttpService.get(
					`${API_CONFIG.path.getHistoryDropDown}?symbol=${replaceHashtagFromUrl(chart.symbol)}&boerse=${replaceHashtagFromUrl(
						chart.boerse
					)}`
				).then((response) => {
					setHistoryDropdown((tempObj) => ({
						...tempObj,
						[`${chart.symbol}-${chart.boerse}`]: [
							{ label: t(localizationConstants.none), value: '' },
							...response.map((year: string) => ({
								label: year,
								value: year
							}))
						]
					}));
				});
			});
		} catch (error) {
			console.error(error);
		}
	}, [chartSettings]);

	useEffect(() => {
		if (props.isVisible) {
			handleHistoryData();
			handleUnitArray();
		}
	}, [handleHistoryData, handleUnitArray, props.isVisible]);

	return (
		<CustomModal handleClose={() => setAction('')} show={props.isVisible} className='chart-settings-popup'>
			<h2 className='font-weight--700 line-height--20px'>
				<Translate text={localizationConstants.chartSettings} />
			</h2>
			<h2 className='font-weight--700 line-height--20px font--15px'>
				<Translate text={localizationConstants.prices} />
			</h2>
			{unitObj && (
				<Formik
					initialValues={{
						scaleType,
						chartSettings: chartSettings.map((item: IChartSettingsType, index: number) => {
							const name =
								(language === 'en' &&
									chartDetails[`${item.symbol}-${item.boerse}-${index}`]?.name_eng !== '' &&
									chartDetails[`${item.symbol}-${item.boerse}-${index}`]?.name_eng) ||
								chartDetails[`${item.symbol}-${item.boerse}-${index}`]?.name;
							return {
								...item,
								name: name,
								axis: {
									label: item.axis.value === 'right' ? `${t(localizationConstants.right)}` : `${t(localizationConstants.left)}`,
									value: item.axis.value
								},
								disallowed: admin.length ? 0 : item.disallowed
							};
						})
					}}
					onSubmit={handleOnSubmit}
					validationSchema={() => chartSettingValidationSchema(t)}
					validateOnBlur={false}
					validateOnChange={false}
					enableReinitialize
				>
					{({ setFieldValue, handleSubmit, dirty, values, errors }) => {
						return (
							<>
								<div className='advance-setting-main' id={'chart-setting-popup'}>
									<PbTable className='no-border'>
										<PbHead>
											<PbRow>
												{tableHeaders.map((item) => {
													return (
														<PbCell header className={item.className}>
															<span className='position--relative'>
																{item.title}
																{item.isIcon && (
																	<>
																		<QuestionMarkIcon className='position--absolute ml-5' id={item.id} />
																		<Tooltip
																			anchorId={item.id}
																			place='bottom'
																			className='no-padding duplicate-chart-tooltip'
																			content={tooltipMessageMapper[item.id]}
																		/>
																	</>
																)}
															</span>
														</PbCell>
													);
												})}
											</PbRow>
										</PbHead>
										<PbBody>
											<FieldArray
												name='chartSettings'
												render={(arrayHelpers: any) => {
													return values.chartSettings.map((item, index) => {
														return (
															<Fragment key={index}>
																{isColorPalletOpen === index && (
																	<div
																		className='overlay z-index--1 mt-0 full-height position--absolute br--20px'
																		onClick={() => handleOnColorSelection(-1)}
																	/>
																)}
																<PbRow className='chart-details-row br--6px'>
																	<PbCell className='width--32px no-border no-padding border-bottom--17px-solid-white full-height'>
																		<div
																			className='full-width full-height cursor-pointer position--relative'
																			style={{
																				background: values.chartSettings[index].color,
																				borderRadius: '5px 0 0 5px',
																				MozBorderRadius: '5px 0 0 5px'
																			}}
																			onClick={() => handleOnColorSelection(index)}
																		>
																			<input
																				className='display--none'
																				name={`chartSettings[${index}].color`}
																				type='color'
																			/>
																			{isColorPalletOpen === index && (
																				<div className='position--absolute color-pallet--main'>
																					{chartSettingColorPallet.map((color) => {
																						return (
																							<div
																								key={color}
																								className='cursor-pointer'
																								style={{
																									background: color,
																									width: '20px',
																									height: '20px',
																									borderRadius: '6px'
																								}}
																								onClick={(event) => {
																									event.stopPropagation();
																									event.preventDefault();
																									handleOnColorSelection(-1);
																									setFieldValue(
																										`chartSettings[${index}].color`,
																										color
																									);
																								}}
																							/>
																						);
																					})}
																				</div>
																			)}
																		</div>
																	</PbCell>
																	<PbCell className='no-border bg-color--greyscale-10 border-bottom--17px-solid-white'>
																		<div className='d-flex align-items-center justify-content-space-between'>
																			{item.name}
																			<button
																				className='cursor-pointer height--30px no-border bg-transparent'
																				onClick={() => {
																					arrayHelpers.push({
																						...values.chartSettings[index],
																						id: uuid()
																					});
																				}}
																				id={`duplicate-chart-tooltip-${item.id}`}
																				disabled={
																					values.chartSettings.length > 8 ||
																					item.disallowed === 1 ||
																					(chartType === 'bar' && size(values.chartSettings) > 2)
																				}
																			>
																				<DuplicateIcon />
																				<Tooltip
																					anchorId={`duplicate-chart-tooltip-${item.id}`}
																					place='bottom'
																					className='no-padding duplicate-chart-tooltip'
																					content={`${t(
																						localizationConstants.duplicateChartTooltipMessage
																					)}`}
																					clickable
																				/>
																			</button>
																		</div>
																	</PbCell>
																	<PbCell className='no-border min-width-82px bg-color--greyscale-10 border-bottom--17px-solid-white'>
																		<Select
																			onChange={(selected: any) => {
																				setFieldValue(`chartSettings[${index}].unit`, selected);
																			}}
																			isDisabled={item.disallowed === 1}
																			className='mr-8 height--30px'
																			value={values.chartSettings[index].unit as any}
																			name={`chartSettings[${index}]..unit`}
																			options={unitObj[`${item.symbol}-${item.boerse}`]}
																			components={{ DropdownIndicator }}
																			placeholder={'Unit'}
																			isSearchable={false}
																			styles={{
																				...(multiReactSelectStyles as any),
																				...commonDropDownStyle(index),
																				dropdownIndicator: (base: CSSProperties, state: any) => ({
																					...base,
																					cursor: 'pointer',
																					transition: 'all 0.2s ease',
																					transform: state.selectProps.menuIsOpen
																						? 'rotate(180deg)'
																						: 'none',
																					color: '#081D34',
																					padding: 0,
																					paddingRight: 4,
																					paddingLeft: 4
																				})
																			}}
																			menuPortalTarget={document.body}
																			hideSelectedOptions={false}
																			isClearable={false}
																		/>
																	</PbCell>
																	<PbCell className='no-border min-width-82px bg-color--greyscale-10 border-bottom--17px-solid-white'>
																		<Select
																			onChange={(selected: any) => {
																				setFieldValue(`chartSettings[${index}].currency`, selected);
																			}}
																			value={values.chartSettings[index].currency}
																			isDisabled={
																				item.disallowed === 1 ||
																				chartDetails[`${item.symbol}-${item.boerse}-${index}`]
																					?.waehrung_ignore === 'ignore'
																			}
																			className='mr-8 height--30px'
																			name={`chartSettings[${index}].currency`}
																			options={
																				item.currency.value === '' || item.currency.value === null
																					? []
																					: currencyList
																			}
																			components={{ DropdownIndicator }}
																			placeholder='Currency'
																			isSearchable={false}
																			styles={{
																				...(multiReactSelectStyles as any),
																				...commonDropDownStyle(index),
																				dropdownIndicator: (base: CSSProperties, state: any) => ({
																					...base,
																					cursor: 'pointer',
																					transition: 'all 0.2s ease',
																					transform: state.selectProps.menuIsOpen
																						? 'rotate(180deg)'
																						: 'none',
																					color: '#081D34',
																					padding: 0,
																					paddingRight: 4,
																					paddingLeft: 4
																				}),
																				menu: (base: CSSProperties) => ({
																					...base,
																					zIndex: 3,
																					display: 'block',
																					marginTop: 4,
																					border: '1px solid #D4D6D9',
																					borderRadius: 4,
																					width: '200px'
																				})
																			}}
																			menuPortalTarget={document.body}
																			hideSelectedOptions={false}
																			isClearable={false}
																		/>
																	</PbCell>
																	<PbCell className='no-border min-width-120px bg-color--greyscale-10 border-bottom--17px-solid-white'>
																		<Select
																			isMulti
																			onChange={(selected: any) => {
																				setFieldValue(
																					`chartSettings[${index}].indicators`,
																					setIndicatorValue(selected, item.symbol, item.boerse)
																				);
																			}}
																			className='mr-8 height--30px'
																			name={`chartSettings[${index}].indicators`}
																			isDisabled={item.disallowed === 1}
																			components={{ DropdownIndicator }}
																			options={IndicatorsDropdown}
																			placeholder={t(localizationConstants.indicator)}
																			isSearchable={false}
																			value={values.chartSettings[index].indicators as any}
																			styles={{
																				...(multiReactSelectStyles as any),
																				...commonDropDownStyle(index),
																				multiValue: () => ({
																					display: 'none' // Hide selected options in the input field
																				}),
																				dropdownIndicator: (base: CSSProperties, state: any) => ({
																					...base,
																					cursor: 'pointer',
																					transition: 'all 0.2s ease',
																					transform: state.selectProps.menuIsOpen
																						? 'rotate(180deg)'
																						: 'none',
																					color: '#081D34',
																					padding: 0,
																					paddingRight: 4,
																					paddingLeft: 4
																				}),
																				menu: (base: CSSProperties) => ({
																					...base,
																					zIndex: 3,
																					display: 'block',
																					marginTop: 4,
																					border: '1px solid #D4D6D9',
																					borderRadius: 4,
																					width: '300px'
																				})
																			}}
																			menuPortalTarget={document.body}
																			hideSelectedOptions={false}
																			isClearable={false}
																		/>
																	</PbCell>
																	<PbCell className='no-border min-width-82px bg-color--greyscale-10 border-bottom--17px-solid-white'>
																		<Select
																			onChange={(selected: any) => {
																				setFieldValue(`chartSettings[${index}].history`, selected);
																			}}
																			className='mr-8 height--30px'
																			name={`chartSettings[${index}].history`}
																			options={historyDropDown[`${item.symbol}-${item.boerse}`]}
																			isDisabled={item.disallowed === 1}
																			components={{ DropdownIndicator }}
																			placeholder='History'
																			value={item.history}
																			isSearchable={false}
																			styles={{
																				...(multiReactSelectStyles as any),
																				...commonDropDownStyle(index),
																				dropdownIndicator: (base: CSSProperties, state: any) => ({
																					...base,
																					cursor: 'pointer',
																					transition: 'all 0.2s ease',
																					transform: state.selectProps.menuIsOpen
																						? 'rotate(180deg)'
																						: 'none',
																					color: '#081D34',
																					padding: 0,
																					paddingRight: 4,
																					paddingLeft: 4
																				})
																			}}
																			menuPortalTarget={document.body}
																			hideSelectedOptions={true}
																			isClearable={false}
																		/>
																	</PbCell>
																	<PbCell className='no-border min-width-82px bg-color--greyscale-10 border-bottom--17px-solid-white'>
																		<Select
																			onChange={(selected: any) => {
																				setFieldValue(`chartSettings[${index}].axis`, selected);
																			}}
																			className='mr-8 height--30px'
																			name={`chartSettings[${index}].axis`}
																			components={{ DropdownIndicator }}
																			value={item.axis}
																			isDisabled={item.disallowed === 1}
																			options={axisDropDown}
																			placeholder='Achse'
																			isSearchable={false}
																			styles={{
																				...(multiReactSelectStyles as any),
																				...commonDropDownStyle(index),
																				dropdownIndicator: (base: CSSProperties, state: any) => ({
																					...base,
																					cursor: 'pointer',
																					transition: 'all 0.2s ease',
																					transform: state.selectProps.menuIsOpen
																						? 'rotate(180deg)'
																						: 'none',
																					color: '#081D34',
																					padding: 0,
																					paddingRight: 4,
																					paddingLeft: 4
																				})
																			}}
																			menuPortalTarget={document.body}
																			hideSelectedOptions={true}
																			isClearable={false}
																		/>
																	</PbCell>
																	<PbCell
																		className='text-align-center no-border bg-color--greyscale-10 border-bottom--17px-solid-white'
																		style={{
																			borderRadius: '0 6px 6px 0'
																		}}
																	>
																		<button
																			className='delete-icon--container d-flex cursor-pointer'
																			disabled={size(values.chartSettings) < 2}
																			onClick={() => arrayHelpers.remove(index)}
																		>
																			<RedDeleteIcon />
																		</button>
																	</PbCell>
																</PbRow>
																{!isEmpty(values.chartSettings[index].indicators) && (
																	<IndicatorRow
																		index={index}
																		values={values}
																		setFieldValue={setFieldValue}
																		errors={errors}
																	/>
																)}
															</Fragment>
														);
													});
												}}
											/>
										</PbBody>
									</PbTable>
								</div>
								<div className='empty-div' />
								<AdvanceSettingsPopupFooter
									dirty={dirty}
									handleSubmit={handleSubmit}
									setAction={setAction}
									setFieldValue={setFieldValue}
									values={values}
									updateChartViewLoading={updateChartViewLoading}
								/>
							</>
						);
					}}
				</Formik>
			)}
		</CustomModal>
	);
};

export default ChartSettingsPopup;

const setIndicatorValue = (selected: any, symbol: string, boerse: string) => {
	const updatedValue = selected.map((value: any) => {
		const lastValue = {
			...value,
			symbol: symbol,
			boerse: boerse
		};
		return lastValue;
	});
	return updatedValue;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const commonDropDownStyle = (index: number) => {
	return {
		control: () => ({
			display: 'flex',
			height: '100%',
			border: '1px solid #EDEEEF',
			padding: '0px !important',
			borderRadius: '4px',
			backgroundColor: '#ffffff !important'
		}),
		option: (base: CSSProperties, state: any) => ({
			...base,
			color: state.isSelected && '#14304F',
			padding: 8,
			flexDirection: 'row-reverse',
			justifyContent: 'space-between',
			backgroundColor: 'white',
			cursor: 'pointer',
			accentColor: ' #002c50 !important',
			':active': {
				backgroundColor: 'transparent',
				color: '#14304F'
			}
		}),
		menu: (base: CSSProperties) => ({
			...base,
			zIndex: 3,
			display: 'block',
			marginTop: 4,
			border: '1px solid #D4D6D9',
			borderRadius: 4
		}),
		menuList: (base: CSSProperties) => ({
			...base,
			padding: 0,
			backgroundColor: '#ffffff !important',
			borderRadius: 4,
			width: '100%',
			maxHeight: '188px',
			'&::-webkit-scrollbar': {
				width: '6px' // Width of the scrollbar
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: '#081d34 !important', // Color of the scrollbar thumb
				borderRadius: '4px', // Border radius of the scrollbar thumb
				border: '6px solid #081d34'
			}
		}),
		menuPortal: (base: CSSProperties) => ({
			...base,
			zIndex: 9999
		})
	};
};

const DropdownIndicator: React.FC<any> = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<DropDownIcon
				className='dropdown-down--icon
					 cursor-pointer'
			/>
		</components.DropdownIndicator>
	);
};

import React, { useCallback, useEffect, useState } from 'react';

import Button from 'shared/components/form/button';
import CustomModal from 'shared/components/modal/modal';
import { ChatIcon, RobotIcon, SettingsToolsIcons } from 'shared/icons/icon';
import { API_CONFIG } from 'shared/constants/constants';
import HttpService from 'shared/services/http.service';
import localizationConstants from 'shared/util/translation/constants';
import { OnboardingActions } from 'shared/interface/enums';
import i18n from 'shared/util/localization';

import AnalyticalDashboard from 'assets/images/analytical-dashboard.png';
import BalancedDashboard from 'assets/images/dashboard-image-1.png';
import GraphicalDashboard from 'assets/images/graphical-dashboard.png';
import '../style/dashboard.scss';
import { Translate } from 'shared/util/translation/translate';
import { t } from 'i18next';

interface CardMapper {
	header: string;
	data: {
		icon: JSX.Element | string;
		message: string;
		type: string;
	}[];
}

interface Props {
	action: string;
	handleModalState: (action: string) => void;
	getDashboardData: () => void;
	toggleLoginFlag: () => void;
}

const SelectProcedurePopup: React.FC<Props> = (props) => {
	const [data, setData] = useState<CardMapper>(firstStepCardMapper);
	const [selectedCard, setSelectedCard] = useState<string>('');
	const [loading, setLoading] = useState(false);
	const { action, handleModalState } = props;

	useEffect(() => {
		if (action === OnboardingActions.FIRST_STEP) {
			setData(firstStepCardMapper);
		} else if (action === OnboardingActions.SECOND_STEP) {
			setSelectedCard('balanced');
			setData(secondStepCardMapper);
		}
	}, [action]);

	const handleSelectCard = useCallback(
		(card: string) => {
			setSelectedCard(card);
		},
		[selectedCard]
	);

	const generateDashboardAutomatic = () => {
		setLoading(true);
		HttpService.put(API_CONFIG.path.automaticDashboard)
			.then(() => {
				props.getDashboardData();
				setLoading(false);
			})
			.catch(() => setLoading(false));
	};

	const handleSubmitPopup = useCallback(() => {
		if (selectedCard === 'assistance') {
			setLoading(true);
			HttpService.put(API_CONFIG.path.updateService)
				.then(() => {
					props.handleModalState('');
					props.getDashboardData();
					props.toggleLoginFlag();
					setLoading(false);
				})
				.catch(() => setLoading(false));
		} else if (selectedCard === 'automatic') {
			setLoading(true);
			generateDashboardAutomatic();
			props.toggleLoginFlag();
			setLoading(false);
			props.handleModalState('');
		} else if (selectedCard === 'balanced') {
			setLoading(true);
			props.getDashboardData();
			setLoading(false);
			props.handleModalState('addWidget');
		} else if (selectedCard === 'custom') {
			handleModalState(OnboardingActions.SECOND_STEP);
		}
	}, [selectedCard]);

	const handleBackPopup = useCallback(() => {
		handleModalState(OnboardingActions.FIRST_STEP);
		setSelectedCard('custom');
	}, []);

	return (
		<CustomModal show className={`${(action === OnboardingActions.SECOND_STEP && 'dashboard-model') || 'procedure-modal'}  `}>
			<div className='procedure__wrapper full-width'>
				<h1 className='font--24px line-height--30px font-weight--500 mb-24'>{data.header}</h1>
				<div className='procedure-card__wrapper d-flex justify-content-center align-items-center'>
					{data.data.map((card, index) => {
						return (
							<div
								className={`card__wrapper ${(selectedCard === card.type && 'active') || ''}`}
								key={index}
								onClick={() => handleSelectCard(card.type)}
							>
								{action === OnboardingActions.FIRST_STEP && <div className='card-icon__wrapper'>{card.icon}</div>}
								<h1 className='font--16px line-height--22px font-weight--500 mt-0 mb-16'>{card.message}</h1>
								{action === OnboardingActions.SECOND_STEP && (
									<div className='dashboard-img__wrapper'>
										<img src={card.icon as string} />
									</div>
								)}
							</div>
						);
					})}
				</div>
				<div className='save-btn__wrapper mt-24 full-width d-flex justify-content-end align-items-center'>
					{action === OnboardingActions.SECOND_STEP && (
						<Button loading={loading} btnType='secondary' className='button-size--small button-border--blue' onClick={handleBackPopup}>
							<Translate text={localizationConstants.back} />
						</Button>
					)}
					<Button
						loading={loading}
						disabled={!selectedCard || selectedCard === 'graphical' || selectedCard === 'analytical'}
						btnType='primary'
						className='button-size--large ml-10'
						onClick={handleSubmitPopup}
					>
						<Translate text={localizationConstants.next} />
					</Button>
				</div>
			</div>
		</CustomModal>
	);
};

const firstStepCardMapper = {
	header: `${t(localizationConstants.howWouldYouLikeToProceed)}`,
	data: [
		{
			icon: <RobotIcon className='robot-icon' />,
			message: `${t(localizationConstants.generateDashBoardAuomatic)}`,
			type: 'automatic'
		},
		{
			icon: <ChatIcon className='robot-icon' />,
			message: `${t(localizationConstants.customerServiceAssistence)}`,
			type: 'assistance'
		},
		{
			icon: <SettingsToolsIcons className='robot-icon' />,
			message: `${t(localizationConstants.createYourOwnDashboard)}`,
			type: 'custom'
		}
	]
};

const secondStepCardMapper: CardMapper = {
	header: i18n.t(localizationConstants.onboardSecondTitle),
	data: [
		{
			icon: BalancedDashboard,
			message: `${t(localizationConstants.balancedDashboard)}`,
			type: 'balanced'
		},
		{
			icon: GraphicalDashboard,
			message: `${t(localizationConstants.graphicalDashboard)}`,
			type: 'graphical'
		},
		{
			icon: AnalyticalDashboard,
			message: `${t(localizationConstants.analyticalDashboard)}`,
			type: 'analytical'
		}
	]
};

export default SelectProcedurePopup;

import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import Search from 'shared/components/search/search';
import { notify } from 'shared/components/notification/notification';
import { DottedLoader } from 'shared/components/spinner/spinner';
import { LockIcon, UnlockIcon } from 'shared/icons/icon';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';
import { useWhitelabelConfigStatus } from 'shared/hooks/useWhitelabelConfigStatus';
import { API_CONFIG } from 'shared/constants/constants';
import httpService from 'shared/services/http.service';
import { debounce } from 'shared/util/utility';
import { State } from 'shared/interface';

import { ISearchMaterialData } from '../interface/futureTable';

interface IProps {
	disabledSearch: boolean;
	getComparativeChartData: (comparativeData: ISearchMaterialData) => void;
}

const ComparisionSearchBar: React.FC<IProps> = (props) => {
	const { disabledSearch, getComparativeChartData } = props;
	const { t } = useTranslation();
	const { language, admin } = useSelector((state: State) => state.siteConfig);
	const [loading, setLoading] = useState(false);
	const [isSearchPopup, setIsSearchPopup] = useState(false);
	const [searchData, setSearchedData] = useState([] as ISearchMaterialData[]);
	const { whitelabelId, material_status } = useWhitelabelConfigStatus();

	const handleSearchComparativeChart = useCallback(
		(word: string) => {
			if (word !== '') {
				setIsSearchPopup(true);
				setLoading(true);
				let params: any = {
					word: word
				};
				if (material_status === 'active' || material_status === 'active+original') {
					params = {
						...params,
						whitelabel_id: whitelabelId
					};
				}
				httpService
					.get(API_CONFIG.path.materialSearch, {
						...params
					})
					.then((response) => {
						setSearchedData(response.material);
						setLoading(false);
					})
					.catch((err) => {
						setLoading(false);
						console.error(err);
					});
			} else {
				setLoading(false);
				setIsSearchPopup(false);
			}
		},
		[material_status, whitelabelId]
	);

	return (
		<div className='navbar-header width-40 position--relative'>
			<Search
				handleOnSearch={debounce((word: string) => {
					handleSearchComparativeChart(word);
				})}
				disabledSearch={disabledSearch}
				placeHolder={`${t(localizationConstants.comparativeSearchPlaceholder)}`}
				className='search-input'
			/>
			{isSearchPopup && (
				<div className='search-result-wrapper position--absolute'>
					<div className='search-result'>
						<ul className='no-margin list'>
							{loading ? (
								<div className='d-flex justify-content-center align-itmes-center'>
									<DottedLoader />
								</div>
							) : (
								<>
									{!isEmpty(searchData) && !loading ? (
										<>
											{searchData.map((data) => {
												return (
													<li
														key={data.id}
														className='item cursor-pointer'
														onClick={() => {
															if (!admin.length && data?.disallowed === 1) {
																notify(t(localizationConstants.dataNotIncludedInSubscription), 'error');
															} else {
																getComparativeChartData(data);
																setIsSearchPopup(false);
															}
														}}
													>
														<div className='d-flex align-items-center'>
															{!admin.length && (
																<span className='mr-20'>
																	{(data?.disallowed === 1 && <LockIcon />) || <UnlockIcon />}
																</span>
															)}
															{language === 'en' && data.name_eng !== '' ? data.name_eng : data.name_deu}
														</div>
													</li>
												);
											})}
										</>
									) : (
										<div className='d-flex flex-column text-align-center padding--20'>
											<p>
												<Translate text={localizationConstants.noMaterialFound} />
											</p>
										</div>
									)}
								</>
							)}
						</ul>
					</div>
				</div>
			)}
		</div>
	);
};

export default ComparisionSearchBar;

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import Button from 'shared/components/form/button';
import { IMAGE_EXPORT_BUTTON_ARRAY } from 'shared/constants/constants';
import { State } from 'shared/interface';

import { IChartDetails } from '../interface/futureTable';

interface IProps {
	chartDetails: IChartDetails;
	chartType: string;
	id: string;
}

const ExportChartImages: React.FC<IProps> = (props) => {
	const { chartDetails, chartType, id } = props;
	const { language } = useSelector((state: State) => state.siteConfig);

	const handleDownloadButton = useCallback(
		(e: any, type: string) => {
			e.preventDefault();
			const chartDiv = document.getElementById(id);
			if (chartType !== 'bar') {
				chartDiv?.querySelector('.react-stockcharts-zoom-button')?.classList.add('hide');
			}
			setTimeout(() => {
				if (chartDiv) {
					html2canvas(chartDiv).then(function (canvas) {
						const downloadLink = document.createElement('a');
						const imageData = canvas.toDataURL();
						downloadLink.href = imageData;
						if (type === 'pdf') {
							const pdf = new jsPDF();
							const imgWidth = 210; // A4 size
							const imgHeight = (canvas.height * imgWidth) / canvas.width;
							pdf.addImage(imageData, 'JPEG', 0, 0, imgWidth, imgHeight);
							pdf.save(`${(language === 'en' && chartDetails.name_eng !== '' && chartDetails.name_eng) || chartDetails.name}.${type}`);
						} else if (type === 'svg') {
							downloadSvgChart();
						} else {
							downloadLink.download = `${
								(language === 'en' && chartDetails.name_eng !== '' && chartDetails.name_eng) || chartDetails.name
							}.${type}`;
							downloadLink.click();
						}
					});
				}
				chartDiv?.querySelector('.react-stockcharts-zoom-button')?.classList.remove('hide');
			}, 100);
		},
		[chartDetails, chartType, language]
	);

	const downloadSvgChart = useCallback(() => {
		const chart = document.getElementsByClassName('react-stockchart-main')[1];
		const svgString = new XMLSerializer().serializeToString(chart);
		const blob = new Blob([svgString], { type: 'image/svg+xml' });
		const url = URL.createObjectURL(blob);

		const a = document.createElement('a');
		a.href = url;
		a.download = `${(language === 'en' && chartDetails.name_eng !== '' && chartDetails.name_eng) || chartDetails.name}.svg`;
		a.click();
	}, [chartDetails, language]);

	return (
		<>
			{IMAGE_EXPORT_BUTTON_ARRAY.map((item: string) => {
				return (
					<Button
						key={item}
						btnType='primary'
						className='mr-10 padding-left-right--16px font-weight--500 line-height--20px'
						onClick={(e: any) => handleDownloadButton(e, item.toLowerCase())}
					>
						{item}
					</Button>
				);
			})}
		</>
	);
};

export default ExportChartImages;

import React, { PropsWithChildren } from 'react';

const PbHead: React.FC<
	{
		dataTestId?: string;
		className?: string;
	} & PropsWithChildren
> = (props) => (
	<thead data-testid={`${props.dataTestId || 'list-column-header'}`} className={props.className}>
		{props.children}
	</thead>
);

export default PbHead;

import React from 'react';
import { CandlestickSeries } from 'react-stockcharts/lib/series';

import moment from 'moment';
import { IChartDetails, IChartSettingsType } from '../interface/futureTable';
import GroupedBarSeries from '../util/GroupedBarSeries';
import RenderIndicatorsOnchart from './renderIndicatorsOnchart';
import { RenderChart } from './stockChart';

interface IProps {
	chartType: string;
	chartDetails: IChartDetails;
	chartSettings: IChartSettingsType[];
	ele: IChartSettingsType;
	getBarChartYAccsesor: any;
	cardIndex: number;
	materialIndex?: number;
	setBarWidth?: (width: { [key: string]: number }) => void;
}

const CommonChart: React.FC<IProps> = (props) => {
	const { chartSettings, getBarChartYAccsesor, chartType, ele, chartDetails, cardIndex, materialIndex, setBarWidth } = props;
	const index = chartSettings.findIndex((chart) => `${chart.id}` === `${ele.id}`);

	return (
		<>
			{chartType === 'bar' && (
				<GroupedBarSeries
					id={chartDetails.symbol}
					yAccessor={getBarChartYAccsesor}
					fill={(d: any, i: any) => {
						return `url(#bar${chartSettings[i].id}-${cardIndex})`;
					}}
					stroke={false}
					setBarWidth={setBarWidth}
				/>
			)}
			{chartType === 'candle' && (
				<CandlestickSeries
					yAccessor={(d: any) => {
						return {
							close: d[`cv${ele.symbol}-${ele.boerse}-${materialIndex || index}`]?.l,
							open: d[`cv${ele.symbol}-${ele.boerse}-${materialIndex || index}`]?.e,
							high: d[`cv${ele.symbol}-${ele.boerse}-${materialIndex || index}`]?.h,
							low: d[`cv${ele.symbol}-${ele.boerse}-${materialIndex || index}`]?.t
						};
					}}
					fill={(d: any) => (d.close < d.open ? '#F53030' : '#40AD1A')}
					wickStroke={(d: any) => (d.close < d.open ? '#F53030' : '#40AD1A')}
					stroke={(d: any) => (d.close < d.open ? '#F53030' : '#40AD1A')}
					widthRatio={0.6}
					opacity={1}
				/>
			)}
			{!chartDetails.isIntraday && (
				<>
					{chartType !== 'bar' && (
						<RenderChart
							type={chartType}
							yAccessor={(d: any) => {
								const momentDate = moment(d.date);
								const dateObj = momentDate.toDate(); // Converts to JavaScript Date object
								if (dateObj <= new Date() || chartSettings.length > 1) {
									return d[`cv${ele.symbol}-${ele.boerse}-${materialIndex || index}`]?.l;
								}
							}}
							fill={`url(#MyGradient${ele.symbol}-${ele.boerse}${ele.id}-${cardIndex})`}
							stroke={ele.color}
						/>
					)}
					{chartType !== 'bar' && chartSettings.length === 1 && (
						<RenderChart
							type={chartType}
							yAccessor={(d: any) => {
								const currentDate = new Date();
								currentDate.setDate(currentDate.getDate() - 1);
								const momentDate = moment(d.date);
								const dateObj = momentDate.toDate(); // Converts to JavaScript Date object
								if (dateObj >= currentDate) {
									return d[`cv${ele.symbol}-${ele.boerse}-${materialIndex || index}`]?.l;
								}
							}}
							fill='url(#MyGradientForecast)' // Color for the first area
							stroke={'#FF9100'}
						/>
					)}
				</>
			)}
			{chartDetails.isIntraday && chartType !== 'bar' && (
				<RenderChart
					type={chartType}
					yAccessor={(d: any) => d[`cv${ele.symbol}-${ele.boerse}-${materialIndex || index}`]?.l}
					fill={`url(#MyGradient${ele.symbol}-${ele.boerse}${ele.id}-${cardIndex})`}
					stroke={ele.color}
				/>
			)}
			{ele.indicators.map((indi, index) => {
				return (
					<RenderIndicatorsOnchart
						key={`${ele.id}-${indi.value}`}
						index={index}
						type={indi.value}
						color={ele.color}
						yAccessor={(d: any) => {
							return d[`${indi.value}-${ele.symbol}-${ele.boerse}-${ele.id}`];
						}}
					/>
				);
			})}
		</>
	);
};

export default CommonChart;

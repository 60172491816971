import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/constants';
import { Loader } from 'shared/components/spinner/spinner';
import { Translate } from 'shared/util/translation/translate';
import localizationConstants from 'shared/util/translation/constants';
import { useWhitelabelConfigStatus } from 'shared/hooks/useWhitelabelConfigStatus';

import { IPDFBoxes } from '../interface/pdfModule';
import AllPdfModuleCards from './allpdfModuleCards';

const PdfModuleContainer = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [pdfModuleList, setPdfModuleList] = useState<IPDFBoxes>({ boxes: [], noGroup: [] });
	const { pdfModule_status } = useWhitelabelConfigStatus();
	const isActivePlusOriginal = pdfModule_status === 'active+original';

	const fetchPdfModuleList = () => {
		setIsLoading(true);
		httpService
			.get(API_CONFIG.path.fetchPdfModuleList)
			.then((response) => {
				setIsLoading(false);
				setPdfModuleList(response);
			})
			.catch((err) => {
				setIsLoading(false);
				console.error('error', err);
			});
	};

	useEffect(() => {
		fetchPdfModuleList();
	}, []);
	const navigate = useNavigate();

	const handleOnClick = useCallback(
		(groupId: number, type: string) => {
			if (type === 'box') {
				navigate(`/pdfModule/list/${groupId}`);
			} else {
				navigate(`/pdfModule/data/${groupId}`);
			}
		},
		[navigate]
	);
	return (
		<>
			{isActivePlusOriginal && (
				<h1 className='font--24px font-weight--500 line-height--30px'>
					<Translate text={localizationConstants.matflixxPdfModule} />
				</h1>
			)}
			{isLoading && <Loader />}
			{!isLoading && (
				<div className='pdfModule-list'>
					<div
						className={`d-flex flex-wrap text-align-center justify-content-center ${
							isActivePlusOriginal ? 'mb-40' : 'mb-68'
						} pdfs-box--wrapper`}
					>
						<AllPdfModuleCards
							pdfModuleList={pdfModuleList?.boxes || []}
							className={isActivePlusOriginal ? 'pdfs-small--box' : 'pdfs--box'}
							type='box'
							handleOnClick={handleOnClick}
						/>
					</div>
					<div className={`d-flex flex-wrap text-align-center justify-content-center pdfs-box--wrapper`}>
						<AllPdfModuleCards
							pdfModuleList={pdfModuleList?.noGroup || []}
							className={isActivePlusOriginal ? 'pdfs-mini-box' : 'pdfs-small--box'}
							type='group'
							handleOnClick={handleOnClick}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default PdfModuleContainer;

import React, { useCallback, useState } from 'react';
import { Formik, FormikValues } from 'formik';
import { Translate } from 'shared/util/translation/translate';
import CustomModal from 'shared/components/modal/modal';
import localizationConstants from 'shared/util/translation/constants';
import { Input } from 'shared/components/form/inputTypes';
import i18n from 'shared/util/localization';
import Button from 'shared/components/form/button';
import { addInfoValidationSchema } from 'shared/constants/validation-schema';
import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/constants';

interface IAddInfoPopUp {
	isAddInfo: boolean;
	handleClosePopup: () => void;
	submitParams: {
		boerse: string;
		basis_symbol: string | null;
		symbol: string;
	};
	initialValues: { info: string; priority: boolean };
	setTooltipInfo: (info: string) => void;
	setPriority: (action: boolean) => void;
}

const AddInfoPopUp: React.FC<IAddInfoPopUp> = (props) => {
	const { isAddInfo, handleClosePopup, submitParams, initialValues, setTooltipInfo, setPriority } = props;
	const [loading, setLoading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const { t } = i18n;
	const handleOnSubmit = useCallback(
		(values: FormikValues, action: string) => {
			let params: { [key: string]: string | boolean | null } = {
				boerse: submitParams.boerse,
				symbol: submitParams.symbol,
				basis_symbol: submitParams.basis_symbol !== 'null' ? submitParams.basis_symbol : null
			};
			if (action === 'add') {
				setLoading(true);
				params = {
					...params,
					...values
				};
			} else if (action === 'delete') {
				setDeleteLoading(true);
				params = {
					...params,
					info: '',
					priority: false
				};
			}
			httpService
				.put(API_CONFIG.path.addChartInformation, params)
				.then(() => {
					setLoading(false);
					setDeleteLoading(false);
					setPriority(action === 'add' ? values.priority : false);
					setTooltipInfo(action === 'add' ? values.info.replace(/\s{2,}/g, ' ').trim() : '');
					handleClosePopup();
				})
				.catch(() => {
					setLoading(false);
					setDeleteLoading(false);
				});
		},
		[handleClosePopup, submitParams]
	);

	return (
		<CustomModal className='add-info-popup' show={isAddInfo} handleClose={handleClosePopup}>
			<h2 className='modal-title'>
				{props.initialValues.info !== '' ? (
					<Translate text={localizationConstants.editInformation} />
				) : (
					<Translate text={localizationConstants.addInformation} />
				)}
			</h2>
			<Formik
				initialValues={initialValues}
				onSubmit={(values: FormikValues) => handleOnSubmit(values, 'add')}
				validationSchema={addInfoValidationSchema}
			>
				{({ setFieldValue, dirty, handleSubmit, values }) => {
					return (
						<form onSubmit={handleSubmit}>
							<Input
								className={`full-width ${values.info.length === Number(250) && 'warning-input'}`}
								name='info'
								setFieldValue={setFieldValue}
								showLabels
								onChange={({ target }) => setFieldValue('info', target.value.trimStart())}
								config={{ name: 'info', type: 'textarea' }}
								placeholder={`${t(localizationConstants.enterNote)}`}
								type='textarea'
								maxChar={1000}
								maxLength={1000}
								rows='10'
							/>
							<div className='d-flex flex-column mt-24'>
								<h3>
									<Translate text={localizationConstants.choosePriority} />
								</h3>
								<div className='d-flex align-items-center mb-16'>
									<Input
										type='radio'
										name='priority'
										value={false}
										showLabels
										setFieldValue={setFieldValue}
										config={{ name: 'priority', type: 'radio', label: `${t(localizationConstants.normal)}` }}
									/>
									<Input
										type='radio'
										name='priority'
										value={true}
										showLabels
										setFieldValue={setFieldValue}
										config={{ name: 'priority', type: 'radio', label: `${t(localizationConstants.critical)}` }}
									/>
								</div>
							</div>
							<div className='d-flex'>
								{props.initialValues.info !== '' && (
									<Button
										btnType='danger'
										type='button'
										onClick={() => handleOnSubmit(values, 'delete')}
										className='button-icon--large d-flex align-items-center justify-content-center mr-8 full-width mt-16'
										loading={deleteLoading}
										disabled={loading}
									>
										<Translate text={localizationConstants.delete} />
									</Button>
								)}
								<Button
									btnType='primary'
									type='submit'
									className='button-icon--large d-flex align-items-center justify-content-center mr-8 full-width mt-16'
									disabled={!dirty}
									loading={loading}
								>
									<Translate text={localizationConstants.save} />
								</Button>
							</div>
						</form>
					);
				}}
			</Formik>
		</CustomModal>
	);
};

export default AddInfoPopUp;

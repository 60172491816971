import i18n from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';

const { t } = i18n;
const errorMessages = {
	required: (field: string) => `${field} ${t(localizationConstants.isRequired)}`,
	select: (field: string) => `please select ${field}`,
	exactLength: (field: string, length: number) => `${field} must be having exact ${length} characters!`,
	minLength: (field: string, length: number) => `${field} must be longer than ${length} characters!`,
	minLengthArray: (field: string, length: number) => `${field} must contain minimum ${length} items!`,
	maxLength: (field: string, length: number) => `${field} must be shorter than ${length} characters!`,
	maxLengthArray: (field: string, length: number) => `${field} must contain maximum ${length} items!`,
	minValue: (field: string, value: number) => `${field} must be greater than or equal to ${value}!`,
	maxValue: (field: string, value: number) => `${field} must be less than or equal to ${value}!`,
	validType: (field: string, type: string) => `${field} must be a valid ${type}!`,
	passwordMatchValidate: () => `${t(localizationConstants.passwordMatch)}`,
	customPasswordMessage: () => 'Password must contain 1 Small Character, 1 Upper character, 1 special character and min length should be 8.',
	lowerCase: () => 'Key should be in lowercase, uppercase, number and underscore'
};

const onConfirmMessages = {
	confirmDelete: (field: string) => `Are you sure, you want to remove ${field}?`,
	confirmLogout: `${t(localizationConstants.confirmLogout)}`
};

export { errorMessages, onConfirmMessages };

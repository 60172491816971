import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { ButtonToolbar, DropdownButton, MenuItem } from 'react-bootstrap';

import { DeleteIcon, EditIcon, PlusIcon, YellowDots } from 'shared/icons/icon';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';

import { IMyLinks, IPartnersLink } from 'features/dashboard/interface/dashboard';

interface Props {
	action: string;
	partnersLink: IPartnersLink[];
	myLink: IMyLinks[];
	deleteData: (id: string, isDelete: boolean) => void;
	handleModalState: (action: string, partnersLink: IMyLinks) => void;
}
const MyShortcutSideNav: React.FC<Props> = (props) => {
	const [isOpen, setIsOpen] = useState('');

	return (
		<>
			<div className={`${(props.action === 'openShortcut' && 'sidenav-active') || ''} sidenav`}>
				<div>
					<div className='sidenav-header d-flex align-items-center justify-content-space-between'>
						<h2>
							<Translate text={localizationConstants.myShortcut} />
						</h2>
						<div className='btn-wrapper'>
							<div className='plus-icon' onClick={() => props.handleModalState('addShortcut', {} as IMyLinks)}>
								<PlusIcon />
							</div>
						</div>
					</div>
					{!isEmpty(props.myLink) &&
						props.myLink.map((data: IMyLinks, index: number) => (
							<div className='sidenav-main' key={index}>
								<div className='shortcut-main'>
									<a href={data.ziel_url} target='_blank' rel='noreferrer'>
										<div className='image-container'>
											<img src={data.bild_url} className='shortcut-image' alt='shortcut' />
											<div className='shortcut-details'>
												<div className='shortcut-name'>
													<h3 className='truncate--lines-1'>{data.link_name}</h3>
													<ButtonToolbar>
														<DropdownButton
															title={<YellowDots className='pointer' />}
															onToggle={(isOpen, event) => {
																event.stopPropagation();
																event.preventDefault();
																setIsOpen(data.element_id);
															}}
															id={`dropdown-basic-${index}`}
															noCaret
															className='dropdown-btn'
															dropup={index === props.myLink.length - 1}
														>
															{isOpen === data.element_id && (
																<>
																	<MenuItem
																		onClick={() => {
																			setIsOpen('');
																			props.handleModalState('editShortcut', data);
																		}}
																		eventKey='2'
																	>
																		<EditIcon className='mr-8' />
																		<p className='font--12px font-weight--500 line-height--17px no-margin'>
																			<Translate text={localizationConstants.edit} />
																		</p>
																	</MenuItem>
																	<MenuItem onClick={() => props.deleteData(data.element_id, true)} eventKey='1'>
																		<DeleteIcon className='delete-icon mr-8' />
																		<p className='font--12px font-weight--500 line-height--17px shortcut-delete no-margin'>
																			<Translate text={localizationConstants.addShortCutDeleteLink} />
																		</p>
																	</MenuItem>
																</>
															)}
														</DropdownButton>
													</ButtonToolbar>
												</div>
												<h4 className='truncate--lines-1'>{data.ziel_url}</h4>
											</div>
										</div>
									</a>
								</div>
							</div>
						))}
				</div>
			</div>
			{props.action === 'openShortcut' && <div className='overlay' />}
		</>
	);
};

export default MyShortcutSideNav;

import React from 'react';
import './spinner.css';

const Loader: React.FC<{ className?: string }> = (props) => (
	<div className={[props.className || '', 'spinner-wrapper'].join(' ')}>
		<div className='loader' />
	</div>
);

const RippleLoader: React.FC<{ className?: string }> = () => (
	<div className='lds-spinner'>
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
	</div>
);

const DottedLoader: React.FC<Record<string, never>> = () => <span className='loader' />;

export { Loader, RippleLoader, DottedLoader };

import React from 'react';
import { Modal } from 'react-bootstrap';

import CustomModal, { ModalProps } from '../modal/modal';

import localizationConstants from 'shared/util/translation/constants';
import i18n from 'shared/util/localization';

import Button from '../form/button';

interface ConfirmComponentProps {
	message: string;
	handleConfirm: () => void;
	loading?: boolean;
	submitBtnText?: string;
	cancelBtnText?: string;
}
const { t } = i18n;
const Confirm: React.FC<ModalProps & ConfirmComponentProps> = (props) => (
	<CustomModal show={props.show} handleClose={props.handleClose} className='confirm-wrapper'>
		<div className='confirm-icon font--30px' data-testid='confirm-icon'>
			<i className='fa fa-exclamation' />
		</div>
		<p className='mt-3 font--20px text-align-center line-height--24px'>{props.message}</p>
		<Modal.Footer className='modal-footer-area text-align-center'>
			<Button className='width-100px mr-8' btnType='primary' onClick={props.handleClose}>
				{props.cancelBtnText || `${t(localizationConstants.no)}`}
			</Button>
			<Button className='width-100px' btnType='secondary' loading={props.loading} onClick={props.handleConfirm}>
				{props.submitBtnText || `${t(localizationConstants.yes)}`}
			</Button>
		</Modal.Footer>
	</CustomModal>
);

export default Confirm;

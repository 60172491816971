import React, { Fragment, useState } from 'react';
import { Tooltip } from 'react-tooltip';

import { AdminChartInfoIcon, AdminCriticleChartInfoIcon, ChartInfoIcon, CriticleChartInfoIcon } from 'shared/icons/icon';
import PermissionManager, { checkPermission } from 'shared/hoc/permission/permissionManager';
import { adminPermissionMapping } from 'shared/constants/permissionConstant';

import AddInfoPopUp from './addInfoPopUp';
import { IChartDetails } from '../interface/futureTable';

interface IProps {
	chartDetails: IChartDetails;
	chartId: string;
}

const ChartInfo: React.FC<IProps> = (props) => {
	const { chartDetails, chartId } = props;
	const [isAddInfo, setIsAddInfo] = useState(false);
	const [tooltipInfo, setTooltipInfo] = useState<string>(chartDetails.info.trim());
	const [priority, setPriority] = useState(chartDetails.priority);
	return (
		<Fragment>
			<span id={`${chartDetails.symbol}-${chartDetails.boerse}-${chartId}`} className='ml-6 height--fit-content'>
				{checkPermission({ permission: adminPermissionMapping.superDataAdmin }) ? (
					priority ? (
						<AdminCriticleChartInfoIcon onClick={() => setIsAddInfo(!isAddInfo)} height='26' width='30' className='cursor-pointer' />
					) : (
						<AdminChartInfoIcon onClick={() => setIsAddInfo(!isAddInfo)} height='26' width='30' className='cursor-pointer' />
					)
				) : priority ? (
					<CriticleChartInfoIcon height='26' width='30' className='cursor-pointer' />
				) : (
					<ChartInfoIcon height='26' width='30' className='cursor-pointer' />
				)}
				<Tooltip
					anchorId={`${chartDetails.symbol}-${chartDetails.boerse}-${chartId}`}
					place='bottom'
					className='no-padding chart-info--tooltip'
					content={tooltipInfo}
					clickable
				/>
			</span>

			{isAddInfo && (
				<PermissionManager permission={adminPermissionMapping.superDataAdmin}>
					<AddInfoPopUp
						isAddInfo={isAddInfo}
						handleClosePopup={() => setIsAddInfo(false)}
						submitParams={{
							boerse: chartDetails.boerse,
							basis_symbol: chartDetails.basis_symbol,
							symbol: chartDetails.symbol
						}}
						initialValues={{ info: tooltipInfo, priority: priority }}
						setTooltipInfo={setTooltipInfo}
						setPriority={setPriority}
					/>
				</PermissionManager>
			)}
		</Fragment>
	);
};

export default ChartInfo;

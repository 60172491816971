import React from 'react';

import { SearchIcon } from 'shared/icons/icon';
import i18n from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';

interface IProps {
	className?: string;
	placeHolder?: string;
	handleOnSearch?: (word: string) => void;
	disabledSearch?: boolean;
}
const { t } = i18n;
const Search: React.FC<IProps> = (props) => (
	<div className='search-bar__container position--relative mr-8'>
		<input
			autoComplete='off'
			className={`form-field ${props.className || ''} ${props.disabledSearch && 'cursor-not-allowed'}`}
			name='searchbar'
			type='text'
			placeholder={props.placeHolder || `${t(localizationConstants.search)}`}
			onChange={(e) => {
				const value = e.target.value;
				if (value.length === 1 && value.startsWith(' ')) {
					e.target.value = ''; // Prevent leading whitespace
				} else {
					props.handleOnSearch && props.handleOnSearch(value.trimStart());
				}
			}}
			disabled={props.disabledSearch}
		/>
		<SearchIcon className='search-icon' />
	</div>
);

export default Search;

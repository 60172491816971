import React, { MouseEventHandler, CSSProperties, PropsWithChildren } from 'react';
import { Loader } from '../spinner/spinner';

interface ButtonProps extends PropsWithChildren {
	btnType?:
		| 'secondary'
		| 'primary'
		| 'tabs'
		| 'additional'
		| 'admin'
		| 'success'
		| 'danger'
		| 'accent'
		| 'sky-blue'
		| 'iframe-gradient'
		| 'transparent';
	loading?: boolean;
	disabled?: boolean;
	type?: 'submit' | 'button' | 'reset';
	className?: string;
	onClick?: MouseEventHandler<any>;
	abbr?: string;
	title?: string;
	style?: CSSProperties;
	dataTestId?: string;
	iconPosition?: 'left' | 'right';
	icon?: JSX.Element;
	id?: string;
}
const Button: React.FC<ButtonProps> = (props) => {
	const onClick: MouseEventHandler<any> = (e) => !props.loading && props.onClick && props.onClick(e);
	const button = (
		<button
			data-testid={props.dataTestId || null}
			title={props.abbr || ''}
			type={props.type}
			className={`button button-${props.btnType || 'secondary'} ${(props.disabled && 'disabled') || ''}  ${props.className} ${
				(props.icon && 'd-flex justify-content-center align-items-center') || ''
			}`}
			disabled={props.loading || props.disabled}
			style={props.style || {}}
			onClick={!props.disabled && !props.loading ? onClick : undefined}
			id={props.id}
		>
			{props.loading ? (
				<Loader className={`${props.btnType == 'primary' && 'white-loader'}`} />
			) : props.icon ? (
				props.iconPosition === 'left' ? (
					<>
						{props.icon}
						{props.children}
					</>
				) : (
					<>
						{props.children}
						{props.icon}
					</>
				)
			) : (
				props.children
			)}
		</button>
	);
	return props.abbr ? (
		<abbr title={props.abbr || ''} className='custom-abbr'>
			{button}
		</abbr>
	) : (
		button
	);
};

export default Button;

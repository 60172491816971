import * as ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import Button from 'shared/components/form/button';
import { API_CONFIG, adminMatrialForecastExcelHeaders, adminMatrialIntradayHeaders } from 'shared/constants/constants';
import { PlusIcon, ProfileIcon } from 'shared/icons/icon';
import { State } from 'shared/interface';
import httpService from 'shared/services/http.service';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';
import { formatDate } from 'shared/util/utility';
import PermissionManager from 'shared/hoc/permission/permissionManager';
import { adminPermissionMapping } from 'shared/constants/permissionConstant';

import { IChartData, IChartDetails, IGetAdminCategoryList } from '../interface/futureTable';
import AddCourseListPopup from './addCourseListPopup';
import AssignToAdmin from './assignToAdmin';

interface IProps {
	chartData: IChartData[];
	symbol: string;
	boerse: string;
	basis_symbol: string;
	chartDetails: IChartDetails;
	isIntraday: boolean;
	setPriceListLoading: (loading: boolean) => void;
}

const AdminExportButtons: React.FC<IProps> = (props) => {
	const { chartData, chartDetails, isIntraday, setPriceListLoading } = props;
	const { language } = useSelector((state: State) => state.siteConfig);
	const [adminIncludeCategory, setAdminIncludeCategory] = useState<IGetAdminCategoryList[]>([]);
	const [action, setAction] = useState('');

	const getAdminCategory = useCallback(() => {
		setPriceListLoading(true);
		const payload = {
			symbol: chartDetails.symbol,
			boerse: chartDetails.boerse,
			basis_symbol: chartDetails.basis_symbol
		};
		setAdminIncludeCategory([]);
		httpService
			.get(API_CONFIG.path.adminCategory, payload)
			.then((res) => {
				setAdminIncludeCategory(res);
				setPriceListLoading(false);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [chartDetails]);

	useEffect(() => {
		if (chartDetails.boerse !== '' || chartDetails.symbol !== '' || chartDetails.basis_symbol !== '') {
			setTimeout(() => {
				getAdminCategory();
			}, 600);
		}
	}, [chartDetails.basis_symbol, chartDetails.boerse, chartDetails.symbol]);

	const downloadAdminHistroy = () => {
		const payload = {
			symbol: chartDetails.symbol,
			boerse: chartDetails.boerse
		};
		httpService
			.get(API_CONFIG.path.adminHistoryDownload, payload)
			.then(async (response) => {
				const columns = [] as any;
				Object.keys(response[0]).map((value: string) => {
					const params = {
						header: value,
						key: value,
						width: value === 'datum' ? 15 : 10
					};
					columns.push(params);
				});
				const sheetName =
					language === 'en' && chartDetails.name_eng !== '' ? chartDetails.name_eng.slice(0, 25) : chartDetails.name.slice(0, 25);
				const workbook = new ExcelJS.Workbook();
				const worksheet = workbook.addWorksheet('sheetName');
				worksheet.columns = columns;
				response.map((object: any) => {
					worksheet.addRow(object);
				});
				const buf = await workbook.xlsx.writeBuffer();
				const downloadSheetName = `Kurshistorie--${sheetName.replaceAll(' ', '_')}--${formatDate(new Date(), 'YYYY-MM-DD')}`;
				saveAs(new Blob([buf]), `${downloadSheetName}.xlsx`);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const downloadAdminForecast = () => {
		const payload = {
			symbol: chartDetails.symbol,
			boerse: chartDetails.boerse
		};
		httpService
			.get(`${API_CONFIG.path.adminForecastDownload}`, payload)
			.then(async (response) => {
				const sheetName =
					language === 'en' && chartDetails.name_eng !== '' ? chartDetails.name_eng.slice(0, 25) : chartDetails.name.slice(0, 25);
				const workbook = new ExcelJS.Workbook();
				const worksheet = workbook.addWorksheet('sheetName');
				worksheet.columns = adminMatrialForecastExcelHeaders;
				response.map((object: any) => {
					worksheet.addRow(object);
				});
				const buf = await workbook.xlsx.writeBuffer();
				const downloadSheetName = `${formatDate(new Date(), 'YYYY-MM-DD')}--Forecasthistory--${sheetName.replaceAll(' ', '_')}`;
				saveAs(new Blob([buf]), `${downloadSheetName}.xlsx`);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const downloadAdminPdfModule = () => {
		const payload = {
			symbol: chartDetails.symbol,
			boerse: chartDetails.boerse
		};
		httpService
			.get(`${API_CONFIG.path.adminPdfModuleDownload}`, payload)
			.then(async (response) => {
				const sheetName =
					language === 'en' && chartDetails.name_eng !== '' ? chartDetails.name_eng.slice(0, 25) : chartDetails.name.slice(0, 25);
				const workbook = new ExcelJS.Workbook();
				const worksheet = workbook.addWorksheet('sheetName');
				worksheet.columns = adminMatrialForecastExcelHeaders;
				response.map((object: any) => {
					worksheet.addRow(object);
				});
				const buf = await workbook.xlsx.writeBuffer();
				const downloadSheetName = `${formatDate(new Date(), 'YYYY-MM-DD')}--PdfModuleHistory--${sheetName.replaceAll(' ', '_')}`;
				saveAs(new Blob([buf]), `${downloadSheetName}.xlsx`);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const downloadAdminIntrdayData = () => {
		const payload = {
			symbol: chartDetails.symbol,
			boerse: chartDetails.boerse
		};
		httpService
			.get(`${API_CONFIG.path.adminDownloadIntrdayData}`, payload)
			.then(async (response) => {
				const sheetName = language === 'en' && chartDetails.name_eng !== '' ? `${chartDetails.name_eng}` : `${chartDetails.name}`;
				const workbook = new ExcelJS.Workbook();
				const worksheet = workbook.addWorksheet('sheetName');
				worksheet.columns = adminMatrialIntradayHeaders;
				response.map((object: any) => {
					worksheet.addRow(object);
				});
				const buf = await workbook.xlsx.writeBuffer();
				const downloadSheetName = `${formatDate(new Date(), 'YYYY-MM-DD--HH-mm-ss')}--Intraday-Kurshistorie--${sheetName.replaceAll(
					' ',
					'_'
				)}`;
				saveAs(new Blob([buf]), `${downloadSheetName}.xlsx`);
			})
			.catch((err) => {
				console.error(err);
			});
	};
	return (
		<>
			<div className='admin-buttons pb-20'>
				<div className='d-flex'>
					<PermissionManager permission={adminPermissionMapping.superDataUserAdmin}>
						<Button
							btnType='admin'
							className='mr-8 padding-left-right--16px'
							icon={<PlusIcon className='mr-10 icon--small' />}
							iconPosition='left'
							onClick={downloadAdminHistroy}
							disabled={isEmpty(chartData)}
						>
							<Translate text={localizationConstants.adminDownloadHistory} />
						</Button>
					</PermissionManager>
					<PermissionManager permission={adminPermissionMapping.superDataUserAdmin}>
						<Button
							btnType='admin'
							className='mr-8 padding-left-right--16px'
							icon={<PlusIcon className='mr-10 icon--small' />}
							iconPosition='left'
							onClick={downloadAdminForecast}
						>
							<Translate text={localizationConstants.adminDownloadForecast} />
						</Button>
					</PermissionManager>
					<PermissionManager permission={adminPermissionMapping.superDataUserAdmin}>
						<Button
							btnType='admin'
							className='mr-8 padding-left-right--16px'
							icon={<PlusIcon className='mr-10 icon--small' />}
							iconPosition='left'
							onClick={downloadAdminPdfModule}
						>
							<Translate text={localizationConstants.adminDownloadPdfModule} />
						</Button>
					</PermissionManager>
					<PermissionManager permission={adminPermissionMapping.superDataAdmin}>
						<Button
							btnType='admin'
							className='mr-8 padding-left-right--16px'
							icon={<PlusIcon className='mr-10 icon--small' />}
							iconPosition='left'
							onClick={() => setAction('addCourseList')}
						>
							<Translate text={localizationConstants.addToMbiList} />
						</Button>
					</PermissionManager>
					<PermissionManager permission={adminPermissionMapping.superUserAdmin}>
						<Button
							btnType='admin'
							className='mr-8 padding-left-right--16px'
							icon={<ProfileIcon className='mr-10 icon--small black-icon' />}
							iconPosition='left'
							onClick={() => setAction('assignToUser')}
						>
							<Translate text={localizationConstants.assignMaterialToUser} />
						</Button>
					</PermissionManager>
				</div>
				{isIntraday && (
					<Button
						btnType='admin'
						className='mt-8 padding-left-right--16px'
						icon={<PlusIcon className='mr-10 icon--small' />}
						iconPosition='left'
						onClick={downloadAdminIntrdayData}
					>
						<Translate text={localizationConstants.adminDownloadIntraday} />
					</Button>
				)}
				<div className='mt-24 d-flex light-orange-bg padding--15px align-items-center'>
					<p className='font--18px no-margin '>
						<Translate text={localizationConstants.categoryIncludeMessage} />
					</p>
					{!isEmpty(adminIncludeCategory) && (
						<>
							{adminIncludeCategory.map((ele: IGetAdminCategoryList) => {
								return (
									<Link
										to={
											ele.lists.parent_id != null
												? `/materials?parentId=${ele.lists.parent_id}&listen_id=${ele.listen_id}${
														ele.lists.gruppen_id && `&parentSubmenuId=${ele.lists.gruppen_id}`
												  }`
												: `/materials/myMaterial/courseList?listen_id=${ele.listen_id}`
										}
										className='ml-5 font--18px'
										key={ele.listen_id}
									>
										{language === 'en' && ele.lists.listen_name_eng !== '' ? ele.lists.listen_name_eng : ele.lists.listen_name}
									</Link>
								);
							})}
						</>
					)}
				</div>
			</div>
			{action === 'assignToUser' && <AssignToAdmin onClose={() => setAction('')} />}
			{action === 'addCourseList' && (
				<AddCourseListPopup
					boerse={chartDetails.boerse}
					setAction={setAction}
					symbol={chartDetails.symbol}
					getAdminCategory={getAdminCategory}
				/>
			)}
		</>
	);
};

export default AdminExportButtons;

import { SearchIcon } from 'shared/icons/icon';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';

const EmptySearchComponent = () => (
	<div className='d-flex flex-column text-align-center padding--20'>
		<div>
			<SearchIcon />
		</div>
		<p className='no-result-title font--20px'>
			<Translate text={localizationConstants.noEntriesFound} />
		</p>
		<p className='font--14px no-result-description'>
			<Translate text={localizationConstants.tryUsingDiffKeyword} />
		</p>
	</div>
);
export default EmptySearchComponent;

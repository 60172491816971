import React from 'react';
import { MatfoxxIcon, YellowMessageIcon, YellowTeleIcon } from 'shared/icons/icon';
import CustomModal from '../modal/modal';
import { useSelector } from 'react-redux';
import { State } from 'shared/interface';
import { useDispatch } from 'react-redux';
import AuthService from 'shared/services/auth.service';
import { createAction } from 'shared/util/utility';
import * as actionTypes from 'store/action-types';

const FreezeUserPopup: React.FC = () => {
	const { userData } = useSelector((state: State) => state.auth);
	const dispatch = useDispatch();

	const handleClosePopup = () => {
		const data = { ...userData };
		data.is_freeze = false;
		AuthService.setAuthData(data);
		dispatch(createAction(actionTypes.AUTH_SUCCESS, data));
	};

	return (
		<CustomModal show handleClose={() => handleClosePopup()} className='freeze-user-modal'>
			<div className='freezeuser-popup__wrapper full-width'>
				<div className='matfoxx-icon__wrapper full-width d-flex justify-content-center align-items-center'>
					<MatfoxxIcon className='matfoxx-icon' />
				</div>
				<p className='no-margin font--24px line-height--30px font-weight--500 text-align-center mt-24'>
					Ihr Zugang wurde eingeschränkt. Bitte kontaktieren Sie den Kundenservice.
				</p>
				<div className='announcement-footer d-flex justify-content-space-evenly mt-31'>
					<div className='d-flex align-items-center'>
						<YellowTeleIcon className='cursor-pointer' />
						<span className='ml-8 cursor-pointer'>+49 (0) 69 271 07 60 11</span>
					</div>
					<div className='d-flex align-items-center'>
						<YellowMessageIcon className='cursor-pointer' />
						<span className='ml-8 cursor-pointer'>service@matflixx.de</span>
					</div>
				</div>
			</div>
		</CustomModal>
	);
};

export default FreezeUserPopup;

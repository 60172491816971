import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { State } from 'shared/interface';
import MATFLIXX_LOGO from 'assets/images/matflixx.png';

interface IProps {
	forecastList: {
		id: number;
		name: string;
		name_eng: string;
		image: string;
	}[];
	className: string;
	type: string;
	handleOnClick: (groupId: number, type: string) => void;
}

const AllForecastCards: React.FC<IProps> = ({ forecastList, className, type, handleOnClick }) => {
	const { language } = useSelector((state: State) => state.siteConfig);
	return (
		<>
			{!isEmpty(forecastList) &&
				forecastList.map((data) => {
					const { id, image, name, name_eng } = data;
					return (
						<div key={id} onClick={() => handleOnClick(id, type)} className={className}>
							<div className={`reports-image__wrapper`}>
								<img src={(image !== '' && image) || MATFLIXX_LOGO} crossOrigin='anonymous' alt='icon' className={`reports--icon`} />
							</div>
							<p className={`reports-name no-margin bg-color--white display-flex-center d-flex truncate--lines-2`}>
								{' '}
								{language === 'en' ? name_eng : name}
							</p>
						</div>
					);
				})}
		</>
	);
};

export default AllForecastCards;

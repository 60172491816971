import React from 'react';

import PbRow from './pbRow';
import PbCell from './pbCell';
import { Loader } from '../spinner/spinner';

const TableSpinner: React.FC<{ colSpan: number }> = (props) => (
	<PbRow>
		<PbCell colSpan={props.colSpan}>
			<Loader />
		</PbCell>
	</PbRow>
);

export default TableSpinner;

export const allPermissionMapping: { [key: string]: string } = {
	// Material
	MaterialStatus: 'material_status',
	NewsStatus: 'news_status',
	ForecastStatus: 'forecast_status',
	PdfModuleStatus: 'pdfModule_status'
};

export const adminPermissionMapping: { [key: string]: string[] } = {
	EditorNewsButton: ['editor', 'superAdmin', 'dataAdmin'],
	superDataAdmin: ['superAdmin', 'dataAdmin'],
	superUserAdmin: ['superAdmin', 'userAdmin'],
	superDataUserAdmin: ['superAdmin', 'dataAdmin', 'userAdmin'],
	editorAdmin: ['editor'],
	superAdmin: ['superAdmin']
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Pagination, State } from 'shared/interface';
import Button from '../form/button';

interface Props {
	disabled?: boolean;
	loading?: boolean;
	active: number;
	handlePageChange: (page: number) => void;
	handlePageSelectionChange: (perPage: number) => void;
	className?: string;
}
// maximum number of pages per pagination
const maxPages = 10;

const pageOptions = [10, 30, 50, 100];

const TablePagination: React.FC<Pagination & Props> = (props) => {
	const { recordPerPage } = useSelector((state: State) => state.siteConfig);
	// total number of paginations available
	// so, let's say props.lastPage = 17
	// that means pagination must be divided into 2 parts => 1 to 10, and 11 to 17
	// so `totalPaginations` = Math.ceil(17 / 10) = 2
	// const totalPaginations = props.lastPage ? Math.ceil(props.lastPage / maxPages) : 0;
	// currentPagination is the pagination that user is currently on
	// pagination is divided in 2 parts => 1to 10, and 11 to 17
	// let's say user's current page is 11 out of total 17 pages,
	// so user must be on 2nd(1st, as array index starts from 0) pagination (11 to 17)
	// so, formula for getting user's currentPagination = Math.ceil(11 / 10) - 1 = 2 - 1 => 1
	const currentPagination = Math.ceil(props.page / maxPages) - 1;

	// determine last page to show in pagination
	let lastPage = maxPages * (currentPagination + 1);
	if (props.lastPage < lastPage) {
		lastPage = props.lastPage;
	}

	// jsx to render page numbers
	const pages: JSX.Element[] = [];
	// show pages in 10-10 batches
	// when user's currentpage = 1, show 1 to 10 pages
	// when user's currentpage = 11, show 11 to 20 pages
	// when user's currentpage = 23, show 21 to 30 pages, and so on...
	for (let page = currentPagination * maxPages + 1; page <= lastPage; page += 1) {
		pages.push(
			<button
				disabled={props.disabled || props.loading}
				onClick={() => page !== props.active && props.handlePageChange(page)}
				key={page}
				className={`btn btn-white pagination-btn full-height ${props.active === page ? 'pagination-active' : ''}`}
			>
				{page}
			</button>
		);
	}

	return (
		<div className={`table-pagination-wrapper ${props.className}`} data-testid='pagination'>
			{/* <div className='page-count'>
				Total Records : <span className='font-bold'>{props.total}</span>
			</div> */}
			<div className='page-selection mr-16'>
				<select
					disabled={props.disabled || props.loading}
					className='form-control'
					value={recordPerPage}
					onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
						props.handlePageSelectionChange(parseInt(e.target.value));
					}}
				>
					{pageOptions.map((page) => {
						return (
							<option key={page} value={page}>
								{page}
							</option>
						);
					})}
				</select>
			</div>
			<div className='btn-group full-height'>
				<Button
					disabled={props.disabled || props.loading || (currentPagination === 0 && props.page === 1)}
					onClick={() => props.handlePageChange(props.lastPage ? props.page - 1 : -1)}
					className='btn btn-white full-height'
					type='button'
				>
					{'<<'}
				</Button>
				{pages}
				<Button
					onClick={() => props.handlePageChange(props.lastPage ? props.page + 1 : -1)}
					disabled={props.disabled || props.loading || (props.total as number) < recordPerPage || props.page == props.lastPage}
					className='btn btn-white full-height'
					type='button'
				>
					{'>>'}
				</Button>
			</div>
		</div>
	);
};

export default TablePagination;

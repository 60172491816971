import { bollingerBand, ema, macd, rsi, sma, tma, wma } from 'react-stockcharts/lib/indicator';
import { get, has, isEmpty, uniqBy } from 'lodash';

import i18n from 'shared/util/localization';
import { ScaleType } from 'shared/interface/enums';
import localizationConstants from 'shared/util/translation/constants';
import { CHARTMAPPER, colorPallet, defArray } from 'shared/constants/constants';

import atr from './atr';
import stochasticOscillator from './stochasticOscillator';
import { IChartIndicators, IChartSettingsType } from 'features/futuretable/interface/futureTable';
import { IIndicatorConfig, IMACDIndicatorInputType, ISTOIndicatorType } from 'features/futuretable/component/stockChart';

const { t } = i18n;
export const spanDropDown = [
	{ label: `1 ${t(localizationConstants.week)}`, value: '1w' },
	{ label: `1 ${t(localizationConstants.month)}`, value: '1m' },
	{ label: `3 ${t(localizationConstants.months)}`, value: '3m' },
	{ label: `6 ${t(localizationConstants.months)}`, value: '6m' },
	{ label: 'YTD', value: 'ytd' },
	{ label: `1 ${t(localizationConstants.year)}`, value: '1y' },
	{ label: `3 ${t(localizationConstants.year)}`, value: '3y' },
	{ label: `5 ${t(localizationConstants.year)}`, value: '5y' },
	{ label: `10 ${t(localizationConstants.year)}`, value: '10y' },
	{ label: 'Max', value: 'max' },
	{ label: `${t(localizationConstants.custom)}`, value: 'custom' }
];

export const indicatorRange = {
	sma: 26,
	ema: 12,
	tma: 20,
	wma: 20,
	bb: 20,
	atr: 14,
	rsi: 14,
	macd: {
		kurz: 26,
		signal: 9,
		lang: 12
	},
	sto: {
		datum: 3,
		tage: 14,
		k: 3
	}
};

export const IndicatorsDropdown = [
	{ label: `${t(localizationConstants.ema)}`, value: 'ema', calculatedValue: indicatorRange.ema },
	{ label: `${t(localizationConstants.sma)}`, value: 'sma', calculatedValue: indicatorRange.sma },
	{ label: `${t(localizationConstants.bb)}`, value: 'bb', calculatedValue: indicatorRange.bb },
	{ label: `${t(localizationConstants.tma)}`, value: 'tma', calculatedValue: indicatorRange.tma },
	{ label: `${t(localizationConstants.wma)}`, value: 'wma', calculatedValue: indicatorRange.wma },
	{ label: `${t(localizationConstants.atr)}`, value: 'atr', calculatedValue: indicatorRange.atr },
	{ label: `${t(localizationConstants.rsi)}`, value: 'rsi', calculatedValue: indicatorRange.rsi },
	{ label: `${t(localizationConstants.macd)}`, value: 'macd', kurz: 26, signal: 9, lang: 12 },
	{ label: `${t(localizationConstants.sto)}`, value: 'sto', datum: 3, tage: 14, k: 3 }
];

export const barSpanDropDown = [
	{ label: `${t(localizationConstants.weekly)}`, value: '1w' },
	{ label: `${t(localizationConstants.monthly)}`, value: '1m' },
	{ label: `${t(localizationConstants.yearly)}`, value: '1y' }
];

export const candleStickSpanDropDown = [
	{ label: `1 ${t(localizationConstants.month)}`, value: '1m' },
	{ label: `3 ${t(localizationConstants.months)}`, value: '3m' },
	{ label: `6 ${t(localizationConstants.months)}`, value: '6m' }
];

// const keyMapper: any = {
// 	brief: 'absoluteChange',
// 	datum: 'date',
// 	erster: 'open',
// 	geld: 'dividend',
// 	hoch: 'high',
// 	letzter: 'close',
// 	letzter_: 'low',
// 	percentage: 'percentChange',
// 	settlement: 'split',
// 	tief: 'low',
// 	volumen: 'volume'
// };

export const indicatorsWindowWidth: { [key: string]: number } = {
	sma: 26,
	ema: 12,
	tma: 20,
	wma: 20,
	bb: 20,
	atr: 14,
	rsi: 14
};

const emaFunction = (sourcePath: string, keyName: string, windowSize: number) =>
	ema()
		.id(2)
		.options({ windowSize: windowSize, sourcePath: sourcePath })
		.merge((d: any, c: any) => {
			d[keyName] = c;
		})
		.accessor((d: any) => d[keyName]);

const smaFunction = (sourcePath: string, keyName: string, windowSize: number) =>
	sma()
		.options({ windowSize: windowSize, sourcePath: sourcePath })
		.merge((d: any, c: any) => {
			d[keyName] = c;
		})
		.accessor((d: any) => d[keyName]);

const tmaFunction = (sourcePath: string, keyName: string, windowSize: number) =>
	tma()
		.options({ windowSize: windowSize, sourcePath: sourcePath })
		.merge((d: any, c: any) => {
			d[keyName] = c;
		})
		.accessor((d: any) => d[keyName]);

const bbFunction = (sourcePath: string, keyName: string, windowSize: number) =>
	bollingerBand()
		.options({ windowSize: windowSize, sourcePath: sourcePath })
		.merge((d: any, c: any) => {
			d[keyName] = c;
		})
		.accessor((d: any) => d[keyName]);

const wmaFunction = (sourcePath: string, keyName: string, windowSize: number) =>
	wma()
		.options({
			windowSize: windowSize,
			sourcePath: sourcePath
		})
		.merge((d: any, c: any) => {
			d[keyName] = c;
		})
		.accessor((d: any) => d[keyName]);

export const atrFunction = (sourcePath: string, keyName: string, windowSize: number, scaleType: string) => {
	return atr(sourcePath, scaleType)
		.options({ windowSize: windowSize })
		.merge((d: any, c: any) => {
			d[keyName] = c;
		})
		.accessor((d: any) => d[keyName]);
};

export const rsiCalculatorFunction = (sourcePath: string, keyName: string, windowSize: number) =>
	rsi()
		.options({ windowSize: windowSize, sourcePath: sourcePath })
		.merge((d: any, c: any) => {
			d[keyName] = c;
		})
		.accessor((d: any) => d[keyName]);

export const macdCalculatorFunction = (sourcePath: string, keyName: string, windowSize: IMACDIndicatorInputType) =>
	macd()
		.options({
			fast: windowSize.lang || indicatorRange.macd.lang,
			slow: windowSize.kurz || indicatorRange.macd.kurz,
			signal: windowSize.signal || indicatorRange.macd.signal,
			sourcePath: sourcePath
		})
		.merge((d: any, c: any) => {
			d[keyName] = c;
		})
		.accessor((d: any) => d[keyName]);

export const fullSTOFunction = (sourcePath: string, keyName: string, windowSize: ISTOIndicatorType, scaleType: string) =>
	stochasticOscillator(sourcePath, scaleType)
		.options({
			windowSize: windowSize.tage || indicatorRange.sto.tage,
			kWindowSize: windowSize.datum || indicatorRange.sto.datum,
			dWindowSize: windowSize.k || indicatorRange.sto.k,
			sourcePath: sourcePath
		})
		.merge((d: any, c: any) => {
			d[keyName] = c;
		})
		.accessor((d: any) => d[keyName]);

export const getChartDataBasedOnIndicators = (chartData: any, indicatorsFunctionNames: IIndicatorConfig[], scaleType: string) => {
	let updatedChartData = [...chartData];
	for (const funcName of indicatorsFunctionNames) {
		if (funcName.value === 'ema') {
			updatedChartData = emaFunction(
				funcName.keyName,
				funcName.indicatorKeyName,
				(funcName.windowSize || indicatorRange['ema']) as number
			)(updatedChartData);
		} else if (funcName.value === 'sma') {
			updatedChartData = smaFunction(
				funcName.keyName,
				funcName.indicatorKeyName,
				(funcName.windowSize || indicatorRange['sma']) as number
			)(updatedChartData);
		} else if (funcName.value === 'tma') {
			updatedChartData = tmaFunction(funcName.keyName, funcName.indicatorKeyName, funcName.windowSize as number)(updatedChartData);
		} else if (funcName.value === 'bb') {
			updatedChartData = bbFunction(
				funcName.keyName,
				funcName.indicatorKeyName,
				(funcName.windowSize || indicatorRange['bb']) as number
			)(updatedChartData);
		} else if (funcName.value === 'wma') {
			updatedChartData = wmaFunction(
				funcName.keyName,
				funcName.indicatorKeyName,
				(funcName.windowSize || indicatorRange['wma']) as number
			)(updatedChartData);
		} else if (funcName.value === 'atr') {
			updatedChartData = atrFunction(
				funcName.keyName,
				funcName.indicatorKeyName,
				(funcName.windowSize || indicatorRange['atr']) as number,
				scaleType
			)(updatedChartData);
		} else if (funcName.value === 'rsi') {
			updatedChartData = rsiCalculatorFunction(funcName.keyName, funcName.indicatorKeyName, funcName.windowSize as number)(updatedChartData);
		} else if (funcName.value === 'macd') {
			updatedChartData = macdCalculatorFunction(
				funcName.keyName,
				funcName.indicatorKeyName,
				funcName.windowSize as IMACDIndicatorInputType
			)(updatedChartData);
		} else if (funcName.value === 'sto') {
			updatedChartData = fullSTOFunction(
				funcName.keyName,
				funcName.indicatorKeyName,
				funcName.windowSize as ISTOIndicatorType,
				scaleType
			)(updatedChartData);
		} else {
			return updatedChartData;
		}
	}
	return updatedChartData;
};

export const getIndicatorsWindowSize = (indi: IChartIndicators): number | ISTOIndicatorType | IMACDIndicatorInputType => {
	switch (indi.value) {
		case 'sto':
			return {
				datum: indi.datum,
				tage: indi.tage,
				k: indi.k
			};
		case 'macd':
			return {
				kurz: indi.kurz,
				signal: indi.signal,
				lang: indi.lang
			};
		case 'atr':
		case 'rsi':
		case 'ema':
		case 'sma':
		case 'tma':
		case 'wma':
		case 'bb':
			return indi.calculatedValue;
		default:
			return indi.calculatedValue;
	}
};

export const externalChartIndicatorHeightConfig = (
	externalChartIndicatorHeight: number,
	indicatorType: string,
	cardIndex: number,
	chartSettings: IChartSettingsType[],
	material: IChartSettingsType
) => {
	const totalExternalIndi = [] as IChartIndicators[];
	chartSettings.map((chart) => {
		chart.indicators.map((item) => {
			if (['rsi', 'macd', 'atr', 'sto'].includes(item.value)) {
				totalExternalIndi.push({
					...item,
					id: chart.id
				});
			}
		});
	});
	const indexOfIndicator = totalExternalIndi
		.reverse()
		.findIndex((indicator) => indicator.value === indicatorType && material.symbol === indicator.symbol && material.id === indicator.id);
	return [0, externalChartIndicatorHeight - ((cardIndex < 2 && 135) || 100) * (indexOfIndicator + 1)];
};

export const createChartConfig = (response: any) => {
	const data = [...response];
	return data.map((item) => {
		const externalChartIndicators: string[] = [];
		const indicator: string[] = [];
		let chartConfig: any = item.chartConfig;
		if (item.type === 'chart') {
			if (has(item.chartConfig, 'compare_instruments') || has(item.chartConfig, 'main_instrument')) {
				const comparativeChartIndicator: any = [];
				uniqBy(item.chartConfig.preset_indicators, 'value')?.forEach((data: any) => {
					if (defArray.includes(data.key)) {
						externalChartIndicators.push(data.key);
					} else {
						indicator.push(data.key);
					}
				});

				if (!isEmpty(item.chartConfig.compare_instruments)) {
					item.chartConfig.compare_instruments.map((compareValue: any) => {
						let currency = 'EUR';
						let unit = '';
						if (has(compareValue.option, 'label_suffix')) {
							const parts = has(compareValue.option, 'label_suffix') && compareValue.option.label_suffix.split(' ');
							currency = parts[0].replace('[', '');
							unit = parts[2].replace(']', '');
						}
						const data = {
							basicSymbolF: compareValue.basicSymbolF || null,
							boerse: compareValue.boerse,
							color: compareValue.option.color,
							currency: currency,
							name: compareValue.option.label,
							suffix: unit,
							symbol: compareValue.symbol,
							isNormalize: true
						};
						comparativeChartIndicator.push(data);
					});
				}
				chartConfig = {
					...chartConfig,
					comparativeChartIndicator: [...comparativeChartIndicator]
				};
				return {
					...item,
					chartConfig: {
						...chartConfig,
						chartSelection: {
							type: CHARTMAPPER[item.chartConfig.chart_type] || 'area',
							indicator: [...indicator, ...externalChartIndicators],
							span: item.chartConfig.view_span || '1y',
							currency: 'EUR',
							chartColor: colorPallet[0],
							history: item.chartConfig.chartSelection?.history || '',
							chartView: item.chartConfig.chartSelection?.chartView || ScaleType.PRICE_AXIS
						},
						externalChartIndicators
					}
				};
			} else {
				return {
					...item,
					chartConfig: {
						...chartConfig,
						chartSelection: {
							type: item.chartConfig.chartSelection.type || 'area',
							indicator: get(item.chartConfig.chartSelection, 'indicator', []),
							span: item.chartConfig.chartSelection.span || '1y',
							currency: item.chartConfig.chartSelection.currency,
							chartColor: item.chartConfig.chartSelection?.chartColor || colorPallet[0],
							unit: item.chartConfig.chartSelection.unit,
							history: item.chartConfig.chartSelection?.history || '',
							axis: item.chartConfig.chartSelection?.axis || 'right',
							chartView: item.chartConfig.chartSelection?.chartView || ScaleType.PRICE_AXIS
						},
						comparativeChartIndicator: get(item, 'chartConfig.comparativeChartIndicator', []).map((ele: any) => {
							if (!has(ele, 'isNormalize')) {
								return {
									...(ele as any),
									isNormalize: true
								};
							} else {
								return ele;
							}
						})
					}
				};
			}
		} else {
			return item;
		}
	});
};

// export function plotDataLengthBarWidth(widthRatio: any, xScale: any) {
// 	const [l, r] = xScale.range();
// 	const totalWidth = Math.abs(r - l);
// 	if (xScale.invert != null) {
// 		const [dl, dr] = xScale.domain();
// 		const width = totalWidth / Math.abs(dl - dr);
// 		return width * widthRatio;
// 	} else {
// 		const width = totalWidth / xScale.domain().length;
// 		return width * widthRatio;
// 	}
// }

import { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosResponse, AxiosError } from 'axios';
import * as Sentry from '@sentry/browser';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { axiosInstance } from 'shared/services/http.service';
import { createAction, handleError } from 'shared/util/utility';
import Notification, { notify } from 'shared/components/notification/notification';
import { ResponseObj } from 'shared/interface';
import AuthService from 'shared/services/auth.service';
import { useInactivityTimer } from 'shared/hooks/useInactivityTimer';
import localizationConstants from 'shared/util/translation/constants';

import * as actionTypes from 'store/action-types';

const ErrorHandler: FC = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const logout = useCallback(() => {
		AuthService.removeAuthData();
		dispatch(createAction(actionTypes.AUTH_LOGOUT_SUCCESS));
	}, [dispatch]);

	const resetTimer = useInactivityTimer();

	useEffect(() => {
		const resInterceptor = axiosInstance.interceptors.response.use(
			(res: AxiosResponse<ResponseObj<any>>) => {
				if (res.data && res.data.isError && res.data.message) {
					notify(res.data.message, 'error', { id: 'error' });
					throw new Error(res.data.message);
				} else if (res.data) {
					if (res.data.message) {
						// notify(res.data.message, 'success');
					}
				}
				const { url } = res.config;
				const apiEndPoints = ['get-base-alerts', 'tracks/page-visits', 'tracks/session', 'login', 'logout'];
				const isResetTimer = apiEndPoints.some((value) => url?.includes(value));
				if (!isResetTimer) {
					resetTimer();
				}
				return res;
			},
			(error: AxiosError<ResponseObj<any>>) => {
				const res = error.response;
				// check if error is having data
				if (res && res.data && res.status) {
					const status = res.status;
					const responseData = res.data;

					// is http error code is 401, and message contains **SESSION_EXPIRED**, log out of the application
					if (responseData.message.includes('5 Minute')) {
						throw error;
					}
					if (status === 401) {
						if (responseData.data === 'other_device_login' || responseData.data === 'logged_out_due_to_inactivity') {
							dispatch(
								createAction(actionTypes.UPDATE_SITE_CONFIG, {
									sessionExpireMessage:
										responseData.data === 'logged_out_due_to_inactivity'
											? t(localizationConstants.inActivityMessage)
											: t(localizationConstants.otherDeviceLoginMessage)
								})
							);
						}
						logout();
					} else if (status === 403) {
						return navigate('/404', { replace: true });
					} else if (responseData.errorMessages && Object.keys(responseData.errorMessages).length) {
						// if error response contains any validation message, fetch it from response, and add error notification
						const validationError = responseData.errorMessages[Object.keys(responseData.errorMessages)[0]];
						notify(validationError[0], 'error', { id: 'notifyError' });
					} else if (res && responseData && responseData.message) {
						Sentry.captureException(handleError(error));
						// if error data contains message field, add error notification
						notify(responseData.message, 'error', { id: 'notifyError' });
					}
					throw error;
				}
			}
		);

		return () => axiosInstance.interceptors.response.eject(resInterceptor);
	}, [logout]);

	return <Notification />;
};

export default ErrorHandler;

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, Canceler } from 'axios';
import { QueryParameters, ResponseObj } from '../interface';
import AuthService from './auth.service';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';

export const cancelToken = axios.CancelToken;

export interface AxiosParams extends MiscellaneousRequestParams {
	method: string;
	url: string;
	data?: any;
}

export interface MiscellaneousRequestParams {
	isAccessTokenRequire?: boolean;
	contentType?: string;
	responseType?: string;
	xApi?: number | string | null;
	noCache?: boolean;
	streamUserId?: string | null;
	apiKey?: string | null;
	executor?: (cancel: Canceler) => void;
}

class HttpService {
	CancelToken = axios.CancelToken;
	cancel_req: any = {};
	baseUrl: string;
	name: string;
	axiosInstance: AxiosInstance;

	constructor(axiosInstance: AxiosInstance, baseUrl: string, name: string) {
		this.axiosInstance = axiosInstance;
		this.baseUrl = baseUrl;
		this.name = name;
	}

	getUrl = (url: string, params: QueryParameters | any = {}): string => {
		if (!url.includes('https')) {
			let urlString = `${this.baseUrl}/${url}`;
			if (params && !isEmpty(params)) {
				Object.keys(params).forEach((key) => {
					if (params[key] == null || params[key] === '') {
						delete params[key];
					}
				});
				urlString += `?${queryString.stringify(params)}`;
			}
			return urlString;
		}
		return url;
	};

	/**
	 * get method
	 * @param request object containing axios params
	 */
	get = (url: string, params: any = {}, otherData: MiscellaneousRequestParams = {}) =>
		this.commonAxios({ method: 'GET', url: this.getUrl(url, params), ...otherData });

	/**
	 * post method
	 * @param request object containing axios params
	 */
	post = (url: string, params: any = {}, queryParams = {}, otherData: MiscellaneousRequestParams = {}) =>
		this.commonAxios({
			method: 'POST',
			url: this.getUrl(url, queryParams),
			data: params,
			...otherData
		});

	/**
	 * put method
	 * @param request object containing axios params
	 */
	put = (url: string, params: any = {}, otherData: MiscellaneousRequestParams = {}) =>
		this.commonAxios({
			method: 'PUT',
			url: this.getUrl(url),
			data: params,
			...otherData
		});

	/**
	 * deleteRequest method
	 * @param request object containing axios params
	 */
	deleteRequest = (url: string, params: any = {}, otherData: MiscellaneousRequestParams = {}) =>
		this.commonAxios({
			method: 'DELETE',
			url: this.getUrl(url),
			data: params,
			...otherData
		});

	/**
	 * patch method
	 * @param request object containing axios params
	 */
	patch = (url: string, params: any = {}, otherData: MiscellaneousRequestParams = {}) =>
		this.commonAxios({
			method: 'PATCH',
			url: this.getUrl(url),
			data: params,
			...otherData
		});

	/**
	 * commonAxios
	 * @param object containing method, url, data, access token, content-type
	 */
	commonAxios = ({
		method,
		url,
		data,
		isAccessTokenRequire = true,
		contentType = 'application/json',
		responseType,
		xApi = '',
		noCache = false,
		streamUserId = '',
		apiKey = '',
		executor = (c) => (this.cancel_req = c)
	}: AxiosParams): Promise<any> => {
		const headers: any = {
			'Content-Type': contentType
		};
		const token = isAccessTokenRequire && AuthService.getAccessToken();
		if (apiKey) {
			headers['api-key'] = `${apiKey}`;
		}
		if (streamUserId) {
			headers['stream-user-id'] = streamUserId;
		}
		if (xApi) {
			headers['x-api-key'] = `${xApi}`;
		}
		if (noCache) {
			headers['Cache-Control'] = 'no-cache, no-store, must-revalidate, max-age=0';
		}
		if (token) {
			headers['authorization'] = `${token}`;
		} else {
			// headers['x-request-language'] = localStorage.getItem('lang');
		}
		let body: any = null;
		if (contentType === 'application/json') {
			body = JSON.stringify(data);
		} else {
			body = data;
		}
		return new Promise((resolve, reject) => {
			this.axiosInstance({
				method: method,
				url: url,
				cancelToken: new axios.CancelToken(executor),
				headers: headers,
				responseType: responseType,
				data: body
			} as AxiosRequestConfig)
				.then((response: AxiosResponse<ResponseObj<any>>) => {
					if (response) {
						if (contentType === 'application/json') {
							resolve(response.data instanceof ArrayBuffer ? response : response.data.data);
						} else {
							resolve(response.data);
						}
					} else {
						resolve({ reqCanceled: true });
					}
				})
				.catch((error: Error) => {
					reject(error);
				});
		});
	};

	getBlob = (url: string) =>
		new Promise((resolve, reject) => {
			axios
				.get(url, { responseType: 'blob' })
				.then((response: any) => {
					resolve(response.data);
				})
				.catch((error: Error) => reject(error));
		});
}

export default HttpService;

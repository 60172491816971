import { components } from 'react-select';

import { DropDownIcon } from 'shared/icons/icon';

const DropdownIndicator = (props: any) => {
	return (
		components.DropdownIndicator && (
			<components.DropdownIndicator {...props}>
				<DropDownIcon />
			</components.DropdownIndicator>
		)
	);
};

export default DropdownIndicator;

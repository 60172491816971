import { useState } from 'react';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import isEmpty from 'lodash/isEmpty';
import { t } from 'i18next';

import Button from 'shared/components/form/button';
import { Translate } from 'shared/util/translation/translate';
import localizationConstants from 'shared/util/translation/constants';
import { IAssingMaterialUsers } from '../interface/futureTable';
import CustomModal from 'shared/components/modal/modal';
import { debounce } from 'shared/util/utility';
import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/constants';
import { notify } from 'shared/components/notification/notification';

interface Props {
	onClose: () => void;
}
interface OwnProps extends Props {}
const AssignToAdmin = (props: OwnProps) => {
	const { symbol = '', boerse = '' } = useQueryParams();

	const [adminAssignData, setAdminAssignData] = useState<IAssingMaterialUsers[]>([]);
	const [isAssignDataShowData, setIsAssignDataShowData] = useState(false);
	const [selectedAdmin, setSelectedAdmin] = useState({} as IAssingMaterialUsers);
	const handleSearchAdmin = debounce((value: string) => handleUpdateFilterParams(value));

	const handleUpdateFilterParams = (value: string) => {
		if (value !== '') {
			httpService
				.put(API_CONFIG.path.getAdminUsers, {
					word: value
				})
				.then((res) => {
					setAdminAssignData(res);
					setIsAssignDataShowData(true);
				})
				.catch((err) => {
					setIsAssignDataShowData(false);
					console.error(err);
				});
		} else {
			setIsAssignDataShowData(false);
		}
	};
	const selectedAdminUser = (value: IAssingMaterialUsers) => {
		setSelectedAdmin(value);
		setIsAssignDataShowData(false);
	};
	const grantPermissionToAdmin = () => {
		const params = {
			[selectedAdmin.type === 'backpack' ? 'backpackId' : 'userId']: selectedAdmin.id,
			data: [
				{
					symbol: symbol,
					boerse: boerse
				}
			],
			expiryDate: selectedAdmin.expiryDate || null,
			target: true
		};
		const apiPath = selectedAdmin.type === 'backpack' ? API_CONFIG.path.assignBackpack : API_CONFIG.path.assignMaterialToAdmin;
		httpService
			.put(apiPath, params)
			.then(() => {
				notify(t(localizationConstants.grantPermissionToUser), 'success', { id: 'grantUser' });
				props.onClose();
			})
			.catch((err) => {
				console.error(err);
			});
	};
	return (
		<CustomModal show={true} handleClose={() => props.onClose()} className={'course-list-popup'}>
			<h2 className='modal-title font--20px line-height--28px '>
				<Translate text={localizationConstants.searchUsers} />
			</h2>
			<div className='assign_mateiral_wrapper position--relative'>
				<input
					type={'text'}
					onChange={({ target }) => handleSearchAdmin(target.value)}
					placeholder={`${t(localizationConstants.searchUsers)}`}
					className='full-width mb-20 padding-3px search-admin '
				/>

				{!isEmpty(selectedAdmin) && (
					<div className='item'>
						<p className='font--16px font-weight--20 no-margin'>
							<Translate text={localizationConstants.email} />: {selectedAdmin.email}
						</p>
						<p className='font--16px font-weight--20 no-margin'>
							<Translate text={localizationConstants.name} />: {selectedAdmin.name}
						</p>
					</div>
				)}
				{isAssignDataShowData && !isEmpty(adminAssignData) && (
					<div className='assign_mateiral_list position--absolute'>
						{adminAssignData.length > 0 ? (
							<ul>
								{adminAssignData.map((value: IAssingMaterialUsers) => {
									return (
										<li onClick={() => selectedAdminUser(value)} key={value.id}>
											<div className='item'>
												<p className='font--16px font-weight--20 no-margin'>
													<Translate text={localizationConstants.email} />: {value.email}
												</p>
												<p className='font--16px font-weight--20 no-margin'>
													<Translate text={localizationConstants.name} />: {value.name}
												</p>
											</div>
										</li>
									);
								})}
							</ul>
						) : (
							<p className='font--16px font-weight--20 no-margin'>
								<Translate text={localizationConstants.noEntriesFound} />
							</p>
						)}
					</div>
				)}
			</div>

			<div className='full-width text-align-right mt-10'>
				<Button btnType='primary' className='button-size--medium' onClick={grantPermissionToAdmin} disabled={isEmpty(selectedAdmin)}>
					<Translate text={localizationConstants.add} />
				</Button>
			</div>
		</CustomModal>
	);
};
export default AssignToAdmin;

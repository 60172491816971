import { IAnnouncmentData } from 'features/dashboard/interface/dashboard';
import React, { useCallback, useState } from 'react';
import Button from 'shared/components/form/button';

import CustomModal from 'shared/components/modal/modal';
import { MatfoxxIcon, YellowMessageIcon, YellowTeleIcon } from 'shared/icons/icon';

interface IProps {
	announcementData: IAnnouncmentData[];
}

const AnnouncementPopup: React.FC<IProps> = (props) => {
	const { announcementData } = props;
	const [index, setIndex] = useState(0);
	const [isShow, setIsShow] = useState(true);

	const handleOnClose = useCallback(() => {
		setIsShow(false);
		localStorage.setItem('isAnnouncement', JSON.stringify(0));
	}, []);

	const handleNext = useCallback(() => {
		if (announcementData.length - 1 !== index) {
			setIndex(index + 1);
		} else {
			handleOnClose();
		}
	}, [announcementData.length, handleOnClose, index]);

	return (
		<CustomModal show={isShow} className='announcement-popup' handleClose={handleOnClose}>
			<div>
				{announcementData[index] && (
					<div className='full-width flex-column d-flex  align-items-center announcement-main pr-24 pl-24'>
						<div className='matfoxx-icon'>
							<MatfoxxIcon width='90' height='125' />
						</div>
						<h2 className='font--20px font-weight--500'>{announcementData[index].heading}</h2>
						<h2 className='font--20px font-weight--500'>{announcementData[index].sub_heading}</h2>
						{announcementData[index].announcementupdate.map((item) => {
							return (
								<div className='border-bottom-grayscale full-width text-align-center'>
									<h2 className='font--20px'>{item.title}</h2>
									<h2 className='font--16px' dangerouslySetInnerHTML={{ __html: item.text?.replaceAll('\n', '<br />') }} />
								</div>
							);
						})}
					</div>
				)}
				<div className='text-align-right mt-10 mb-10'>
					{announcementData.length > 1 && (
						<Button onClick={handleNext} className='button-size--medium mr-8' type='button' btnType='primary'>
							Next
						</Button>
					)}
				</div>
				<div className='announcement-footer d-flex justify-content-space-evenly'>
					<div className='d-flex align-items-center'>
						<YellowTeleIcon className='cursor-pointer' />
						<span className='ml-8 cursor-pointer'>+49 (0) 69 271 07 60 11</span>
					</div>
					<div className='d-flex align-items-center'>
						<YellowMessageIcon className='cursor-pointer' />
						<span className='ml-8 cursor-pointer'>service@matflixx.de</span>
					</div>
				</div>
			</div>
		</CustomModal>
	);
};

export default AnnouncementPopup;

import { ATR as defaultOptions } from 'react-stockcharts/lib/calculator/defaultOptionsForComputation';
import { isDefined, isNotDefined } from 'react-stockcharts/lib/utils';

export default function compareCalculator() {
	let options = defaultOptions;

	function calculator(data: any) {
		const { mainKeys, compareKeys } = options;

		// eslint-disable-next-line prefer-const
		let firsts: any = {};
		const compareData = data.map((d: any, index: number) => {
			// eslint-disable-next-line prefer-const
			let result: any = {};

			mainKeys.forEach((key: any) => {
				if (typeof d[key] === 'object') {
					result[key] = {};
					Object.keys(d[key]).forEach((subkey) => {
						result[key][subkey] = d[key] / 100;
					});
				} else {
					result[key] = d[key] / 100;
				}
			});

			compareKeys.forEach((key: any) => {
				if (isDefined(d[key]) && isNotDefined(firsts[key])) {
					firsts[key] = d[key] / 100;
				}
				if (isDefined(d[key]) && isDefined(firsts[key])) {
					if (typeof d[key] === 'object') {
						Object.keys(d[key]).forEach((subkey) => {
							result[key][subkey] = d[key][subkey] / 100;
						});
					} else {
						result[key] = d[key] / 100;
					}
				}
				if (index === 0) {
					if (typeof d[key] === 'object') {
						Object.keys(d[key]).forEach((subkey) => {
							result[key][subkey] = 0;
						});
					} else {
						result[key] = 0; // Set the first data point to 0
					}
				}
			});
			return result;
		});

		return compareData;
	}
	calculator.options = function (x: any) {
		if (!arguments.length) {
			return options;
		}
		options = { ...defaultOptions, ...x };
		return calculator;
	};
	return calculator;
}

import isEmpty from 'lodash/isEmpty';
import React, { Dispatch, Fragment, SetStateAction, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Loader } from 'shared/components/spinner/spinner';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { LockIcon, RedCrossIcon, RedDeleteIcon } from 'shared/icons/icon';
import { State } from 'shared/interface';
import i18n from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';
import { customNumberFormatter, formatDate, replaceHashtagFromUrl } from 'shared/util/utility';
import PermissionManager from 'shared/hoc/permission/permissionManager';
import { adminPermissionMapping } from 'shared/constants/permissionConstant';

import { IChartDetails, IChartIndicators, IChartSettingsType, ISpan } from '../interface/futureTable';
import ChartInfo from './chartInfo';
import { isArray } from 'lodash';

interface IProps {
	chartDetails: IChartDetails;
	color: string;
	indicators: IChartIndicators[];
	chartSettings: IChartSettingsType[];
	scaleType: string;
	headerIndex: number;
	spanObj: ISpan;
	currency: string;
	chartType: string;
	chartColor: string;
	setChartData: (chartData: any) => void;
	chartId: string;
	setChartSettings: Dispatch<SetStateAction<IChartSettingsType[]>>;
	setAllChartDetails: (chartDetails: { [key: string]: IChartDetails }) => void;
	setIsChartSettingUpdate: () => void;
}
const ChartHeader: React.FC<IProps> = (props) => {
	const {
		chartDetails,
		color,
		indicators,
		headerIndex,
		chartSettings,
		scaleType,
		chartType,
		chartColor,
		setChartSettings,
		spanObj: { span, endDate, startDate },
		currency,
		setChartData,
		setAllChartDetails,
		chartId,
		setIsChartSettingUpdate
	} = props;
	const { language, admin, chartView } = useSelector((state: State) => state.siteConfig);
	const { t } = i18n;
	const { timeSpan: timeSpanParams, currency: currencyParams } = useQueryParams();
	const navigate = useNavigate();

	const chartUpdate: { [key: string]: string } = {
		D: `${t(localizationConstants.chartUpdateDaily)}`,
		M: `${t(localizationConstants.chartUpdateMonthly)}`,
		W: `${t(localizationConstants.chartUpdateWeekly)}`,
		Q: `${t(localizationConstants.chartUpdateQuarterly)}`,
		Y: `${t(localizationConstants.chartUpdateYearly)}`
	};

	const handleNavigationState = useCallback(
		(data: IChartSettingsType[]) => {
			if (data.length > 1) {
				const locationSpan = chartType === 'bar' ? (span === '1y' || span === '1m' || span === '1w' ? span : '1y') : span;
				navigate(`/materials/chartScreen?timeSpan=${timeSpanParams || span}&currency=${currencyParams}`, {
					state: {
						advanceChartConfig: { chartSettings: data, scaleType: chartView },
						chartSelection: { type: chartView === 'percentageChange' ? 'line' : chartType, span: locationSpan }
					}
				});
			} else if (chartSettings.length > 1 && data.length === 1) {
				const { boerse, symbol, basis_symbol } = data[0];
				setIsChartSettingUpdate();
				const navigationPath = `/materials/futureTable?boerse=${replaceHashtagFromUrl(boerse)}&basis_symbol=${
					basis_symbol ? replaceHashtagFromUrl(basis_symbol) : ''
				}&symbol=${replaceHashtagFromUrl(symbol)}&currency=${currency}&timeSpan=${timeSpanParams || span}`;
				navigate(navigationPath, {
					state: location.pathname.includes('chartScreen')
						? {
								advanceChartConfig: { chartSettings: data, scaleType: scaleType },
								chartSelection: {
									currency: data[0].currency.value,
									indicator: data[0]?.indicators || [],
									span: span,
									type: chartView === 'percentageChange' ? 'line' : chartType,
									chartColor: data[0]?.color || chartColor,
									unit: data[0]?.unit.value || '',
									history: data[0]?.history.value || '',
									axis: data[0]?.axis.value || 'right',
									customChartSpan: {
										von: startDate,
										bis: endDate
									}
								}
						  }
						: {}
				});
			} else if (chartSettings.length === 1 && data.length === 1) {
				setChartSettings(data);
			}
		},
		[
			chartSettings.length,
			chartType,
			span,
			navigate,
			timeSpanParams,
			currencyParams,
			chartView,
			setIsChartSettingUpdate,
			currency,
			scaleType,
			chartColor,
			startDate,
			endDate,
			setChartSettings
		]
	);

	const deleteChartHeader = useCallback(() => {
		setChartData([]);
		setAllChartDetails({});
		const tempAdvanceChartConfig = [...chartSettings];
		const advanceChartConfigIndex = tempAdvanceChartConfig.findIndex((item) => `${item.id}` === `${chartSettings[headerIndex].id}`);
		tempAdvanceChartConfig.splice(advanceChartConfigIndex, 1);
		handleNavigationState(
			tempAdvanceChartConfig.map((item: any) => {
				return {
					...item,
					axis: {
						value: chartView === 'percentageChange' ? 'left' : item.axis.value,
						label: chartView === 'percentageChange' ? 'Left' : item.axis.label
					}
				};
			})
		); // Set navigation state
	}, [chartSettings, chartView, handleNavigationState, headerIndex, setAllChartDetails, setChartData]);

	const deleteIndicators = useCallback(
		(index: number) => {
			const tempIndicatorArray = [...chartSettings[headerIndex].indicators];
			tempIndicatorArray.splice(index, 1);
			// update indicators element in  advance chart config
			const tempAdvanceChartConfig = [...chartSettings];
			const advanceChartConfigIndex = tempAdvanceChartConfig.findIndex(
				(item) =>
					`${item.symbol}-${item.boerse}-${item.id}` ===
					`${chartSettings[headerIndex].symbol}-${chartSettings[headerIndex].boerse}-${chartSettings[headerIndex].id}`
			);
			const chartObj = { ...tempAdvanceChartConfig[advanceChartConfigIndex], indicators: tempIndicatorArray };
			tempAdvanceChartConfig.splice(advanceChartConfigIndex, 1, chartObj);
			handleNavigationState(tempAdvanceChartConfig); // Set navigation state
		},
		[chartSettings, handleNavigationState, headerIndex]
	);

	const isAdminRight = useMemo(() => isArray(admin) && (admin.includes('superAdmin') || admin.includes('dataAdmin')), [admin]);

	return (
		<Fragment>
			<div className={`graph-header d-flex justify-content-space-between bg-color--white ${isEmpty(indicators) ? 'mb-20' : 'mb-8'}`}>
				{isEmpty(chartDetails) && <Loader className='full-width height--77px' />}
				{!isEmpty(chartDetails) && (
					<>
						<div className='chart-title__wrapper d-flex width--80'>
							<div
								className='color-div'
								style={{
									backgroundColor: color
								}}
							/>
							<div className='graph-title align-items-center position--relative d-flex'>
								<h2 className='d-flex align-items-center font--16px full-width line--height--22px font-weight--500 no-margin pl--25'>
									{(language === 'en' && chartDetails.name_eng !== '' && chartDetails.name_eng) || chartDetails.name}
									{(chartDetails.info !== '' || admin.length) && (
										<span>
											<ChartInfo chartDetails={chartDetails} chartId={chartId} />
										</span>
									)}
								</h2>
							</div>
						</div>
						<div
							className={`graph-price d-flex align-items-center ${
								chartDetails.disallowed === 0 ? 'justify-content-space-between' : 'justify-content-end'
							}`}
						>
							{chartDetails.disallowed === 1 && (
								<div className='d-flex justify-content-center align-items-center mr-20'>
									<LockIcon />
								</div>
							)}
							{chartDetails.disallowed === 0 && (
								<div className='d-flex mb-5px mt-5px justify-content-start flex-column align-items-start'>
									<h2 className='font--16px no-margin line--height--22px font-weight--500 d-flex justify-content-end align-items-center'>
										{customNumberFormatter(chartDetails.price, chartDetails.nkstellen) || 0}

										{!['null', null].includes(chartDetails.waehrung) && (
											<>
												<span className='font--16px font-weight--500 line-height--22px'>
													{`${chartDetails.waehrung} /` || ''}
												</span>
												<span className='font--16px font-weight--500 line-height--22px '>{chartDetails.einheit}</span>
											</>
										)}
									</h2>
									<h5 className='font--16px no-margin line--height--22px font-weight--500 mb-5px'>
										{`${formatDate(chartDetails.kurszeit?.split('T')[0], language === 'de' ? 'DD.MM.YYYY' : 'MM/DD/YYYY')}`}
									</h5>

									{chartDetails.notierung && (
										<h4 className='font-weight--400 font--16px no-margin line--height--22px mb-5px'>
											<Translate text={localizationConstants.update} />: {chartUpdate[chartDetails.notierung]}
										</h4>
									)}
									{isAdminRight && (
										<PermissionManager permission={adminPermissionMapping.superDataUserAdmin}>
											<h2 className='no-margin font--12px br--6px bg-light-orange padding-5 d-flex justify-content-center align-items-center '>
												Code: {chartDetails.code}
											</h2>
										</PermissionManager>
									)}
								</div>
							)}
							{chartSettings.length > 1 && (
								<button
									disabled={chartSettings.length <= 1}
									onClick={deleteChartHeader}
									className='delete-icon--container d-flex cursor-pointer'
								>
									<RedDeleteIcon />
								</button>
							)}
						</div>
					</>
				)}
			</div>
			{!isEmpty(indicators) && (
				<div className='indicators-container'>
					<div className='d-flex flex-wrap pl-72'>
						{indicators.map((item, index) => {
							return (
								<div className={`indicator-main mt-8`} key={`${item.symbol}-${item.boerse}-${index}`}>
									<div
										style={{
											backgroundColor: color,
											opacity: 100 - (index + 1) * 10 + '%',
											width: '20px',
											height: '100%',
											borderRadius: '2px 0 0 2px'
										}}
									/>
									<h3>
										{item.value.toUpperCase()} (
										{item.value !== 'macd' && item.value !== 'sto'
											? item.calculatedValue
											: item.value === 'sto'
											? `${item.tage},${item.k}`
											: `${item.kurz},${item.lang}`}
										){' '}
									</h3>
									<span onClick={() => deleteIndicators(index)} className='cross-icon cursor-pointer d-flex '>
										<RedCrossIcon />
									</span>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default ChartHeader;

// {chartDetails.faelligkeit && (
// 	<h2 className='maturity-text no-margin mb-8 font--16px padding-5 d-flex justify-content-center align-items-center'>
// 		<Translate text={localizationConstants.maturity} />: {chartDetails.faelligkeit}
// 	</h2>
// )}
{
	/* {chartDetails.notierung_zusatz && (
	<h4 className='font-weight--400 no-margin'>
	<Translate text={localizationConstants.additionalInfo} />: {chartDetails.notierung_zusatz}
	</h4>
)} */
}

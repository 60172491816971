import { ArcElement, Chart, ChartData, ChartOptions, LayoutPosition, Legend, Tooltip, TooltipItem } from 'chart.js';
import { isEmpty, orderBy, round } from 'lodash';
import React, { useMemo, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';

import EmptyDataContainer from 'shared/components/emptyDataContainer/emptyDataContainer';
import { Loader } from 'shared/components/spinner/spinner';

import { ICostModal } from '../interface/costModal';
import PriceDriveComponent from './priceDriveComponent';

export interface IProductValue {
	name: string;
	value: number;
	productPrice: number;
	amount: number;
}

Chart.register(ArcElement, Legend, Tooltip);

export const chartColors = ['#FF9100', '#84878B', '#999CA2', '#D4D6D9', '#61656A', '#44474D', '#272A30', '#081D34', '#EDEEEF', '#40AD1A'];

interface centerTextDesign {
	fontWeight: string;
	size: number;
	fontColor: string;
	lineHeight: number;
}

interface IProps {
	position: LayoutPosition;
	id: number;
	align: 'start' | 'center' | 'end' | undefined;
	legendsPadding: number;
	textDesign: centerTextDesign[];
	action: string;
	dougnutChartData: ICostModal;
	loading: boolean;
}

const DoughnutChartContainer: React.FC<IProps> = (props) => {
	const chartRef = useRef<any>(null);
	const { position, align, legendsPadding, action, dougnutChartData, loading } = props;

	const productValues: IProductValue[] = useMemo(() => {
		let totalPrice = 0;
		let totalAmount = 0;
		if (!isEmpty(dougnutChartData)) {
			dougnutChartData.costmodalingridients.map((ingridient) => {
				const totalPriceOfProduct = ingridient.amount * ingridient.productPrice;
				totalPrice += totalPriceOfProduct;
				totalAmount += ingridient.amount;
			});
			return dougnutChartData.costmodalingridients.map((ingridient) => {
				const totalPriceOfProduct = ingridient.amount * ingridient.productPrice;
				return {
					value: round((totalPriceOfProduct * 100) / totalPrice, 2),
					name: ingridient.ingridients_name,
					productPrice: ingridient.productPrice,
					amount: round((ingridient.amount * 100) / totalAmount, 2)
				};
			});
		} else {
			return [];
		}
	}, [dougnutChartData]);

	// const centerTextPlugin = {
	// 	id: 'centerText', // Plugin ID
	// 	beforeDraw: (chart: Chart) => {
	// 		const width = chart.chartArea.width;
	// 		const height = chart.chartArea.height;
	// 		const ctx = chart.ctx as CanvasRenderingContext2D;
	// 		const maxValueProduct = maxBy(productValues, (value) => value.value);
	// 		// Center of the chart
	// 		const centerX = width / 2 + chart.chartArea.left;
	// 		const centerY = (height + 15) / 2 + chart.chartArea.top;

	// 		// Text to display
	// 		const text = [
	// 			{
	// 				content: `${maxValueProduct?.amount}%`,
	// 				fontWeight: props.textDesign[0].fontWeight,
	// 				size: props.textDesign[0].size,
	// 				fontColor: props.textDesign[0].fontColor,
	// 				lineHeight: props.textDesign[0].lineHeight
	// 			},
	// 			{
	// 				content: maxValueProduct?.name,
	// 				fontWeight: props.textDesign[1].fontWeight,
	// 				size: props.textDesign[1].size,
	// 				fontColor: props.textDesign[1].fontColor,
	// 				lineHeight: props.textDesign[1].lineHeight
	// 			},
	// 			{
	// 				content: `${maxValueProduct?.productPrice && customNumberFormatter(maxValueProduct?.productPrice, 2)} ${dougnutChartData.currency
	// 					} / Unit`,
	// 				fontWeight: props.textDesign[2].fontWeight,
	// 				size: props.textDesign[2].size,
	// 				fontColor: props.textDesign[2].fontColor,
	// 				lineHeight: props.textDesign[2].lineHeight
	// 			}
	// 		];

	// 		for (let i = 0; i < text.length; i++) {
	// 			const { fontColor, fontWeight, size, lineHeight, content } = text[i];
	// 			ctx.font = `${fontWeight} ${size}px Outfit`;
	// 			ctx.textAlign = 'center';
	// 			// Text color
	// 			ctx.fillStyle = fontColor;
	// 			// Calculate vertical position of text
	// 			const startY = centerY - lineHeight;
	// 			// Draw each line of text
	// 			ctx.fillText(content || '', centerX, startY + i * lineHeight);
	// 		}
	// 	}
	// };

	const data: ChartData<'doughnut'> = {
		labels: (productValues.length > 0 && productValues.map((value) => value.name)) || [],
		datasets: [
			{
				data: (productValues.length > 0 && orderBy(productValues, 'amount', 'desc').map((value) => value.amount)) || [],
				backgroundColor: chartColors,
				hoverBackgroundColor: chartColors
			}
		]
	};

	const options: ChartOptions<'doughnut'> = {
		cutout: '78%',
		elements: {
			arc: {
				borderWidth: 0
			}
		},
		aspectRatio: 0,
		maintainAspectRatio: false,
		responsive: true,
		plugins: {
			legend: {
				display: true,
				position: position,
				align: align,
				labels: {
					boxHeight: 10,
					boxWidth: 10,
					padding: legendsPadding,
					// boxPadding: 6,
					color: '#44474D',
					font: {
						size: 12,
						lineHeight: 17,
						weight: 400,
						family: 'Outfit'
					}
				}
			},
			tooltip: {
				enabled: true,
				axis: 'xy',
				position: 'average',
				usePointStyle: true,
				backgroundColor: '#FFFFFF',
				bodyAlign: 'center',
				displayColors: false,
				titleColor: '#081D34',
				bodyColor: '#081D34',
				titleAlign: 'center',
				padding: 4,
				footerColor: '#61656A',
				bodyFont: {
					weight: 500,
					size: 10
				},
				footerFont: {
					weight: 400,
					size: 9
				},
				titleFont: {
					weight: 600,
					size: 12
				},
				footerAlign: 'center',
				callbacks: {
					title: (tooltipItem: TooltipItem<'doughnut'>[]) => {
						return `${orderBy(productValues, 'amount', 'desc')[tooltipItem[0].dataIndex].amount}%`;
					},

					label: (tooltipItem: TooltipItem<'doughnut'>) => {
						return `${productValues[tooltipItem.dataIndex].name}`;
					},
					labelColor: () => {
						return {
							borderColor: '',
							backgroundColor: '#081D34',
							fontWeight: '500' // add fontWeight property
						};
					},
					footer: (tooltipItem: TooltipItem<'doughnut'>[]) => {
						return `${round(productValues[tooltipItem[0].dataIndex].productPrice, 2)} ${dougnutChartData.currency} / Unit`;
					}
				}
			}
		}
	};

	return (
		<>
			{loading && <Loader />}
			{isEmpty(dougnutChartData) && !loading && <EmptyDataContainer />}
			{action === 'chart' && !isEmpty(dougnutChartData) && (
				<Doughnut ref={chartRef} data={data} options={options} /* plugins={[centerTextPlugin]} */ />
			)}
			{action === 'price' && !isEmpty(dougnutChartData) && (
				<div className='cost-modal-card__wrapper d-flex justify-content-center align-items-center'>
					<PriceDriveComponent productValues={productValues} chartColors={chartColors} currancy={dougnutChartData.currency} />
				</div>
			)}
		</>
	);
};

export default DoughnutChartContainer;

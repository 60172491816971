import moment from 'moment';
import { useEffect, useState } from 'react';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';
import Button from 'shared/components/form/button';
import { PbBody, PbCell, PbHead, PbRow, TableSpinner } from 'shared/components/table';
import { API_CONFIG } from 'shared/constants/constants';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import HttpService from 'shared/services/http.service';
import i18n from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';
import { customNumberFormatter } from 'shared/util/utility';
import { IChartData, IChartDetails, IChartItem } from '../interface/futureTable';
import { useSelector } from 'react-redux';
import { State } from 'shared/interface';

function EditableCell({ value, row: { index }, column: { id }, updateMyData, name, setChangedValues, changedValues }: any) {
	const [fieldValue, setFieldValue] = useState(parseFloat(value));
	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFieldValue(parseFloat(e.target.value));
	};

	const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		changedValues.map((value: { date: string; amount: number }) => {
			if (value.date === e.target.name) {
				changedValues.splice(index, 1);
			}
		});
		setChangedValues([
			...changedValues,
			{
				date: e.target.name,
				amount: e.target.value
			}
		]);
		updateMyData(index, id, e.target.value);
	};

	return (
		<input
			name={name}
			value={fieldValue}
			type='number'
			className='form-field mr-8 max-width-150px'
			placeholder={value}
			onChange={onChange}
			onBlur={onBlur}
		/>
	);
}

function Table({ columns, data, setSlicedData, handleNextData, hasMore, changedValues, setChangedValues, chartDetails, loading }: any) {
	const updateMyData = (rowIndex: number, columnId: string, value: string) => {
		// We also turn on the flag to not reset the page
		setSlicedData((old: IChartData[]) => {
			return old.map((row, index) => {
				if (index === rowIndex) {
					return {
						...row,
						[chartDetails.mainField]: value
					};
				}
				return row;
			});
		});
	};
	const { language } = useSelector((state: State) => state.siteConfig);
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{ columns, data },
		useGlobalFilter,
		useSortBy /* useGlobalFilter, useSortBy */
	);
	return (
		<div className='table-responsive material-table_responsive' id='scrollableDiv'>
			<table className='table table-bordered table-hover dataTables-example dataTable' {...getTableProps()}>
				<PbHead>
					{headerGroups.map((headerGroup) => (
						<PbRow {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column: any) => (
								<PbCell
									header
									{...column.getHeaderProps(column.getSortByToggleProps())}
									className='text-align-center bg-color--greyscale-10 font--12px line-height--17px font-weight--400'
								>
									{column.render('Header')}
									<span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
								</PbCell>
							))}
						</PbRow>
					))}
				</PbHead>
				<PbBody {...getTableBodyProps()}>
					{loading && <TableSpinner colSpan={3} />}
					{!loading &&
						rows.map((row: any) => {
							prepareRow(row);
							return (
								<PbRow {...row.getRowProps()}>
									{row.cells.map((cell: any) => {
										if (cell.column.editable) {
											const materialValue = parseFloat(cell.value.replaceAll(',', '.'));
											return (
												<PbCell {...cell.getCellProps()} className='font--12px line-height--17px font-weight--400'>
													<EditableCell
														name={language === 'de' ? cell.row.values.Datum : cell.row.values.Date}
														setChangedValues={setChangedValues}
														changedValues={changedValues}
														value={materialValue}
														row={row}
														column={cell.column}
														updateMyData={updateMyData}
													/>
												</PbCell>
											);
										} else {
											return (
												<PbCell {...cell.getCellProps()} className='font--12px line-height--17px font-weight--400'>
													{cell.render('Cell')}
												</PbCell>
											);
										}
									})}
								</PbRow>
							);
						})}
				</PbBody>
			</table>
			{hasMore && (
				<div className='full-width text-align-center'>
					<Button type='button' className='button-size--medium color-grayscale-60 mt-16 mb-16' onClick={handleNextData}>
						<Translate text={localizationConstants.loadMore} />
					</Button>
				</div>
			)}
		</div>
	);
}

interface IProps {
	loading: boolean;
	chartData: IChartData[];
	saveMode: boolean;
	chartDetails: IChartDetails;
	setIsChartSettingUpdate: () => void;
}

const MaterialTable: React.FC<IProps> = (props) => {
	const [startIndex, setStartIndex] = useState(0);
	const [slicedData, setSlicedData] = useState<IChartData[]>([]);
	const [hasMore, setHasMore] = useState(true);
	const [changedValues, setChangedValues] = useState<{ date: string; amount: number }[]>([]);
	const { chartData, saveMode, chartDetails, loading, setIsChartSettingUpdate } = props;
	const [, setActionLoading] = useState(false);
	const { boerse, symbol } = useQueryParams();
	const { dateFormat } = useSelector((state: State) => state.siteConfig);
	const { t } = i18n;

	useEffect(() => {
		if (!saveMode && changedValues.length > 0) {
			setActionLoading(false);
			const payload = {
				symbol: symbol,
				boerse: boerse,
				data: changedValues,
				data_formate: dateFormat
			};
			HttpService.put(API_CONFIG.path.updateHistoryData, payload)
				.then(() => {
					setActionLoading(false);
					setChangedValues([]);
					setIsChartSettingUpdate();
				})
				.catch(() => setActionLoading(false));
		}
	}, [changedValues, symbol, boerse, saveMode]);

	const columns = [
		{
			Header: `${t(localizationConstants.date)}`,
			accessor: (data: IChartData) => moment(data.date).format(dateFormat)
		},
		{
			Header: `${t(localizationConstants.price)}`,
			accessor: (data: IChartData) => customNumberFormatter((data[`cv${chartDetails.symbol}-${chartDetails.boerse}-0`] as IChartItem)?.l, 3),
			editable: saveMode
		},
		{
			Header: `${t(localizationConstants.change)}`,
			accessor: (data: IChartData) => (
				<p
					className={`no - margin font--12px line - height--17px font - weight--400 ${
						(data[`cv${chartDetails.symbol}-${chartDetails.boerse}-0`] as IChartItem)?.p >= 0
							? 'color--success-color'
							: 'color--error-color'
					}`}
				>
					{customNumberFormatter((data[`cv${chartDetails.symbol}-${chartDetails.boerse}-0`] as IChartItem)?.p, 2)}%
				</p>
			)
		}
	];
	useEffect(() => {
		handleNextData();
	}, []);

	const handleNextData = () => {
		const endIndex = Math.min(startIndex + 20, chartData.length);
		const nextBatch = chartData.slice(startIndex, endIndex);
		setSlicedData((prevData) => {
			return [...prevData, ...nextBatch];
		});
		setHasMore(endIndex < chartData.length);
		setStartIndex(endIndex);
	};
	return (
		<Table
			columns={columns}
			data={slicedData}
			setSlicedData={setSlicedData}
			handleNextData={handleNextData}
			hasMore={hasMore}
			changedValues={changedValues}
			setChangedValues={setChangedValues}
			chartDetails={chartDetails}
			loading={loading}
		/>
	);
};

export default MaterialTable;

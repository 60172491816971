import isEmpty from 'lodash/isEmpty';

import { allPermissionMapping } from 'shared/constants/permissionConstant';
import AuthService from 'shared/services/auth.service';

import { IUserWhiteLabelConfig, LoginResponse } from 'features/login/interface/login';

export interface AllPermissionRequired {
	permission: string[];
}

/**
 * check if user has permission to access any block
 * @param permission : a list of permissions, if user is not having any one permission of the given permissions, return false
 * @param anyOnePermission : a list of optional permissions, if user is having atleast one permission of the given permissions, return true
 */
const checkPermission = (data: AllPermissionRequired): boolean => {
	const userData: LoginResponse = AuthService.getAuthData() as LoginResponse;
	const { whitelable_config } = userData;

	if (!userData || !whitelable_config) {
		return false;
	}
	if (isEmpty(whitelable_config)) {
		return true;
	} else {
		const allPermissionData = data as AllPermissionRequired;

		// if user is not having any one permission of the given permissions, return false
		for (let i = 0; i < allPermissionData.permission.length; i += 1) {
			if (whitelable_config[allPermissionMapping[allPermissionData.permission[i]] as keyof IUserWhiteLabelConfig] !== 'hideall') {
				return true;
			}
		}
		return false;
	}
};

export { checkPermission };

import React from 'react';
import { Link } from 'react-router-dom';
import { BlindMatfoxx } from 'shared/icons/icon';
import Button from '../form/button';

const NotFound: React.FC = () => (
	<>
		<div className='not-found__wrapper text-center animated fadeInDown d-flex justify-content-center align-items-center flex-column'>
			<h1 className='color-white font--371px line-height--22px font-weight--900'>
				4
				<span>
					<BlindMatfoxx className='blind-matfoxx-icon' />
				</span>
				4
			</h1>
			<h3 className='font-bold color-white font--60px line-height--22px font-weight--600 mt-20'>Hier gibt es wohl nichts.</h3>
			<Link to='/'>
				<Button btnType='accent' className='button-size--medium mt-95'>
					Zurück
				</Button>
			</Link>
		</div>
	</>
);

export default NotFound;

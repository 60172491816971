const CustomOption = ({ innerProps, label, data }: any) => {
	return (
		<div {...innerProps} className='padding-8 border-bottom--2px-solid-grayscale-30'>
			<span>Name: {label}</span>
			{data.component && <data.component />}
		</div>
	);
};

export default CustomOption;

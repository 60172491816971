import React from 'react';

interface Props {
	name: string;
	id: string;
	value: string | number;
	onClick?: () => void;
}
const Option: React.FC<Props> = (props) => (
	<option value={props.value} onClick={props.onClick}>
		{props.name}
	</option>
);
export default Option;

import { isEmpty } from 'lodash';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { FilledStar, UnfilledStar } from 'shared/icons/icon';
import { CardView } from 'shared/interface/enums';

interface IProps {
	data: any;
	cardIndex: number;
	section: string;
	className: string;
	view: string;
	rubrics: string;
	sectorId: any;
	onSaveTender: (newsId: number, action: boolean, index: number, section: string, rubrics: string) => void;
	financersId: number[];
	isSaved: boolean;
}

export const TenderContent: FC<IProps> = ({ rubrics, data, section, className, cardIndex, view, sectorId, financersId, isSaved, onSaveTender }) => {
	const navigate = useNavigate();
	const navigationRoute = `/tenders/${isSaved ? 'saveTenders' : 'tenderList'}/${rubrics}?continentId=${data.country.continentId}${
		isEmpty(sectorId) ? `&countryId=${data.country.id}` : `&sectorId=${sectorId.toString()}`
	}&financerId=${financersId.toString()}&tenderId=${data.id}`;

	return (
		<div key={data.id} className={`${className || ''}`}>
			<div className='news-data__wrapper tender-data__wrapper d-flex justify-content-space-between align-items-center overflow-hidden'>
				<div className='news-data cursor-pointer' onClick={() => navigate(navigationRoute)}>
					<h1
						className={`font--16px line - height - 22px font - weight--500 no - margin cursor - pointer ${
							(cardIndex > 1 || view === CardView.LIST_VIEW) && 'truncate--lines-2'
						} `}
					>
						{data.title}
					</h1>
				</div>
				<div
					className='star-icon__wrapper cursor-pointer'
					onClick={() => onSaveTender(data.id, !data.bookmarkStatus, cardIndex, section, rubrics)}
				>
					{data.bookmarkStatus ? <FilledStar className='filled-star-icon' /> : <UnfilledStar className='unfilled-star-icon' />}
				</div>
			</div>
		</div>
	);
};

import { ErrorMessage, FieldArray, FormikErrors, FormikValues } from 'formik';
import size from 'lodash/size';
import React from 'react';
import { Tooltip } from 'react-tooltip';

import FieldErrorMessage from 'shared/components/form/error';
import { Input } from 'shared/components/form/inputTypes';
import { PbCell, PbRow } from 'shared/components/table';
import { QuestionMarkIcon, RedCrossIcon } from 'shared/icons/icon';
import i18n from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';

import { IChartSettingsType, IToolTipMessageMapper } from '../interface/futureTable';
interface IIndicatorRow {
	index: number;
	values: FormikValues;
	setFieldValue: (name: string, value: number) => void;
	errors: FormikErrors<{ scaleType: string; chartSettings: IChartSettingsType[] }>;
}
const { t } = i18n;
const indicatorTooltipMessageMapper: IToolTipMessageMapper = {
	ema: `${t(localizationConstants.emaToolTipMessage)}`,
	sma: `${t(localizationConstants.smaToolTipMessage)}`,
	sto: `${t(localizationConstants.stoToolTipMessage)}`,
	macd: `${t(localizationConstants.macdToolTipMessage)}`,
	rsi: `${t(localizationConstants.rsiToolTipMessage)}`,
	bb: `${t(localizationConstants.bbToolTipMessage)}`,
	tma: `${t(localizationConstants.tmaToolTipMessage)}`,
	wma: `${t(localizationConstants.wmaToolTipMessage)}`,
	atr: `${t(localizationConstants.atrToolTipMessage)}`
};

const IndicatorRow: React.FC<IIndicatorRow> = (props) => {
	const { index, values, setFieldValue } = props;
	return (
		<FieldArray
			name={`chartSettings[${index}].indicators`}
			render={(arrayHelpers) => {
				return values.chartSettings[index].indicators.map((item: any, i: number) => {
					return (
						<PbRow className='chart-details-row bg-color--white br--6px mb-17 indicator-row'>
							<PbCell className='bg-color--white no-border  no-padding' />
							<PbCell
								colSpan={3}
								className={`no-border no-padding mb-8 ${i === size(values.chartSettings[index].indicators) - 1 && 'mb-17'}`}
								style={{
									borderRadius: '6px'
								}}
							>
								<div className='no-border no-padding mb-8 d-flex indicator-div'>
									<div
										className='width--32px indicator-color'
										style={{
											backgroundColor: values.chartSettings[index].color,
											opacity: 100 - (i + 1) * 10 + '%'
										}}
									/>
									<div className='d-flex align-items-center bg-color--greyscale-10'>
										<h4 className='indicator-title position--relative font--12px font-weight--400 line-height--20px ml-24'>
											{item.label}
											<QuestionMarkIcon className='position--absolute ml-5' id={item.value} />
											<Tooltip
												anchorId={item.value}
												place='top'
												className='no-padding duplicate-chart-tooltip'
												content={indicatorTooltipMessageMapper[item.value]}
											/>
										</h4>
										{item.value === 'sto' || item.value === 'macd' ? (
											<div className='d-flex'>
												<div className='position--relative'>
													<Input
														type='number'
														name={
															item.value === 'sto'
																? `chartSettings[${index}].indicators[${i}].datum`
																: `chartSettings[${index}].indicators[${i}].kurz`
														}
														setFieldValue={setFieldValue}
														placeholder={
															item.value === 'sto'
																? `${t(localizationConstants.date)}`
																: `${t(localizationConstants.short)}`
														}
														className='width-83px bg-color--white'
														min={1}
														onChange={({ target }) => {
															setFieldValue(
																item.value === 'sto'
																	? `chartSettings[${index}].indicators[${i}].datum`
																	: `chartSettings[${index}].indicators[${i}].kurz`,
																parseInt(target.value)
															);
														}}
														config={{
															name:
																item.value === 'sto'
																	? `chartSettings[${index}].indicators[${i}].datum`
																	: `chartSettings[${index}].indicators[${i}].kurz`,
															type: 'number',
															hideErrorMessage: true
														}}
													/>
												</div>
												<div className='position--relative'>
													<Input
														type='number'
														name={
															item.value === 'sto'
																? `chartSettings[${index}].indicators[${i}].tage`
																: `chartSettings[${index}].indicators[${i}].signal`
														}
														setFieldValue={setFieldValue}
														placeholder={
															item.value === 'sto'
																? `${t(localizationConstants.days)}`
																: `${t(localizationConstants.signal)}`
														}
														className='width--70px bg-color--white'
														min={1}
														onChange={({ target }) => {
															setFieldValue(
																item.value === 'sto'
																	? `chartSettings[${index}].indicators[${i}].tage`
																	: `chartSettings[${index}].indicators[${i}].signal`,
																parseInt(target.value)
															);
														}}
														config={{
															name:
																item.value === 'sto'
																	? `chartSettings[${index}].indicators[${i}].tage`
																	: `chartSettings[${index}].indicators[${i}].signal`,
															type: 'number',
															hideErrorMessage: true
														}}
													/>
												</div>
												<div className='position--relative'>
													<Input
														type='number'
														name={
															item.value === 'sto'
																? `chartSettings[${index}].indicators[${i}].k`
																: `chartSettings[${index}].indicators[${i}].lang`
														}
														setFieldValue={setFieldValue}
														placeholder={item.value === 'sto' ? 'K' : `${t(localizationConstants.long)}`}
														className='width--60px bg-color--white'
														min={1}
														onChange={({ target }) => {
															setFieldValue(
																item.value === 'sto'
																	? `chartSettings[${index}].indicators[${i}].k`
																	: `chartSettings[${index}].indicators[${i}].lang`,
																parseInt(target.value)
															);
														}}
														config={{
															name:
																item.value === 'sto'
																	? `chartSettings[${index}].indicators[${i}].k`
																	: `chartSettings[${index}].indicators[${i}].lang`,
															type: 'number',
															hideErrorMessage: true
														}}
													/>
												</div>
											</div>
										) : (
											<div className='position--relative'>
												<Input
													type='number'
													name={`chartSettings[${index}].indicators[${i}].calculatedValue`}
													setFieldValue={setFieldValue}
													placeholder={item.value}
													className='width--60px bg-color--white'
													min={1}
													onChange={({ target }) => {
														setFieldValue(
															`chartSettings[${index}].indicators[${i}].calculatedValue`,
															parseInt(target.value)
														);
													}}
													config={{
														name: `chartSettings[${index}].indicators[${i}].calculatedValue`,
														type: 'number',
														hideErrorMessage: true
													}}
												/>
											</div>
										)}
										<div className='red-cross-container' onClick={() => arrayHelpers.remove(i)}>
											<RedCrossIcon />
										</div>
									</div>
								</div>
								<ErrorMessage
									className='full-width'
									name={`chartSettings[${index}].indicators[${i}].calculatedValue`}
									component={FieldErrorMessage}
								/>
								{item.value === 'sto' && (
									<>
										<ErrorMessage
											className='full-width'
											name={`chartSettings[${index}].indicators[${i}].datum`}
											component={FieldErrorMessage}
										/>
										<ErrorMessage
											className='full-width'
											name={`chartSettings[${index}].indicators[${i}].tage`}
											component={FieldErrorMessage}
										/>
										<ErrorMessage
											className='full-width'
											name={`chartSettings[${index}].indicators[${i}].k`}
											component={FieldErrorMessage}
										/>
									</>
								)}
								{item.value === 'macd' && (
									<>
										<ErrorMessage
											className='full-width'
											name={`chartSettings[${index}].indicators[${i}].kurz`}
											component={FieldErrorMessage}
										/>
										<ErrorMessage
											className='full-width'
											name={`chartSettings[${index}].indicators[${i}].signal`}
											component={FieldErrorMessage}
										/>
										<ErrorMessage
											className='full-width'
											name={`chartSettings[${index}].indicators[${i}].lang`}
											component={FieldErrorMessage}
										/>
									</>
								)}
							</PbCell>
						</PbRow>
					);
				});
			}}
		/>
	);
};

export default IndicatorRow;

import React, { Fragment } from 'react';

import { useWhitelabelConfigStatus } from 'shared/hooks/useWhitelabelConfigStatus';

import ForecastList from 'features/forecast/component/forecastList';
import WhiteLabelForecastList from '../component/whitelabelForecastList';

const AllForecastBoxContainer: React.FC = () => {
	const { forecast_status } = useWhitelabelConfigStatus();
	return (
		<Fragment>
			{(forecast_status === 'active' || forecast_status === 'active+original') && <WhiteLabelForecastList />}
			{(!forecast_status || forecast_status === 'inactive' || forecast_status === 'active+original') && <ForecastList />}
		</Fragment>
	);
};

export default AllForecastBoxContainer;

import React from 'react';

interface IProps {
	data: any;
	content: any;
}

const HoverTooltipSVG: React.FC<IProps> = (props) => {
	const { content, data } = props;
	const { fontSize, fontFamily, fontFill } = data;
	const X = 10;
	const startY = 20;
	const tspans = [];
	const squareSize = 9; // Size of the square box
	let position = 0;
	for (let i = 0; i < content.y.length; i++) {
		const y = content.y[i];
		const labelPositionY = startY + fontSize + position - (i !== 0 ? 10 : 0) + (i !== 0 && content.y[i - 1].indicator.length > 0 ? fontSize : 0);
		const boxPositionY = labelPositionY - squareSize;
		position = labelPositionY;
		let positionX = 0;
		if (y.isShowLabel) {
			tspans.push(
				<g key={`Group-${i}`} x={X} y={startY}>
					<defs>
						<filter id='boxShado-w2'>
							<feDropShadow dx={0} dy={4} stdDeviation={4} floodColor='rgba(0, 0, 0, 0.25)' />
						</filter>
						<filter id='boxShadow2' x='-25%' y='-25%' width='150%' height='150%'>
							<feFlood floodColor='rgba(0, 0, 0, 0.25)' result='shadowColor' />
							<feComposite in='shadowColor' in2='SourceGraphic' operator='in' result='shadow' />
							<feGaussianBlur in='shadow' stdDeviation='4' result='blurredShadow' />
							<feOffset in='blurredShadow' dx='4' dy='4' result='offsetBlurredShadow' />
							<feMerge>
								<feMergeNode in='SourceGraphic' />
								<feMergeNode />
							</feMerge>
						</filter>
					</defs>
					<g y={startY}>
						<rect
							x={X} // Adjust the x position of the box
							y={boxPositionY}
							width={squareSize}
							height={squareSize}
							rx={1}
							ry={1}
							fill={y.stroke}
							filter='url(#boxShadow2)'
							style={{ stroke: 'none' }}
						/>
						<text
							key={`L-${i}`}
							x={X + squareSize + 10} // Adjust the x position of the text
							y={labelPositionY}
							fontFamily={'Outfit'}
							fontSize={fontSize}
							fill={fontFill}
							fontWeight={400}
						>
							<tspan>{`${y.label.split('$')[0]}: `}</tspan>
							<tspan fontWeight={700}>{`${y.value}`}</tspan>{' '}
						</text>
						{y.indicator.map((indi: any, index: number) => {
							const indicatorBoxPostionX =
								X +
								squareSize +
								(index === 0
									? 0
									: y.indicator[index - 1].label.includes('BB')
									? `${y.indicator[index - 1].label} ${y.indicator[index - 1].value}`.length > 39
										? 280
										: 210
									: 110) +
								positionX;
							positionX = indicatorBoxPostionX;
							const indicatorTextPositionX = indicatorBoxPostionX + squareSize + 10;

							return (
								<g key={`indicator-${i}`}>
									<rect
										x={indicatorBoxPostionX} // Adjust the x position of the box
										y={boxPositionY + fontSize}
										width={squareSize}
										height={squareSize}
										rx={1}
										ry={1}
										fill={indi.stroke}
										filter='url(#boxShadow2)'
										style={{ stroke: 'none' }}
									/>
									<text
										key={`L-${i}`}
										x={indicatorTextPositionX} // Adjust the x position of the text
										y={labelPositionY + fontSize}
										fontFamily={'Outfit'}
										fontSize={fontSize}
										fill={fontFill}
										fontWeight={400}
									>
										<tspan>{`${indi.label}: `}</tspan>
										<tspan fontWeight={700}>{indi.value}</tspan>{' '}
									</text>
								</g>
							);
						})}
					</g>
				</g>
			);
		}
	}
	return (
		<g x={X} y={startY}>
			<text x={X} y={startY} fontFamily={fontFamily} fontSize={fontSize} fill={'#081D34'} fontWeight={700}>
				{content.x}
			</text>
			{tspans}
		</g>
	);
};

export default HoverTooltipSVG;

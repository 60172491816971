import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/constants';
import { Loader } from 'shared/components/spinner/spinner';
import { useWhitelabelConfigStatus } from 'shared/hooks/useWhitelabelConfigStatus';

import { IPDFsBoxes } from '../interface/pdfModule';
import AllPdfModuleCards from './allpdfModuleCards';
import { isEmpty } from 'lodash';
import EmptyDataContainer from 'shared/components/emptyDataContainer/emptyDataContainer';

const WhitelabelpdfModuleList = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [pdfModuleList, setPdfModuleList] = useState<IPDFsBoxes>({ boxes: [], noGroup: [] });
	const { whitelabelId } = useWhitelabelConfigStatus();
	const navigate = useNavigate();

	const fetchPdfModuleList = useCallback(() => {
		setIsLoading(true);
		httpService
			.get(`${API_CONFIG.path.fetchWhiteLabelPdfModuleBoxes}/${whitelabelId}`)
			.then((response) => {
				setIsLoading(false);
				setPdfModuleList(response);
			})
			.catch((err) => {
				setIsLoading(false);
				console.error('error', err);
			});
	}, [whitelabelId]);

	useEffect(() => {
		fetchPdfModuleList();
	}, [fetchPdfModuleList]);

	const handleOnClick = useCallback(
		(groupId: number, type: string) => {
			if (type === 'box') {
				navigate(`/pdfModule/list/${groupId}?whitelabelId=${whitelabelId}`);
			} else {
				navigate(`/pdfModule/data/${groupId}?whitelabelId=${whitelabelId}`);
			}
		},
		[whitelabelId]
	);

	return (
		<>
			{isLoading && <Loader />}
			{!isLoading && (
				<div className='pdfModule-list'>
					{isEmpty(pdfModuleList?.boxes) && isEmpty(pdfModuleList?.noGroup) && <EmptyDataContainer />}
					<div className={`d-flex flex-wrap text-align-center justify-content-center mb-68 pdfs-box--wrapper`}>
						<AllPdfModuleCards
							pdfModuleList={pdfModuleList?.boxes || []}
							className='pdfs--box'
							type='box'
							handleOnClick={handleOnClick}
						/>
					</div>
					<div className={`d-flex flex-wrap text-align-center justify-content-center pdfs-box--wrapper`}>
						<AllPdfModuleCards
							pdfModuleList={pdfModuleList?.noGroup || []}
							className='pdfs-small--box'
							type='group'
							handleOnClick={handleOnClick}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default WhitelabelpdfModuleList;

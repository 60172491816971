import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { colorPallet } from 'shared/constants/constants';
import { useQuery } from 'shared/hooks/useQuery';
import { State } from 'shared/interface';
import { ScaleType } from 'shared/interface/enums';
import localizationConstants from 'shared/util/translation/constants';
import { chartTimeRangeMapperForNumber } from 'shared/util/utility';
import { v4 as uuid } from 'uuid';
import { IChartSettingsType, ISpan } from '../interface/futureTable';

export const useAdvanceChartConfig = () => {
	const location = useLocation();
	const params = useQuery();
	const { chartView } = useSelector((state: State) => ({ ...state.siteConfig }));
	const [chartSettings, setChartSettings] = useState<IChartSettingsType[]>([]);
	const [isChartSettingUpdate, setIsChartSettingUpdate] = useState(true);

	const { basis_symbol, boerse, symbol, currency } = useMemo(() => {
		return {
			basis_symbol: `${params.get('basis_symbol') || chartSettings[0]?.basis_symbol}`,
			boerse: `${params.get('boerse') || chartSettings[0]?.boerse}`,
			symbol: `${params.get('symbol') || chartSettings[0]?.symbol}`,
			currency: `${params.get('currency')}`
		};
	}, [chartSettings, params]);

	useEffect(() => {
		if (symbol && boerse) {
			setIsChartSettingUpdate(true);
		}
	}, [basis_symbol, boerse, symbol, currency]);

	const [scaleType, setScaleType] = useState<string>(chartView);
	const [chartType, setChartType] = useState<string>(chartView === ScaleType.PERCENTAGE_CHANGE ? 'line' : 'area');
	const [spanObj, setSpanObj] = useState<ISpan>({ span: '1y' });

	useEffect(() => {
		if (location.state?.chartSelection) {
			if (location.state?.chartSelection?.chartView) {
				setScaleType(location.state?.chartSelection?.chartView || chartView);
			} else {
				setScaleType(chartView);
			}
			if (location.state?.chartSelection?.type) {
				if (location.state?.chartSelection?.chartView) {
					setChartType(
						location.state?.chartSelection?.chartView === ScaleType.PERCENTAGE_CHANGE ? 'line' : location.state?.chartSelection.type
					);
				} else {
					setChartType(scaleType === ScaleType.PERCENTAGE_CHANGE ? 'line' : location.state?.chartSelection.type);
				}
			}
			if (location.state?.chartSelection?.customChartSpan?.von && location.state?.chartSelection?.customChartSpan?.bis) {
				setSpanObj({
					span: '',
					startDate: location.state?.chartSelection?.customChartSpan?.von,
					endDate: location.state?.chartSelection?.customChartSpan?.bis
				});
			} else if (location.state?.chartSelection?.span) {
				setSpanObj({ span: chartTimeRangeMapperForNumber([], location.state?.chartSelection.span) });
			}
		}
	}, [location.state?.chartSelection]);

	useEffect(() => {
		if (location.state?.advanceChartConfig && location.state?.advanceChartConfig?.chartSettings) {
			setChartSettings(location.state?.advanceChartConfig.chartSettings);
		} else {
			setChartSettings([
				{
					symbol: symbol,
					boerse: boerse,
					basis_symbol: basis_symbol,
					currency: {
						label: currency !== 'null' ? currency : '',
						value: currency !== 'null' ? currency : ''
					},
					axis: {
						label: chartView === ScaleType.PERCENTAGE_CHANGE ? 'Left' : 'Right',
						value: chartView === ScaleType.PERCENTAGE_CHANGE ? 'left' : 'right'
					},
					color: colorPallet[0],
					disallowed: 0,
					history: {
						label: t(localizationConstants.none),
						value: ''
					},
					id: uuid(),
					indicators: [],
					name: '',
					unit: {
						label: '',
						value: ''
					}
				}
			]);
		}
	}, [basis_symbol, boerse, chartView, currency, location.state?.advanceChartConfig, symbol]);

	return {
		chartSettings,
		setChartSettings,
		scaleType,
		setScaleType,
		chartType,
		setChartType,
		spanObj,
		setSpanObj,
		basis_symbol,
		boerse,
		symbol,
		currency,
		isChartSettingUpdate,
		setIsChartSettingUpdate
	};
};
